<template>
	<v-container fluid>
		<!-- Main -->
		<v-row class="align-center pl-3">
			<!-- Breadcrumbs -->
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
		</v-row>

		<!-- Delete Report Dialog -->
		<DeleteDialog
			:dialog.sync="deleteDialog"
			v-on:confirmed="deletingSelectedKnowledgeBaseTypesConfirmed"
		>
			<template v-slot:title>{{ $t("deleteDialog.title") }}</template>
			<template v-slot:content>{{ $t("deleteDialog.content") }}</template>
		</DeleteDialog>

		<!-- Knowledge Base Type Dialog-->
		<KnowledgeBaseTypeEditDialog
			:object.sync="editedKnowledgeBaseType"
			:dialog.sync="knowledgeBaseTypeDialog"
			v-on:item-created="onKnowledgeBaseTypeCreated"
		></KnowledgeBaseTypeEditDialog>

		<v-row>
			<v-col cols="12" class="pt-0">
				<v-card
					:class="{
						'mx-0 elevation-5': $vuetify.breakpoint.xsOnly,
						'mx-3 elevation-5': $vuetify.breakpoint.smAndUp,
					}"
				>
					<v-tabs centered show-arrows>
						<v-tabs-slider color="primary"></v-tabs-slider>

						<!-- tab header -->
						<v-tab v-if="me.isSuperuser"
							><v-icon left>error</v-icon
							>{{
								$t(
									"knowledgeBaseSettings.knowledgeBaseTypes.title"
								)
							}}</v-tab
						>

						<!-- tab body -->
						<!-- Knowledge Base Types -->
						<v-tab-item v-if="me.isSuperuser">
							<v-card flat>
								<v-card-title class="pb-0">
									<v-col cols="12" sm="4"></v-col>
									<v-col cols="12" sm="4">
										<v-text-field
											dense
											v-model="searchKnowledgeBaseType"
											append-outer-icon="refresh"
											@click:append-outer="
												refreshKnowledgeBaseTypesCurrentPage()
											"
											v-bind:label="
												$t('datatable.searchLabel')
											"
											clearable
											hide-details
											@keydown.enter="
												refreshKnowledgeBaseTypesCurrentPage()
											"
											@click:clear="
												onClearKnowledgeBaseTypesSearch()
											"
										></v-text-field>
									</v-col>
									<v-spacer></v-spacer>
									<v-btn
										v-if="me.isSuperuser"
										class="mr-2"
										color="error"
										dark
										outlined
										rounded
										small
										:disabled="
											selectedKnowledgeBaseTypes.length <=
												0
										"
										@click="
											deletingSelectedKnowledgeBaseTypes()
										"
										>Delete Type</v-btn
									>
									<v-btn
										v-if="me.isSuperuser"
										color="primary"
										dark
										outlined
										rounded
										small
										@click="addKnowledgeBaseType()"
										>New Type</v-btn
									>
								</v-card-title>

								<v-container fluid>
									<v-data-table
										:headers="knowledgeBaseTypesHeaders"
										:loading="
											$apollo.queries.knowledgeBaseTypes
												.loading
										"
										:items="knowledgeBaseTypes.edges"
										:options.sync="
											knowledgeBaseTypesDataTableOptions
										"
										:items-per-page="defaultItemsPerPage"
										:footer-props="{
											'items-per-page-options': [
												10,
												25,
												50,
												75,
												100,
											],
											'disable-pagination':
												$apollo.queries
													.knowledgeBaseTypes.loading,
										}"
										v-model="selectedKnowledgeBaseTypes"
										item-key="node.id"
										:show-select="
											me.isSuperuser
												? 'show-select'
												: undefined
										"
									>
										<template
											v-slot:[`item.action`]="{ item }"
										>
											<v-btn
												v-if="me.isSuperuser"
												icon
												color="primary"
												@click="
													editKnowledgeBaseType(
														item.node
													)
												"
											>
												<v-icon>edit</v-icon>
											</v-btn>
										</template>
									</v-data-table>
								</v-container>
							</v-card>
						</v-tab-item>
					</v-tabs>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import store from "@/store/index.js";
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import KnowledgeBaseTypeEditDialog from "@/components/status/KnowledgeBaseTypeEditDialog.vue";

export default {
	name: "knowledge-base-settings-page",
	components: {
		DeleteDialog,
		KnowledgeBaseTypeEditDialog,
	},

	apollo: {
		knowledgeBaseTypes: {
			query: gql`
				query knowledgeBaseTypes(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$orderBy: String
					$search: String
				) {
					knowledgeBaseTypes(
						first: $first
						last: $last
						before: $before
						after: $after
						search: $search
						orderBy: $orderBy
					) {
						edges {
							node {
								id
								knowledgeBaseTypeName
							}
						}
					}
				}
			`,
			variables() {
				return {
					first: 10,
				};
			},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.knowledgeBaseTypes,
			skip: true,
			pollInterval: 60000, // ms
		},
	},

	data() {
		return {
			deleteDialog: false,
			knowledgeBaseTypeDialog: false,

			knowledgeBaseTypes: {},

			componentKey: 0,

			searchKnowledgeBaseType: "",

			defaultItemsPerPage: 10,
			knowledgeBaseTypesDataTableOptions: {},
			page: 1,

			editedKnowledgeBaseType: {},

			selectedKnowledgeBaseTypes: [],

			// table headers
			knowledgeBaseTypesHeaders: [
				{
					text: this.$t(
						"knowledgeBaseSettings.knowledgeBaseTypes.datatableHeaders.knowledgeBaseTypeName"
					),
					value: "node.knowledgeBaseTypeName",
					sortable: true,
				},
				{
					text: this.$t("datatable.actionHeader"),
					align: "center",
					value: "action",
					sortable: false,
				},
			],
		};
	},
	computed: {
		me() {
			return this.$store.state.user.me;
		},

		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("knowledgeBaseSettings.breadcrumbs.home"),
					disable: false,
					href: "/#/",
				},
				{
					text: this.$t(
						"knowledgeBaseSettings.breadcrumbs.knowledgeBase"
					),
					disable: false,
					href: "/#/knowledge-base",
				},
				{
					text: this.$t("knowledgeBaseSettings.breadcrumbs.settings"),
					disabled: true,
				},
			];
			return breadcrumbs;
		},
	},
	watch: {
		knowledgeBaseTypesDataTableOptions: {
			handler() {
				this.refreshKnowledgeBaseTypesCurrentPage();
			},
			deep: true,
		},
	},
	created() {},
	mounted() {},
	methods: {
		refreshKnowledgeBaseTypesCurrentPage() {
			const {
				sortBy,
				sortDesc,
				page,
				itemsPerPage,
			} = this.knowledgeBaseTypesDataTableOptions;

			var _first = itemsPerPage;
			var _last = null;
			var _before = null;
			var _after = null;
			var _orderBy = null;

			if (page < this.page) {
				_first = null;
				_last = itemsPerPage;
				_before = this.knowledgeBaseTypes.pageInfo.startCursor;
				_after = null;
			}

			if (page > this.page) {
				_first = itemsPerPage;
				_last = null;
				_before = null;
				_after = this.knowledgeBaseTypes.pageInfo.endCursor;
			}

			switch (sortBy[0]) {
				case "node.knowledgeBaseTypeName":
					_orderBy = "knowledgeBaseTypeName";
					break;
				default:
					_orderBy = "knowledgeBaseTypeName";
					break;
			}

			if (sortDesc[0] === true) {
				_orderBy = "-" + _orderBy;
			}

			this.$apollo.queries.knowledgeBaseTypes.setVariables({
				first: _first,
				last: _last,
				before: _before,
				after: _after,
				orderBy: _orderBy,
				search: this.searchKnowledgeBaseType,
			});

			// set skip false to execute the query
			this.$apollo.queries.knowledgeBaseTypes.skip = false;
			this.$apollo.queries.knowledgeBaseTypes.refresh();

			// update this.page
			this.page = page;
		},

		onClearKnowledgeBaseTypesSearch() {
			this.searchKnowledgeBaseType = "";

			this.refreshKnowledgeBaseTypesCurrentPage();
		},

		onKnowledgeBaseTypeCreated() {
			this.knowledgeBaseTypeDialog = false;
			this.$apollo.queries.knowledgeBaseTypes.refresh();
		},

		addKnowledgeBaseType() {
			this.editedKnowledgeBaseType = null;
			this.knowledgeBaseTypeDialog = true;
		},

		editKnowledgeBaseType(knowledgeBaseType) {
			this.editedKnowledgeBaseType = JSON.parse(
				JSON.stringify(knowledgeBaseType)
			);
			this.knowledgeBaseTypeDialog = true;
		},

		deletingSelectedKnowledgeBaseTypes() {
			this.deleteDialog = true;
		},

		deletingSelectedKnowledgeBaseTypesConfirmed() {
			var selectedKnowledgeBaseTypes = this.selectedKnowledgeBaseTypes;
			function getKnowledgeBaseTypeIds(selectedKnowledgeBaseTypes) {
				var knowledgeBaseTypesIds = [];
				for (var i = 0; i < selectedKnowledgeBaseTypes.length; i++) {
					knowledgeBaseTypesIds.push(
						selectedKnowledgeBaseTypes[i].node.id
					);
				}
				return knowledgeBaseTypesIds;
			}
			var knowledgeBaseTypesIds = getKnowledgeBaseTypeIds(
				selectedKnowledgeBaseTypes
			);

			const payload = {
				ids: knowledgeBaseTypesIds,
			};

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation deleteKnowledgeBaseTypes(
							$input: DeleteKnowledgeBaseTypesInput!
						) {
							deleteKnowledgeBaseTypes(input: $input) {
								knowledgeBaseType {
									id
								}
							}
						}
					`,
					// Parameters
					variables: {
						input: payload,
					},
				})
				.then((response) => {
					// refresh query
					this.$apollo.queries.knowledgeBaseTypes.refresh();

					// close delete dialog
					this.deleteDialog = false;

					// show snackbar
					const payload = {
						color: "success",
						message: `Type successfully deleted`,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					this.error = error.graphQLErrors[0].message;
				});
			// update state of selectedReports
			this.selectedKnowledgeBaseTypes = [];
		},
	},
};
</script>
