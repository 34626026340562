var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('SystemEditDialog',{attrs:{"dialog":_vm.systemDialog},on:{"update:dialog":function($event){_vm.systemDialog=$event},"item-created":_vm.systemAdded}}),_c('v-row',{staticClass:"ma-0 pa-0"}),_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.error)?_c('v-alert',{attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.formTitle)+" "),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pt-5"},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":100,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(100),
								],"label":_vm.$t(
										'maintenanceEditDialog.maintenanceNameLabel'
									),"outlined":""},model:{value:(_vm.editedItem.maintenanceName),callback:function ($$v) {_vm.$set(_vm.editedItem, "maintenanceName", $$v)},expression:"editedItem.maintenanceName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{staticClass:"subtitle-2",attrs:{"counter":255,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(255),
								],"label":_vm.$t(
										'maintenanceEditDialog.maintenanceDescriptionLabel'
									),"outlined":""},model:{value:(_vm.editedItem.maintenanceDescription),callback:function ($$v) {_vm.$set(_vm.editedItem, "maintenanceDescription", $$v)},expression:"editedItem.maintenanceDescription"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{ref:"dateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.dateMenu,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.dateMenu=$event},"update:return-value":function($event){_vm.dateMenu=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t(
												'maintenanceEditDialog.dateRangeLabel'
											),"readonly":"","outlined":"","rules":[_vm.rules.required]},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"scrollable":"","first-day-of-week":"1","locale-first-day-of-year":"4","show-week":"","range":"","min":_vm.minDate},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t("button.cancelBtn"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dateMenu.save(_vm.dateRange)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{ref:"timeMenuStart",attrs:{"close-on-content-click":false,"return-value":_vm.timeStart,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.timeStart=$event},"update:return-value":function($event){_vm.timeStart=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t(
												'maintenanceEditDialog.startTimeLabel'
											),"readonly":"","outlined":""},model:{value:(_vm.timeStart),callback:function ($$v) {_vm.timeStart=$$v},expression:"timeStart"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.timeMenuStart),callback:function ($$v) {_vm.timeMenuStart=$$v},expression:"timeMenuStart"}},[(_vm.timeMenuStart)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","scrollable":""},on:{"click:minute":function($event){return _vm.$refs.timeMenuStart.save(_vm.timeStart)}},model:{value:(_vm.timeStart),callback:function ($$v) {_vm.timeStart=$$v},expression:"timeStart"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{ref:"timeMenuEnd",attrs:{"close-on-content-click":false,"return-value":_vm.timeEnd,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.timeEnd=$event},"update:return-value":function($event){_vm.timeEnd=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t(
												'maintenanceEditDialog.endTimeLabel'
											),"readonly":"","outlined":""},model:{value:(_vm.timeEnd),callback:function ($$v) {_vm.timeEnd=$$v},expression:"timeEnd"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.timeMenuEnd),callback:function ($$v) {_vm.timeMenuEnd=$$v},expression:"timeMenuEnd"}},[(_vm.timeMenuEnd)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","scrollable":""},on:{"click:minute":function($event){return _vm.$refs.timeMenuEnd.save(_vm.timeEnd)}},model:{value:(_vm.timeEnd),callback:function ($$v) {_vm.timeEnd=$$v},expression:"timeEnd"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"subtitle-2",attrs:{"items":_vm.systems.edges,"item-text":"node.systemName","item-value":"node.id","loading":_vm.$apollo.queries.systems.loading,"rules":[_vm.rules.required],"label":_vm.$t('maintenanceEditDialog.systemsLabel'),"outlined":"","clearable":"","multiple":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"text":"","small":"","tabindex":"-1"}},[_c('v-icon',{attrs:{"color":"grey","tabindex":"-1"},on:{"click":_vm.addSystem}},[_vm._v("add")])],1)]},proxy:true}]),model:{value:(_vm.editedItem.selectedSystems),callback:function ($$v) {_vm.$set(_vm.editedItem, "selectedSystems", $$v)},expression:"editedItem.selectedSystems"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pb-5 pr-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isSaving,"text":"","rounded":""},on:{"click":_vm.closeMaintenanceDialog}},[_vm._v(_vm._s(_vm.$t("button.cancelBtn")))]),_c('v-btn',{attrs:{"outlined":"","rounded":"","loading":_vm.isSaving,"color":"primary"},on:{"click":_vm.saveMaintenance}},[_vm._v(_vm._s(_vm.$t("button.saveBtn")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }