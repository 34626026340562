var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.error)?_c('v-alert',{attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")]),_c('v-card-text',{staticClass:"pt-5"},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-autocomplete',{staticClass:"subtitle-2",attrs:{"items":_vm.tenants.edges,"item-text":"node.tenantName","item-value":"node.id","loading":_vm.$apollo.queries.tenants.loading,"rules":[_vm.rules.required],"label":_vm.$t('reportEditDialog.tenantLabel'),"outlined":"","clearable":""},model:{value:(_vm.editedItem.tenantNodeId),callback:function ($$v) {_vm.$set(_vm.editedItem, "tenantNodeId", $$v)},expression:"editedItem.tenantNodeId"}})],1),(_vm.editedItem.reportType === 'POWERBIREPORT')?_c('v-col',{attrs:{"cols":"4"}},[_c('v-switch',{staticClass:"mt-3 my-0 py-0",attrs:{"label":_vm.$t('reportEditDialog.isEditableLabel'),"hide-details":""},model:{value:(_vm.editedItem.isEditable),callback:function ($$v) {_vm.$set(_vm.editedItem, "isEditable", $$v)},expression:"editedItem.isEditable"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"subtitle-2",attrs:{"items":_vm.reportGroups.edges,"item-text":"node.reportGroupName","item-value":"node.id","loading":_vm.$apollo.queries.reportGroups.loading,"rules":[],"label":_vm.$t('reportEditDialog.reportGroupLabel'),"outlined":"","clearable":""},model:{value:(_vm.editedItem.reportGroupNodeId),callback:function ($$v) {_vm.$set(_vm.editedItem, "reportGroupNodeId", $$v)},expression:"editedItem.reportGroupNodeId"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":100,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(100),
								],"label":_vm.$t('reportEditDialog.reportNameLabel'),"outlined":""},model:{value:(_vm.editedItem.reportName),callback:function ($$v) {_vm.$set(_vm.editedItem, "reportName", $$v)},expression:"editedItem.reportName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":36,"rules":[
									_vm.rules.required,
									_vm.rules.minLength(36),
									_vm.rules.maxLength(36),
								],"label":_vm.$t('reportEditDialog.groupIdLabel'),"outlined":""},model:{value:(_vm.editedItem.groupId),callback:function ($$v) {_vm.$set(_vm.editedItem, "groupId", $$v)},expression:"editedItem.groupId"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":36,"rules":[
									_vm.rules.required,
									_vm.rules.minLength(36),
									_vm.rules.maxLength(36),
								],"label":_vm.$t('reportEditDialog.reportIdLabel'),"outlined":""},model:{value:(_vm.editedItem.reportId),callback:function ($$v) {_vm.$set(_vm.editedItem, "reportId", $$v)},expression:"editedItem.reportId"}})],1),(_vm.editedItem.reportType === 'POWERBIREPORT')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":36,"rules":[
									_vm.rules.required,
									_vm.rules.minLength(36),
									_vm.rules.maxLength(36),
								],"label":_vm.$t('reportEditDialog.datasetIdLabel'),"outlined":""},model:{value:(_vm.editedItem.datasetId),callback:function ($$v) {_vm.$set(_vm.editedItem, "datasetId", $$v)},expression:"editedItem.datasetId"}})],1):_vm._e(),(_vm.editedItem.reportType === 'POWERBIREPORT')?_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":36,"rules":[_vm.rules.maxLength(36)],"label":_vm.$t(
										'reportEditDialog.bindedGroupIdLabel'
									),"outlined":""},model:{value:(_vm.editedItem.bindedGroupId),callback:function ($$v) {_vm.$set(_vm.editedItem, "bindedGroupId", $$v)},expression:"editedItem.bindedGroupId"}})],1):_vm._e(),(_vm.editedItem.reportType === 'POWERBIREPORT')?_c('v-col',{attrs:{"cols":"4"}},[_c('v-switch',{staticClass:"mt-3 my-0 py-0",attrs:{"label":_vm.$t('reportEditDialog.isBindedLabel'),"hide-details":""},model:{value:(_vm.editedItem.isBinded),callback:function ($$v) {_vm.$set(_vm.editedItem, "isBinded", $$v)},expression:"editedItem.isBinded"}})],1):_vm._e(),(_vm.editedItem.reportType === 'POWERBIREPORT')?_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":36,"rules":[_vm.rules.maxLength(36)],"label":_vm.$t(
										'reportEditDialog.bindedDatasetIdLabel'
									),"outlined":""},model:{value:(_vm.editedItem.bindedDatasetId),callback:function ($$v) {_vm.$set(_vm.editedItem, "bindedDatasetId", $$v)},expression:"editedItem.bindedDatasetId"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"4"}},[_c('v-switch',{staticClass:"mt-3 my-0 py-0",attrs:{"label":"Direct Query","hide-details":""},model:{value:(_vm.editedItem.isDirectQuery),callback:function ($$v) {_vm.$set(_vm.editedItem, "isDirectQuery", $$v)},expression:"editedItem.isDirectQuery"}})],1),(_vm.editedItem.reportType === 'POWERBIREPORT')?_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":30,"rules":[_vm.rules.maxLength(30)],"label":_vm.$t('reportEditDialog.pbiDateTableLabel'),"outlined":""},model:{value:(_vm.editedItem.pbiDateTable),callback:function ($$v) {_vm.$set(_vm.editedItem, "pbiDateTable", $$v)},expression:"editedItem.pbiDateTable"}})],1):_vm._e(),(_vm.editedItem.reportType === 'POWERBIREPORT')?_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":30,"rules":[_vm.rules.maxLength(30)],"label":_vm.$t(
										'reportEditDialog.pbiDateColumnLabel'
									),"outlined":""},model:{value:(_vm.editedItem.pbiDateColumn),callback:function ($$v) {_vm.$set(_vm.editedItem, "pbiDateColumn", $$v)},expression:"editedItem.pbiDateColumn"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{staticClass:"subtitle-2",attrs:{"items":_vm.reportTypeChoices,"item-text":"text","item-value":"value","rules":[],"label":"Report Type","outlined":"","clearable":""},model:{value:(_vm.editedItem.reportType),callback:function ($$v) {_vm.$set(_vm.editedItem, "reportType", $$v)},expression:"editedItem.reportType"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pb-5 pr-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isSaving,"text":"","rounded":""},on:{"click":_vm.closeReportDialog}},[_vm._v(_vm._s(_vm.$t("button.cancelBtn")))]),_c('v-btn',{attrs:{"outlined":"","rounded":"","loading":_vm.isSaving,"color":"primary"},on:{"click":_vm.saveReport}},[_vm._v(_vm._s(_vm.$t("button.saveBtn")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }