<template>
	<v-container class="fill-height" fluid>
		<!-- Login Dialog -->
		<v-row align="center" justify="center">
			<v-col v-if="isVerifyingToken" cols="12">
				<div class="text-center">
					<v-progress-circular
						indeterminate
						color="primary"
						class="mx-2"
					></v-progress-circular>
				</div>
			</v-col>
			<v-col v-else cols="12" sm="8" md="6" lg="4">
				<v-card v-if="!isTokenValid" class="elevation-1 pa-10">
					<h3>
						<v-icon large class="mr-5">error_outline</v-icon>
						{{ $t("passwordReset.invalidLinkText") }}
						<a href="/#/login" class="ml-10">{{
							$t("passwordReset.backText")
						}}</a>
					</h3>
				</v-card>

				<v-card v-else-if="isPasswordReset" class="elevation-1 pa-10">
					<h3>
						<v-icon large class="mr-5" size="100" color="primary"
							>beach_access</v-icon
						>
						{{ $t("passwordReset.resetSuccesfulText") }}
						<a href="/#/login" class="ml-10">{{
							$t("button.loginBtn")
						}}</a>
					</h3>
				</v-card>

				<v-card v-else class="elevation-1">
					<v-toolbar color="primary">
						<!-- <v-toolbar-icon>
                        <v-img
                            :src="require('../assets/logo-48x48.png')"
                            class="mr-3"
                            height="30"
                        ></v-img></v-toolbar-icon>-->
						<v-toolbar-title class="white--text">{{
							$t("passwordReset.title")
						}}</v-toolbar-title>
						<v-spacer />
					</v-toolbar>
					<v-card-text class="mt-10 pb-0">
						<v-form ref="resetPasswordForm">
							<v-text-field
								id="newPassword"
								v-bind:label="
									$t('passwordReset.newPasswordLabel')
								"
								prepend-icon="lock_open"
								:type="showNewPassword ? 'text' : 'password'"
								:rules="[
									rules.required,
									rules.minLength(8),
									rules.maxLength(40),
								]"
								outlined
								v-model="newPassword"
								:error-messages="resetPasswordError"
								autofocus
							>
								<template v-slot:append>
									<v-icon
										color="primary"
										@click="
											showNewPassword = !showNewPassword
										"
										tabindex="-1"
										>{{
											showNewPassword
												? "visibility"
												: "visibility_off"
										}}</v-icon
									>
								</template>
							</v-text-field>
							<v-text-field
								id="newPasswordConfirm"
								v-bind:label="
									$t('passwordReset.confirmNewPasswordLabel')
								"
								prepend-icon="lock_open"
								:type="showNewPassword ? 'text' : 'password'"
								:rules="[
									rules.required,
									rules.minLength(8),
									rules.maxLength(40),
								]"
								outlined
								v-model="newPasswordConfirm"
								:error-messages="resetPasswordError"
								@keydown.enter="resetPassword"
							>
								<template v-slot:append>
									<v-icon
										color="primary"
										@click="
											showNewPassword = !showNewPassword
										"
										tabindex="-1"
										>{{
											showNewPassword
												? "visibility"
												: "visibility_off"
										}}</v-icon
									>
								</template></v-text-field
							>
						</v-form>
					</v-card-text>

					<v-card-actions class="pb-5">
						<v-spacer></v-spacer>
						<v-btn
							outlined
							rounded
							:disabled="
								newPassword.length == 0 ||
									newPassword !== newPasswordConfirm
							"
							:loading="isResettingPassword"
							color="primary"
							@click="resetPassword()"
							>{{ $t("button.saveBtn") }}</v-btn
						>
						<v-spacer></v-spacer>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
export default {
	data: function() {
		return {
			isVerifyingToken: true,
			isTokenValid: false,
			isPasswordReset: false,

			isResettingPassword: false,
			newPassword: "",
			newPasswordConfirm: "",
			showNewPassword: "",
			resetPasswordError: "",

			rules: {
				required: (v) => !helper.isEmpty(v) || "This field is required",
				listRequired: (v) =>
					(v || "").length >= 1 || `This field is required`,
				minLength: (len) => (v) =>
					(v || "").length >= len || `Require at least ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len || "Too long",
				// nameRules: [
				//     v => !!v || "Name is required",
				//     v =>
				//         v.length <= 50 || "Name must be less than 50 characters"
				// ]
			},
		};
	},
	computed: {
		uidb64() {
			return this.$route.params.uidb64;
		},
		token() {
			return this.$route.params.token;
		},
	},
	created() {
		this.verifyResetPasswordToken();
	},
	methods: {
		verifyResetPasswordToken() {
			this.isVerifyingToken = true;

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation verifyResetPasswordToken(
							$input: VerifyResetPasswordTokenInput!
						) {
							verifyResetPasswordToken(input: $input) {
								success
							}
						}
					`,
					// Parameters
					variables: {
						input: {
							uidb64: this.uidb64,
							token: this.token,
						},
					},
				})
				.then((response) => {
					this.isTokenValid =
						response.data.verifyResetPasswordToken.success;
					console.log(this.isTokenValid);
				})
				.catch((error) => {
					if (error.graphQLErrors) {
						for (let err of error.graphQLErrors) {
							this.requestPasswordResetError = err.message;
						}
					}
				})
				.finally(() => {
					this.isVerifyingToken = false;
				});
		},

		resetPassword() {
			if (!this.$refs.resetPasswordForm.validate()) {
				return;
			}

			this.isResettingPassword = true;

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation resetPassword($input: ResetPasswordInput!) {
							resetPassword(input: $input) {
								success
							}
						}
					`,
					// Parameters
					variables: {
						input: {
							uidb64: this.uidb64,
							token: this.token,
							password: this.newPassword,
						},
					},
				})
				.then((response) => {
					console.log(response);
					this.isPasswordReset = response.data.resetPassword.success;
				})
				.catch((error) => {
					if (error.graphQLErrors) {
						this.requestPasswordResetError =
							error.graphQLErrors[0].message;
					}
				})
				.finally(() => {
					this.isResettingPassword = false;
				});
		},
	},
};
</script>
