<template>
	<v-row justify="center">
		<!-- Severity Dialog -->
		<SeverityEditDialog
			:dialog.sync="severityDialog"
			v-on:item-created="severityAdded"
		></SeverityEditDialog>

		<!-- System Dialog -->
		<SystemEditDialog
			:dialog.sync="systemDialog"
			v-on:item-created="systemAdded"
		></SystemEditDialog>

		<!-- add empty row to fit to page on mobile -->
		<v-row class="ma-0 pa-0"></v-row>

		<!-- Edit system -->
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card>
				<v-alert v-if="error" tile type="error">{{ error }}</v-alert>

				<v-card-title class="primary white--text">
					{{ formTitle }}
					<v-spacer></v-spacer>
				</v-card-title>

				<v-card-text class="pt-5">
					<v-form ref="form">
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.incidentName"
									:counter="100"
									:rules="[
										rules.required,
										rules.maxLength(100),
									]"
									v-bind:label="
										$t(
											'incidentEditDialog.incidentNameLabel'
										)
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="12">
								<v-textarea
									v-model="editedItem.incidentDescription"
									:counter="255"
									:rules="[
										rules.required,
										rules.maxLength(255),
									]"
									v-bind:label="
										$t(
											'incidentEditDialog.incidentDescriptionLabel'
										)
									"
									outlined
									class="subtitle-2"
								></v-textarea>
							</v-col>
							<!-- Severity -->
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.severityNodeId"
									:items="severities.edges"
									item-text="node.severityName"
									item-value="node.id"
									:loading="
										$apollo.queries.severities.loading
									"
									:rules="[rules.required]"
									v-bind:label="
										$t('incidentEditDialog.severityLabel')
									"
									class="subtitle-2"
									outlined
									clearable
								>
									<template v-slot:append-outer>
										<v-btn text small tabindex="-1">
											<v-icon
												color="grey"
												@click="addSeverity"
												tabindex="-1"
												>add</v-icon
											>
										</v-btn>
									</template>
								</v-autocomplete>
							</v-col>
							<!-- Systems -->
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.selectedSystems"
									:items="systems.edges"
									item-text="node.systemName"
									item-value="node.id"
									:loading="$apollo.queries.systems.loading"
									:rules="[rules.required]"
									v-bind:label="
										$t('incidentEditDialog.systemsLabel')
									"
									class="subtitle-2"
									outlined
									clearable
									multiple
								>
									<template v-slot:append-outer>
										<v-btn text small tabindex="-1">
											<v-icon
												color="grey"
												@click="addSystem"
												tabindex="-1"
												>add</v-icon
											>
										</v-btn>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col cols="4">
								<v-switch
									v-model="editedItem.isResolved"
									v-bind:label="
										$t('incidentEditDialog.isResolvedLabel')
									"
									class="my-0 py-0"
									hide-details
								></v-switch>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn
						:disabled="isSaving"
						text
						rounded
						@click="closeIncidentDialog"
						>{{ $t("button.cancelBtn") }}</v-btn
					>
					<v-btn
						outlined
						rounded
						:loading="isSaving"
						color="primary"
						@click="saveIncident"
						>{{ $t("button.saveBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import SeverityEditDialog from "@/components/status/SeverityEditDialog.vue";
import SystemEditDialog from "@/components/status/SystemEditDialog.vue";

export default {
	name: "incident-edit-dialog",
	props: ["dialog", "object"],
	components: { SeverityEditDialog, SystemEditDialog },

	apollo: {
		severities: {
			query: gql`
				query severities {
					severities {
						edges {
							node {
								id
								severityName
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.severities,
			skip: true,
			pollInterval: 60000, // ms
		},

		systems: {
			query: gql`
				query systems {
					systems {
						edges {
							node {
								id
								systemName
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.systems,
			skip: true,
			pollInterval: 60000, // ms
		},
	},

	data() {
		return {
			error: null,
			severityDialog: false,
			systemDialog: false,
			isSaving: false,

			severities: {},
			systems: {},

			defaultItem: {
				id: null,
				incidentName: null,
				incidentDescription: null,
				severityNodeId: null,
				selectedSystems: [],
				isResolved: false,
			},
			editedItem: {
				id: null,
				incidentName: null,
				incidentDescription: null,
				severityNodeId: null,
				selectedSystems: [],
				isResolved: false,
			},
			rules: {
				required: (v) => !helper.isEmpty(v) || "This field is required",
				minLength: (len) => (v) =>
					(v || "").length >= len ||
					`Invalid character length, required ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len || "Too long",
			},
		};
	},
	computed: {
		formTitle() {
			return this.object
				? this.$t("incidentEditDialog.editTitle")
				: this.$t("incidentEditDialog.newTitle");
		},
		me() {
			return this.$store.state.user.me;
		},
	},
	watch: {
		// reset form when dialog open or close
		dialog(val) {
			// if open dialog, initialize selections
			if (val) {
				this.$apollo.queries.severities.skip = false;
				this.$apollo.queries.systems.skip = false;
			}

			this.resetForm();
		},
	},
	created() {
		this.fetchMe();
	},
	methods: {
		fetchMe() {
			this.$store
				.dispatch("user/fetchMe")
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		resetForm() {
			// reset form state
			this.error = null;
			this.isSaving = false;
			this.panel = 0;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			// when editing, set form with object
			if (this.dialog && this.object) {
				this.editedItem.id = this.object.id;
				this.editedItem.incidentName = this.object.incidentName;
				this.editedItem.incidentDescription = this.object.incidentDescription;
				this.editedItem.severityNodeId = this.object.severity.id;
				this.editedItem.selectedSystems = this.object.incidentSystemSet
					? this.object.incidentSystemSet.edges.map((item) => {
							return item.node.system.id;
					  })
					: [];
				this.editedItem.isResolved = this.object.isResolved;
			}
			// when new or closing, set form with default
			else {
				this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
			}
		},

		closeIncidentDialog() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		saveIncident() {
			if (!this.$refs.form.validate()) {
				return;
			}

			// set form state
			this.error = null;
			this.isSaving = true;

			// prepare api call payload
			var payload = {
				incidentName: this.editedItem.incidentName,
				incidentDescription: this.editedItem.incidentDescription,
				severityNodeId: this.editedItem.severityNodeId,
				systems: this.editedItem.selectedSystems,
				isResolved: this.editedItem.isResolved,
			};

			// if editing
			if (this.object) {
				// prepare graphql mutation payload
				payload.id = this.editedItem.id;

				// update
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation updateIncident(
								$input: UpdateIncidentInput!
							) {
								updateIncident(input: $input) {
									incident {
										id
										createdAt
										modifiedAt
										incidentName
										incidentDescription
										isResolved
										severity {
											id
											severityName
											color
										}
										incidentSystemSet {
											edges {
												node {
													system {
														id
														createdAt
														modifiedAt
														systemName
														systemDescription
													}
												}
											}
										}
										incidentUpdateSet {
											edges {
												node {
													id
													createdAt
													modifiedAt
													incidentUpdateName
													incidentUpdateDescription
												}
											}
										}
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// update parent object
						this.$emit("item-created", response);
						this.closeIncidentDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Incident successfully edited`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
						this.isSaving = false;
					})
					.finally(() => {});
			}
			// new
			else {
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation createIncident(
								$input: CreateIncidentInput!
							) {
								createIncident(input: $input) {
									incident {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						this.$emit("item-created", response);
						this.closeIncidentDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Incident successfully added`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						console.log(error);

						this.error = error.graphQLErrors
							.map((error) => error.message)
							.join(", ");
						this.isSaving = false;
					})
					.finally(() => {});
			}
		},

		addSeverity() {
			this.severityDialog = true;
		},

		severityAdded(item) {
			this.$apollo.queries.severities.refresh();
			this.editedItem.severityNodeId = item.id;
		},

		addSystem() {
			this.systemDialog = true;
		},

		systemAdded(item) {
			this.$apollo.queries.systems.refresh();
			this.editedItem.selectedSystems = [item.id];
		},
	},
};
</script>
