<template>
	<v-container fluid>
		<!-- Main -->
		<v-row class="align-center pl-3">
			<!-- Breadcrumbs -->
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
		</v-row>

		<!-- Delete Dialog -->
		<DeleteDialog
			:dialog.sync="deleteDialog"
			:item="deletedItem"
			v-on:confirmed="deletingConfirmed"
		>
			<template v-slot:title>{{ $t("deleteDialog.title") }}</template>
			<template v-slot:content>{{ $t("deleteDialog.content") }}</template>
		</DeleteDialog>

		<!-- Tenant Dialog-->
		<TenantEditDialog
			:object.sync="editedTenant"
			:dialog.sync="tenantDialog"
			v-on:item-created="onTenantCreated"
		></TenantEditDialog>

		<!-- New Group Dialog-->
		<GroupEditDialog
			:dialog.sync="groupDialog"
			v-on:item-created="onGroupCreated"
		></GroupEditDialog>

		<!-- Theme Dialog-->
		<ThemeEditDialog
			:object.sync="editedTheme"
			:dialog.sync="themeDialog"
			v-on:item-created="onThemeCreated"
		></ThemeEditDialog>

		<v-row>
			<v-col cols="12" class="pt-0">
				<v-card
					:class="{
						'mx-0 elevation-5': $vuetify.breakpoint.xsOnly,
						'mx-3 elevation-5': $vuetify.breakpoint.smAndUp,
					}"
				>
					<v-tabs centered show-arrows>
						<v-tabs-slider color="primary"></v-tabs-slider>

						<!-- tab header -->
						<v-tab v-if="me.isSuperuser"
							><v-icon left>group</v-icon
							>{{ $t("usersSettings.tenants.title") }}</v-tab
						>
						<v-tab v-if="me.isSuperuser"
							><v-icon left>security</v-icon
							>{{ $t("usersSettings.groups.title") }}</v-tab
						>
						<v-tab v-if="me.isSuperuser"
							><v-icon left>palette</v-icon
							>{{ $t("usersSettings.themes.title") }}</v-tab
						>

						<!-- tab body -->
						<!-- Tenant -->
						<v-tab-item v-if="me.isSuperuser">
							<v-card flat>
								<v-card-title class="pb-0">
									<v-col cols="12" sm="4"></v-col>
									<v-col cols="12" sm="4">
										<v-text-field
											dense
											:value="searchTenants"
											@input="handleSearchTenants"
											append-outer-icon="refresh"
											@click:append-outer="
												refreshTenantsCurrentPage()
											"
											v-bind:label="
												$t('datatable.searchLabel')
											"
											clearable
											hide-details
											@keydown.enter="
												refreshTenantsCurrentPage()
											"
											@click:clear="
												onClearSearchTenants()
											"
										></v-text-field>
									</v-col>
									<v-spacer></v-spacer>
									<v-btn
										v-if="me.isSuperuser"
										class="mr-2"
										color="error"
										dark
										outlined
										rounded
										small
										:disabled="selectedTenants.length <= 0"
										@click="deletingTenant()"
										>{{
											$t(
												"usersSettings.tenants.deleteBtn"
											)
										}}</v-btn
									>
									<v-btn
										v-if="me.isSuperuser"
										color="primary"
										dark
										outlined
										rounded
										small
										@click="addTenant()"
										>{{
											$t("usersSettings.tenants.newBtn")
										}}</v-btn
									>
								</v-card-title>

								<v-container fluid>
									<v-data-table
										:headers="tenantHeaders"
										:loading="
											$apollo.queries.tenants.loading
										"
										:items="tenants.edges"
										:options.sync="tenantsDataTableOptions"
										:server-items-length="
											tenants.totalCount
										"
										:items-per-page="defaultItemsPerPage"
										:footer-props="{
											'items-per-page-options': [
												10,
												25,
												50,
												75,
												100,
											],
											'disable-pagination':
												$apollo.queries.tenants.loading,
										}"
										v-model="selectedTenants"
										item-key="node.id"
										:show-select="
											me.isSuperuser
												? 'show-select'
												: undefined
										"
									>
										<template
											v-slot:[`item.node.theme.themeName`]="{
												item,
											}"
										>
											<v-chip
												small
												:color="
													item.node.theme.primaryColor
												"
												>{{
													item.node.theme.themeName
												}}</v-chip
											>
										</template>
										<template
											v-slot:[`item.action`]="{ item }"
										>
											<v-btn
												v-if="me.isSuperuser"
												icon
												color="primary"
												@click="editTenant(item.node)"
											>
												<v-icon>edit</v-icon>
											</v-btn>
										</template>
									</v-data-table>
								</v-container>
							</v-card>
						</v-tab-item>

						<v-tab-item v-if="me.isSuperuser">
							<v-card flat>
								<v-card-title class="pb-0">
									<v-col cols="12" sm="4"></v-col>
									<v-col cols="12" sm="4">
										<v-text-field
											dense
											:value="searchGroups"
											@input="handleSearchGroups"
											append-outer-icon="refresh"
											@click:append-outer="
												refreshGroupsCurrentPage()
											"
											v-bind:label="
												$t('datatable.searchLabel')
											"
											clearable
											hide-details
											@keydown.enter="
												refreshGroupsCurrentPage()
											"
											@click:clear="onClearSearchGroups()"
										></v-text-field>
									</v-col>
									<v-spacer></v-spacer>
									<v-btn
										v-if="me.isSuperuser"
										color="primary"
										dark
										outlined
										rounded
										small
										@click="addGroup()"
										>{{
											$t("usersSettings.groups.newBtn")
										}}</v-btn
									>
								</v-card-title>

								<v-container fluid>
									<v-data-table
										:headers="groupHeaders"
										:loading="
											$apollo.queries.groups.loading
										"
										:items="groups.edges"
										:options.sync="groupsDataTableOptions"
										:server-items-length="groups.totalCount"
										:items-per-page="defaultItemsPerPage"
										:footer-props="{
											'items-per-page-options': [
												10,
												25,
												50,
												75,
												100,
											],
											'disable-pagination':
												$apollo.queries.groups.loading,
										}"
										item-key="node.id"
									>
										<template
											v-slot:[`item.action`]="{ item }"
										>
											<v-btn
												icon
												color="primary"
												@click="
													goToGroupDetail(
														item.node.id
													)
												"
											>
												<v-icon>remove_red_eye</v-icon>
											</v-btn>
										</template>
									</v-data-table>
								</v-container>
							</v-card>
						</v-tab-item>

						<!-- Theme -->
						<v-tab-item v-if="me.isSuperuser">
							<v-card flat>
								<v-card-title class="pb-0">
									<v-col cols="12" sm="4"></v-col>
									<v-col cols="12" sm="4">
										<v-text-field
											dense
											:value="searchThemes"
											@input="handleSearchThemes"
											append-outer-icon="refresh"
											@click:append-outer="
												refreshThemesCurrentPage()
											"
											v-bind:label="
												$t('datatable.searchLabel')
											"
											clearable
											hide-details
											@keydown.enter="
												refreshThemesCurrentPage()
											"
											@click:clear="onClearSearchThemes()"
										></v-text-field>
									</v-col>
									<v-spacer></v-spacer>
									<v-btn
										v-if="me.isSuperuser"
										class="mr-2"
										color="error"
										dark
										outlined
										rounded
										small
										:disabled="selectedThemes.length <= 0"
										@click="deletingTheme()"
										>{{
											$t("usersSettings.themes.deleteBtn")
										}}</v-btn
									>
									<v-btn
										v-if="me.isSuperuser"
										color="primary"
										dark
										outlined
										rounded
										small
										@click="addTheme()"
										>{{
											$t("usersSettings.themes.newBtn")
										}}</v-btn
									>
								</v-card-title>

								<v-container fluid>
									<v-data-table
										:headers="themeHeaders"
										:loading="
											$apollo.queries.themes.loading
										"
										:items="themes.edges"
										:options.sync="themesDataTableOptions"
										:server-items-length="themes.totalCount"
										:items-per-page="defaultItemsPerPage"
										:footer-props="{
											'items-per-page-options': [
												10,
												25,
												50,
												75,
												100,
											],
											'disable-pagination':
												$apollo.queries.themes.loading,
										}"
										v-model="selectedThemes"
										item-key="node.id"
										:show-select="
											me.isSuperuser
												? 'show-select'
												: undefined
										"
									>
										<template
											v-slot:[`item.node.primaryColor`]="{
												item,
											}"
										>
											<v-chip
												small
												:color="item.node.primaryColor"
												>{{
													item.node.primaryColor
												}}</v-chip
											>
										</template>
										<template
											v-slot:[`item.action`]="{ item }"
										>
											<v-btn
												v-if="me.isSuperuser"
												icon
												color="primary"
												@click="editTheme(item.node)"
											>
												<v-icon>edit</v-icon>
											</v-btn>
										</template>
									</v-data-table>
								</v-container>
							</v-card>
						</v-tab-item>
					</v-tabs>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import store from "@/store/index.js";
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import TenantEditDialog from "@/components/users/TenantEditDialog.vue";
import GroupEditDialog from "@/components/users/GroupEditDialog.vue";
import ThemeEditDialog from "@/components/users/ThemeEditDialog.vue";

export default {
	name: "users-settings-page",
	components: {
		DeleteDialog,
		TenantEditDialog,
		GroupEditDialog,
		ThemeEditDialog,
	},

	apollo: {
		tenants: {
			query: gql`
				query tenants(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$orderBy: String
					$search: String
				) {
					tenants(
						first: $first
						last: $last
						before: $before
						after: $after
						search: $search
						orderBy: $orderBy
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								id
								tenantName
								externalId
								allowedHosts
								theme {
									id
									themeName
									primaryColor
								}
								tenantGroupSet {
									edges {
										node {
											group {
												id
												name
											}
										}
									}
								}
								maxUsers
							}
						}
					}
				}
			`,
			variables() {
				return {
					first: 25,
					// orderStatus_In: this.orderStatusFilter
				};
			},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.tenants,
			skip: true,
			pollInterval: 60000, // ms
		},

		groups: {
			query: gql`
				query groups(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$orderBy: String
					$searchGroup: String
				) {
					groups(
						first: $first
						last: $last
						before: $before
						after: $after
						searchGroup: $searchGroup
						orderBy: $orderBy
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								id
								name
							}
						}
					}
				}
			`,
			variables() {
				return {
					first: 25,
					// orderStatus_In: this.orderStatusFilter
				};
			},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.groups,
			skip: true,
			pollInterval: 60000, // ms
		},

		themes: {
			query: gql`
				query themes(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$orderBy: String
					$search: String
				) {
					themes(
						first: $first
						last: $last
						before: $before
						after: $after
						search: $search
						orderBy: $orderBy
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								id
								themeName
								primaryColor
								themeLogoPath
							}
						}
					}
				}
			`,
			variables() {
				return {
					first: 25,
					// orderStatus_In: this.orderStatusFilter
				};
			},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.themes,
			skip: true,
			pollInterval: 60000, // ms
		},
	},

	data() {
		return {
			deleteDialog: false,
			deletedItem: {
				ids: [],
				type: null,
			},
			tenantDialog: false,
			groupDialog: false,
			themeDialog: false,

			tenants: {},
			groups: {},
			themes: {},

			defaultItemsPerPage: 25,
			tenantsDataTableOptions: {},
			groupsDataTableOptions: {},
			themesDataTableOptions: {},
			page: 1,

			editedTenant: {},
			editedTheme: {},

			selectedTenants: [],
			selectedThemes: [],

			// table headers
			tenantHeaders: [
				{
					text: this.$t(
						"usersSettings.tenants.datatableHeaders.tenant"
					),
					value: "node.tenantName",
					sortable: true,
				},
				{
					text: this.$t(
						"usersSettings.tenants.datatableHeaders.externalId"
					),
					value: "node.externalId",
					sortable: true,
				},
				{
					text: this.$t(
						"usersSettings.tenants.datatableHeaders.maxUsers"
					),
					value: "node.maxUsers",
					sortable: false,
				},
				{
					text: this.$t(
						"usersSettings.tenants.datatableHeaders.allowedHosts"
					),
					value: "node.allowedHosts",
					sortable: true,
				},
				{
					text: this.$t(
						"usersSettings.tenants.datatableHeaders.theme"
					),
					value: "node.theme.themeName",
					sortable: false,
				},
				{
					text: this.$t("datatable.actionHeader"),
					align: "center",
					value: "action",
					sortable: false,
				},
			],

			// table headers
			groupHeaders: [
				{
					text: this.$t(
						"usersSettings.groups.datatableHeaders.groupName"
					),
					value: "node.name",
					sortable: true,
				},
				{
					text: this.$t("datatable.actionHeader"),
					align: "center",
					value: "action",
					sortable: false,
				},
			],

			// table headers
			themeHeaders: [
				{
					text: this.$t(
						"usersSettings.themes.datatableHeaders.theme"
					),
					value: "node.themeName",
					sortable: true,
				},
				{
					text: this.$t(
						"usersSettings.themes.datatableHeaders.primaryColor"
					),
					value: "node.primaryColor",
					sortable: false,
				},
				{
					text: this.$t("datatable.actionHeader"),
					align: "center",
					value: "action",
					sortable: false,
				},
			],
		};
	},
	computed: {
		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("usersSettings.breadcrumbs.home"),
					disable: false,
					href: "/#/",
				},
				{
					text: this.$t("usersSettings.breadcrumbs.users"),
					disable: false,
					href: "/#/users",
				},
				{
					text: this.$t("usersSettings.breadcrumbs.settings"),
					disabled: true,
				},
			];
			return breadcrumbs;
		},

		me() {
			return this.$store.state.user.me;
		},

		searchTenants() {
			return this.$store.state.user.searchTenants;
		},

		searchGroups() {
			return this.$store.state.user.searchGroups;
		},

		searchThemes() {
			return this.$store.state.user.searchThemes;
		},
	},
	watch: {
		tenantsDataTableOptions: {
			handler() {
				this.refreshTenantsCurrentPage();
			},
			deep: true,
		},

		groupsDataTableOptions: {
			handler() {
				this.refreshGroupsCurrentPage();
			},
			deep: true,
		},

		themesDataTableOptions: {
			handler() {
				this.refreshThemesCurrentPage();
			},
			deep: true,
		},
	},
	created() {},
	mounted() {},
	methods: {
		refreshTenantsCurrentPage() {
			const {
				sortBy,
				sortDesc,
				page,
				itemsPerPage,
			} = this.tenantsDataTableOptions;

			var _first = itemsPerPage;
			var _last = null;
			var _before = null;
			var _after = null;
			var _orderBy = null;

			if (page < this.page) {
				_first = null;
				_last = itemsPerPage;
				_before = this.tenants.pageInfo.startCursor;
				_after = null;
			}

			if (page > this.page) {
				_first = itemsPerPage;
				_last = null;
				_before = null;
				_after = this.tenants.pageInfo.endCursor;
			}

			switch (sortBy[0]) {
				case "node.tenantName":
					_orderBy = "tenantName";
					break;
				default:
					_orderBy = null;
					break;
			}

			if (sortDesc[0] === true) {
				_orderBy = "-" + _orderBy;
			}

			this.$apollo.queries.tenants.setVariables({
				first: _first,
				last: _last,
				before: _before,
				after: _after,
				orderBy: _orderBy,
				search: this.searchTenants,
			});

			// set skip false to execute the query
			this.$apollo.queries.tenants.skip = false;
			this.$apollo.queries.tenants.refresh();

			// update this.page
			this.page = page;
		},

		handleSearchTenants(search) {
			this.$store
				.dispatch("user/searchTenants", search)
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		onClearSearchTenants() {
			this.handleSearchTenants("");
			this.refreshTenantsCurrentPage();
		},

		onTenantCreated() {
			this.tenantDialog = false;
			this.refreshTenantsCurrentPage();
			this.$apollo.queries.tenants.refresh();
		},

		addTenant() {
			this.editedTenant = null;
			this.tenantDialog = true;
		},

		editTenant(tenant) {
			this.editedTenant = JSON.parse(JSON.stringify(tenant));
			this.tenantDialog = true;
		},

		deletingTenant() {
			this.deletedItem.type = "tenants";
			var selectedTenants = this.selectedTenants;
			function getTenantIds(selectedTenants) {
				var tenantIds = [];
				for (var i = 0; i < selectedTenants.length; i++) {
					tenantIds.push(selectedTenants[i].node.id);
				}
				return tenantIds;
			}
			this.deletedItem.ids = getTenantIds(selectedTenants);
			this.deleteDialog = true;
		},

		refreshGroupsCurrentPage() {
			const {
				sortBy,
				sortDesc,
				page,
				itemsPerPage,
			} = this.groupsDataTableOptions;

			var _first = itemsPerPage;
			var _last = null;
			var _before = null;
			var _after = null;
			var _orderBy = null;

			if (page < this.page) {
				_first = null;
				_last = itemsPerPage;
				_before = this.groups.pageInfo.startCursor;
				_after = null;
			}

			if (page > this.page) {
				_first = itemsPerPage;
				_last = null;
				_before = null;
				_after = this.groups.pageInfo.endCursor;
			}

			switch (sortBy[0]) {
				case "node.name":
					_orderBy = "name";
					break;
				default:
					_orderBy = null;
					break;
			}

			if (sortDesc[0] === true) {
				_orderBy = "-" + _orderBy;
			}

			this.$apollo.queries.groups.setVariables({
				first: _first,
				last: _last,
				before: _before,
				after: _after,
				orderBy: _orderBy,
				searchGroup: this.searchGroups,
			});

			// set skip false to execute the query
			this.$apollo.queries.groups.skip = false;
			this.$apollo.queries.groups.refresh();

			// update this.page
			this.page = page;
		},

		handleSearchGroups(search) {
			this.$store
				.dispatch("user/searchGroups", search)
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		onClearSearchGroups() {
			this.handleSearchGroups("");
			this.refreshGroupsCurrentPage();
		},

		onGroupCreated(response) {
			this.groupDialog = false;
			this.goToGroupDetail(response.id);
		},

		addGroup() {
			this.editedGroup = null;
			this.groupDialog = true;
		},

		goToGroupDetail(groupNodeId) {
			this.$router.push({
				name: "group-detail",
				params: {
					groupNodeId: groupNodeId,
				},
			});
		},

		refreshThemesCurrentPage() {
			const {
				sortBy,
				sortDesc,
				page,
				itemsPerPage,
			} = this.themesDataTableOptions;

			var _first = itemsPerPage;
			var _last = null;
			var _before = null;
			var _after = null;
			var _orderBy = null;

			if (page < this.page) {
				_first = null;
				_last = itemsPerPage;
				_before = this.themes.pageInfo.startCursor;
				_after = null;
			}

			if (page > this.page) {
				_first = itemsPerPage;
				_last = null;
				_before = null;
				_after = this.themes.pageInfo.endCursor;
			}

			switch (sortBy[0]) {
				case "node.themeName":
					_orderBy = "themeName";
					break;
				default:
					_orderBy = null;
					break;
			}

			if (sortDesc[0] === true) {
				_orderBy = "-" + _orderBy;
			}

			this.$apollo.queries.themes.setVariables({
				first: _first,
				last: _last,
				before: _before,
				after: _after,
				orderBy: _orderBy,
				search: this.searchThemes,
			});

			// set skip false to execute the query
			this.$apollo.queries.themes.skip = false;
			this.$apollo.queries.themes.refresh();

			// update this.page
			this.page = page;
		},

		handleSearchThemes(search) {
			this.$store
				.dispatch("user/searchThemes", search)
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		onClearSearchThemes() {
			this.handleSearchThemes("");
			this.refreshThemesCurrentPage();
		},

		onThemeCreated() {
			this.themeDialog = false;
			this.$apollo.queries.themes.refresh();
		},

		addTheme() {
			this.editedTheme = null;
			this.themeDialog = true;
		},

		editTheme(theme) {
			this.editedTheme = JSON.parse(JSON.stringify(theme));
			this.themeDialog = true;
		},

		deletingTheme() {
			this.deletedItem.type = "themes";
			var selectedThemes = this.selectedThemes;
			function getThemeIds(selectedThemes) {
				var themeIds = [];
				for (var i = 0; i < selectedThemes.length; i++) {
					themeIds.push(selectedThemes[i].node.id);
				}
				return themeIds;
			}
			this.deletedItem.ids = getThemeIds(selectedThemes);
			this.deleteDialog = true;
		},

		// delete confirmed
		deletingConfirmed() {
			switch (this.deletedItem.type) {
				case "tenants":
					this.callDeleteTenant();
					break;
				case "groups":
					this.callDeleteGroup();
					break;
				case "themes":
					this.callDeleteTheme();
					break;
				default:
					return;
			}
		},

		callDeleteTenant() {
			if (this.deletedItem) {
				const payload = {
					ids: this.deletedItem.ids,
				};

				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation deleteTenants(
								$input: DeleteTenantsInput!
							) {
								deleteTenants(input: $input) {
									tenant {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// refresh plants
						this.$apollo.queries.tenants.refresh();

						// close delete dialog
						this.deleteDialog = false;

						// show snackbar
						const payload = {
							color: "success",
							message: `Tenants successfully deleted`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
					});

				this.selectedTenants = [];
			}
		},

		callDeleteTheme() {
			if (this.deletedItem) {
				const payload = {
					ids: this.deletedItem.ids,
				};

				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation deleteThemes($input: DeleteThemesInput!) {
								deleteThemes(input: $input) {
									theme {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// refresh plants
						this.$apollo.queries.themes.refresh();

						// close delete dialog
						this.deleteDialog = false;

						// show snackbar
						const payload = {
							color: "success",
							message: `Themes successfully deleted`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
					});

				this.selectedThemes = [];
			}
		},
	},
};
</script>
