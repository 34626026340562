<template>
	<v-container fluid>
		<!-- Main -->
		<v-row class="align-center">
			<!-- Breadcrumbs -->
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
			<v-spacer />
		</v-row>

		<!-- <ReportDetailSettingsComponent
			:reportNodeId="$route.params.id"
			v-on:item-fetched="onReportFetched"
		></ReportDetailSettingsComponent> -->
		<DeleteDialog
			:dialog.sync="deleteDialog"
			v-on:confirmed="deletingSelectedUserReportFiltersConfirmed"
		>
			<template v-slot:title>{{ $t("deleteDialog.title") }}</template>
			<template v-slot:content>{{ $t("deleteDialog.content") }}</template>
		</DeleteDialog>

		<v-row>
			<v-col cols="12" class="pt-0">
				<v-card
					:class="{
						'mx-0 elevation-5': $vuetify.breakpoint.xsOnly,
						'mx-3 elevation-5': $vuetify.breakpoint.smAndUp,
					}"
				>
					<v-tabs centered show-arrows>
						<v-tabs-slider color="primary"></v-tabs-slider>

						<!-- tab header -->
						<v-tab>
							<v-icon left>collections_bookmark</v-icon
							>{{
								$t(
									"reportsDetailSettings.userReportFilters.title"
								)
							}}
						</v-tab>

						<!-- tab body -->
						<!-- User Report Filter -->
						<v-tab-item>
							<v-card flat>
								<v-card-title class="pb-0">
									<v-col cols="12" sm="4"></v-col>
									<v-col cols="12" sm="4">
										<v-text-field
											dense
											v-model="searchUserReportFilter"
											append-outer-icon="refresh"
											@click:append-outer="
												refreshUserReportFiltersCurrentPage()
											"
											v-bind:label="
												$t('datatable.searchLabel')
											"
											clearable
											hide-details
											@keydown.enter="
												refreshUserReportFiltersCurrentPage()
											"
											@click:clear="
												onClearUserReportFiltersSearch()
											"
										></v-text-field>
									</v-col>
									<v-spacer></v-spacer>
									<v-btn
										class="mr"
										color="error"
										dark
										outlined
										rounded
										small
										:disabled="
											selectedUserReportFilters.length <=
												0
										"
										@click="
											deletingSelectedUserReportFilters()
										"
										>{{
											$t(
												"reportsDetailSettings.userReportFilters.deleteBtn"
											)
										}}</v-btn
									>
								</v-card-title>

								<v-container fluid>
									<v-data-table
										:headers="userReportFiltersHeaders"
										:loading="
											$apollo.queries.userReportFilters
												.loading
										"
										:items="userReportFilters.edges"
										:options.sync="
											userReportFiltersDataTableOptions
										"
										:server-items-length="
											userReportFilters.totalCount
										"
										:items-per-page="defaultItemsPerPage"
										:footer-props="{
											'items-per-page-options': [
												10,
												25,
												50,
												75,
												100,
											],
											'disable-pagination':
												$apollo.queries
													.userReportFilters.loading,
										}"
										v-model="selectedUserReportFilters"
										item-key="node.id"
										show-select
									>
									</v-data-table>
								</v-container>
							</v-card>
						</v-tab-item>
					</v-tabs>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import DeleteDialog from "@/components/base/DeleteDialog.vue";

export default {
	name: "report-detail-settings-page",
	components: {
		DeleteDialog,
	},

	apollo: {
		report: {
			query: gql`
				query report($id: ID!) {
					report(id: $id) {
						id
						reportName
						groupId
						reportId
						datasetId
						pbiDateColumn
						pbiDateTable
						isEditable
					}
				}
			`,
			variables() {
				return {
					id: this.$route.params.id,
				};
			},
			// Additional options here
			fetchPolicy: "network-only",
			//update: (data) => data.report,
			update(data) {
				//send data to parent component
				this.$emit("item-fetched", data.report);

				//return result to current componet
				return data.report;
				// return JSON.parse(JSON.stringify(data.product));
			},
			pollInterval: 60000, // ms
		},

		userReportFilters: {
			query: gql`
				query userReportFilters(
					$reportId: ID!
					$first: Int
					$last: Int
					$before: String
					$after: String
					$orderBy: String
					$search: String
				) {
					userReportFilters(
						report_Id: $reportId
						first: $first
						last: $last
						before: $before
						after: $after
						search: $search
						orderBy: $orderBy
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								filterJson
								filterName
								id
								user {
									id
									email
								}
							}
						}
					}
				}
			`,
			variables() {
				return {
					reportId: this.$route.params.id,
				};
			},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.userReportFilters,
			pollInterval: 60000, // ms
		},
	},

	data() {
		return {
			deleteDialog: false,

			report: {},
			userReportFilters: {},

			componentKey: 0,

			searchUserReportFilter: "",

			defaultItemsPerPage: 25,
			userReportFiltersDataTableOptions: {},
			page: 1,

			selectedUserReportFilters: [],

			// table headers
			userReportFiltersHeaders: [
				{
					text: this.$t(
						"reportsDetailSettings.userReportFilters.datatableHeaders.filterName"
					),
					value: "node.filterName",
					sortable: true,
				},
			],
		};
	},

	computed: {
		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("reportsDetailSettings.breadcrumbs.home"),
					disable: false,
					href: "/#/",
				},
				{
					text: this.$t("reportsDetailSettings.breadcrumbs.reports"),
					disable: false,
					href: "/#/reports",
				},
				{
					text: this.report.reportName,
					disabled: false,
					href: "/#/report/" + this.report.id,
				},
				{
					text: this.$t("reportsDetailSettings.breadcrumbs.settings"),
					disabled: true,
				},
			];
			return breadcrumbs;
		},
	},
	watch: {
		userReportFiltersDataTableOptions: {
			handler() {
				this.refreshUserReportFiltersCurrentPage();
			},
			deep: true,
		},
	},
	created() {},
	mounted() {},
	methods: {
		refreshUserReportFiltersCurrentPage() {
			const {
				sortBy,
				sortDesc,
				page,
				itemsPerPage,
			} = this.userReportFiltersDataTableOptions;

			var _first = itemsPerPage;
			var _last = null;
			var _before = null;
			var _after = null;
			var _orderBy = null;

			if (page < this.page) {
				_first = null;
				_last = itemsPerPage;
				_before = this.userReportFilters.pageInfo.startCursor;
				_after = null;
			}

			if (page > this.page) {
				_first = itemsPerPage;
				_last = null;
				_before = null;
				_after = this.userReportFilters.pageInfo.endCursor;
			}

			switch (sortBy[0]) {
				case "node.filterName":
					_orderBy = "filterName";
					break;
				default:
					_orderBy = null;
					break;
			}

			if (sortDesc[0] === true) {
				_orderBy = "-" + _orderBy;
			}

			this.$apollo.queries.userReportFilters.setVariables({
				reportId: this.$route.params.id,
				first: _first,
				last: _last,
				before: _before,
				after: _after,
				orderBy: _orderBy,
				search: this.searchUserReportFilter,
			});

			// set skip false to execute the query
			this.$apollo.queries.userReportFilters.skip = false;
			this.$apollo.queries.userReportFilters.refresh();

			// update this.page
			this.page = page;
		},

		onClearUserReportFiltersSearch() {
			this.searchUserReportFilter = "";

			this.refreshUserReportFiltersCurrentPage();
		},

		deletingSelectedUserReportFilters() {
			this.deleteDialog = true;
		},

		deletingSelectedUserReportFiltersConfirmed() {
			var selectedUserReportFilters = this.selectedUserReportFilters;
			// console.log(selectedUserReportFilters);
			function getUserReportFilterIds(selectedUserReportFilters) {
				var userReportFilterIds = [];
				for (var i = 0; i < selectedUserReportFilters.length; i++) {
					userReportFilterIds.push(
						selectedUserReportFilters[i].node.id
					);
				}
				return userReportFilterIds;
			}
			var userReportFilterIds = getUserReportFilterIds(
				selectedUserReportFilters
			);

			const payload = {
				ids: userReportFilterIds,
			};

			// update
			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation deleteUserReportFilters(
							$input: DeleteUserReportFiltersInput!
						) {
							deleteUserReportFilters(input: $input) {
								userReportFilter {
									id
								}
							}
						}
					`,
					// Parameters
					variables: {
						input: payload,
					},
				})
				.then((response) => {
					// refresh filters
					this.$apollo.queries.userReportFilters.refresh();

					// close delete dialog
					this.deleteDialog = false;

					// show snackbar
					const payload = {
						color: "success",
						message: `Filters successfully deleted!`,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				});
			// update state of selectedReports
			this.selectedUserReportFilters = [];
		},
	},
};
</script>
