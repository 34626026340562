<template>
  <v-container fluid>
    <!-- Main -->
    <v-row class="align-center pl-3">
      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
    </v-row>

    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card
          :class="{
            'mx-0 elevation-5': $vuetify.breakpoint.mobile,
          }"
          loading="$apollo.queries.report.loading"
        >
          <v-card-title class="pb-0">
            <v-icon color="primary" class="mx-2">bar_chart</v-icon>Paginated
            Report
            <v-spacer></v-spacer>
          </v-card-title>

          <!-- report card -->
          <v-container fluid>
            <!-- report iframe -->
            <div
              v-if="$vuetify.breakpoint.smAndUp"
              id="reportContainer"
              style="width: 100%; height: 83vh; border-style: none"
            ></div>
            <div
              class="mx-n2"
              v-if="$vuetify.breakpoint.xsOnly"
              id="reportContainer"
              style="width: 105%; height: 100vh; border-style: none"
            ></div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import * as pbi from "powerbi-client";

export default {
  name: "paginated-report-detail-page",
  components: {},

  apollo: {
    pbiEmbedToken: {
      query: gql`
        query pbiEmbedToken($reportNodeId: ID!) {
          pbiEmbedToken(reportNodeId: $reportNodeId) {
            expiration
            odataContext
            token
            tokenId
          }
        }
      `,
      variables() {
        return {
          reportNodeId: this.$route.params.reportNodeId,
        };
      },
      fetchPolicy: "network-only",
      skip: true,
      update: (data) => data.pbiEmbedToken,
      result({ data }) {
        var expiration = Date.parse(data.pbiEmbedToken.expiration);

        var safetyInterval = 5 * 60 * 1000;
        var currentTime = Date.now();

        // time until token refresh in milliseconds
        var timeout = expiration - currentTime - safetyInterval;

        console.log(
          "Report Embed Token will be updated in " +
            timeout / 60000 +
            " minutes."
        );

        // set the refresh of the next token
        this.$apollo.queries.pbiEmbedToken.stopPolling();
        this.$apollo.queries.pbiEmbedToken.startPolling(timeout); //ms

        if (this.nrOfTokens == 0) {
          this.loadReport();
        } else {
          console.log("update");
          this.updateToken();
        }

        this.nrOfTokens++;
      },
      // Error handling
      error(error) {
        console.error("We've got an error!", error);
        this.error = error.graphQLErrors
          .map((error) => error.message)
          .join(", ");
      },
    },

    report: {
      query: gql`
        query report($reportNodeId: ID!) {
          report(id: $reportNodeId) {
            id
            reportName
            groupId
            reportId
            datasetId
            isBinded
            bindedGroupId
            bindedDatasetId
            pbiDateColumn
            pbiDateTable
            isEditable
            reportType
          }
        }
      `,
      variables() {
        return {
          reportNodeId: this.$route.params.reportNodeId,
        };
      },
      fetchPolicy: "network-only",
      update: (data) => data.report,
      pollInterval: 60000, // ms
      result() {
        this.$apollo.queries.pbiEmbedToken.skip = false;
      },
    },
  },

  data() {
    return {
      error: null,
      nrOfTokens: 0,
      pbiEmbedToken: {},
      report: {},
      rules: {
        required: (v) => !helper.isEmpty(v) || "This field is required",
        minLength: (len) => (v) =>
          (v || "").length >= len ||
          `Invalid character length, required ${len}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len || "Invalid character length, too long",
      },
    };
  },

  computed: {
    breadcrumbs() {
      let breadcrumbs = [
        {
          text: this.$t("reportDetail.breadcrumbs.home"),
          disable: false,
          href: "/#/",
        },
        {
          text: this.$t("reportDetail.breadcrumbs.reports"),
          disable: false,
          href: "/#/reports",
        },
        {
          text: "Paginated Report",
          disabled: true,
        },
      ];
      return breadcrumbs;
    },

    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {},
  created() {
    this.hasPermission = helper.hasPermission;

    this.logPageView();
  },
  mounted() {},
  methods: {
    getReportContainer() {
      // Get a reference to the embedded report HTML element
      var embedContainer = document.getElementById("reportContainer");

      // Get a reference to the embedded report.
      var report = powerbi.get(embedContainer);

      return report;
    },

    async updateToken() {
      let report = this.getReportContainer();

      // Set the new access token
      await report.setAccessToken(this.pbiEmbedToken.token);
    },

    loadReport() {
      var reportId = this.report.reportId;

      var models = pbi.models;

      let embedUrl =
        "https://app.powerbi.com/rdlEmbed?reportId=" +
        reportId +
        "&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUVVUk9QRS1OT1JUSC1CLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d";

      // Create the embed configuration object for the paginated report
      // For more information see https://go.microsoft.com/fwlink/?linkid=2153700
      let config = {
        accessToken: this.pbiEmbedToken.token,
        embedUrl: embedUrl,
        id: reportId,
        permissions: models.Permissions.View,
        tokenType: models.TokenType.Embed,
        type: "report",
      };

      var embedConfiguration = Object.assign(config);

      var element = document.getElementById("reportContainer");
      var report = powerbi.embed(element, embedConfiguration);

      // After embedded delete the border of the iframe
      var iframe = document.getElementsByTagName("iframe")[0];
      iframe.setAttribute(
        "style",
        "width: 100%; height: 100%; border-style: none;"
      );
    },

    logPageView() {
      // prepare api call payload
      var payload = {
        logType: "pageview",
        refType: "report",
        refNodeId: this.$route.params.reportNodeId,
      };

      // new
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation createLog($input: CreateLogInput!) {
              createLog(input: $input) {
                log {
                  id
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: payload,
          },
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
  },
};
</script>
