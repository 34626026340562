<template>
	<v-row justify="center">
		<!-- Severity Dialog -->
		<SeverityEditDialog
			:dialog.sync="severityDialog"
			v-on:item-created="severityAdded"
		></SeverityEditDialog>

		<!-- add empty row to fit to page on mobile -->
		<v-row class="ma-0 pa-0"></v-row>

		<!-- Edit system -->
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card>
				<v-alert v-if="error" tile type="error">{{ error }}</v-alert>

				<v-card-title class="primary white--text">
					{{ formTitle }}
					<v-spacer></v-spacer>
				</v-card-title>

				<v-card-text class="pt-5">
					<v-form ref="form">
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.systemName"
									:counter="100"
									:rules="[
										rules.required,
										rules.maxLength(100),
									]"
									v-bind:label="
										$t('systemEditDialog.systemNameLabel')
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.systemDescription"
									:counter="100"
									:rules="[
										rules.required,
										rules.maxLength(255),
									]"
									v-bind:label="
										$t('systemEditDialog.descriptionLabel')
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<!-- Severity -->
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.severityNodeId"
									:items="severities.edges"
									item-text="node.severityName"
									item-value="node.id"
									:loading="
										$apollo.queries.severities.loading
									"
									:rules="[rules.required]"
									v-bind:label="
										$t('systemEditDialog.severityLabel')
									"
									class="subtitle-2"
									outlined
									clearable
								>
									<template v-slot:append-outer>
										<v-btn text small tabindex="-1">
											<v-icon
												color="grey"
												@click="addSeverity"
												tabindex="-1"
												>add</v-icon
											>
										</v-btn>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn
						:disabled="isSaving"
						text
						rounded
						@click="closeSystemDialog"
						>{{ $t("button.cancelBtn") }}</v-btn
					>
					<v-btn
						outlined
						rounded
						:loading="isSaving"
						color="primary"
						@click="saveSystem"
						>{{ $t("button.saveBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import SeverityEditDialog from "@/components/status/SeverityEditDialog.vue";

export default {
	name: "system-edit-dialog",
	props: ["dialog", "object"],
	components: {
		SeverityEditDialog,
	},

	apollo: {
		severities: {
			query: gql`
				query severities {
					severities {
						edges {
							node {
								id
								severityName
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.severities,
			skip: true,
			pollInterval: 60000, // ms
		},
	},

	data() {
		return {
			error: null,
			isSaving: false,
			severityDialog: false,

			severities: {},

			defaultItem: {
				id: null,
				systemName: null,
				systemDescription: null,
				severityNodeId: null,
			},
			editedItem: {
				id: null,
				systemName: null,
				systemDescription: null,
				severityNodeId: null,
			},
			rules: {
				required: (v) => !helper.isEmpty(v) || "This field is required",
				minLength: (len) => (v) =>
					(v || "").length >= len ||
					`Invalid character length, required ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len || "Too long",
			},
		};
	},
	computed: {
		formTitle() {
			return this.object
				? this.$t("systemEditDialog.editTitle")
				: this.$t("systemEditDialog.newTitle");
		},
		me() {
			return this.$store.state.user.me;
		},
	},
	watch: {
		// reset form when dialog open or close
		dialog(val) {
			// if open dialog, initialize selections
			if (val) {
				this.$apollo.queries.severities.skip = false;
			}

			this.resetForm();
		},
	},
	created() {
		this.fetchMe();
	},
	methods: {
		fetchMe() {
			this.$store
				.dispatch("user/fetchMe")
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		resetForm() {
			// reset form state
			this.error = null;
			this.isSaving = false;
			this.panel = 0;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			// when editing, set form with object
			if (this.dialog && this.object) {
				this.editedItem.id = this.object.id;
				this.editedItem.systemName = this.object.systemName;
				this.editedItem.systemDescription = this.object.systemDescription;
				this.editedItem.severityNodeId = this.object.severity.id;
			}
			// when new or closing, set form with default
			else {
				this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
			}
		},

		closeSystemDialog() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		saveSystem() {
			if (!this.$refs.form.validate()) {
				return;
			}

			// set form state
			this.error = null;
			this.isSaving = true;

			// prepare api call payload
			var payload = {
				systemName: this.editedItem.systemName,
				systemDescription: this.editedItem.systemDescription,
				severityNodeId: this.editedItem.severityNodeId,
			};

			// if editing
			if (this.object) {
				// prepare graphql mutation payload
				payload.id = this.editedItem.id;

				// update
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation updateSystem($input: UpdateSystemInput!) {
								updateSystem(input: $input) {
									system {
										id
										createdAt
										modifiedAt
										systemName
										systemDescription
										severity {
											id
											createdAt
											modifiedAt
											severityName
											index
											color
											icon
										}
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// update parent object
						this.$emit("item-created", response);
						this.closeSystemDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `System successfully edited`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
						this.isSaving = false;
					})
					.finally(() => {});
			}
			// new
			else {
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation createSystem($input: CreateSystemInput!) {
								createSystem(input: $input) {
									system {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						this.$emit(
							"item-created",
							response.data.createSystem.system
						);
						this.closeSystemDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `System successfully added`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						console.log(error);

						this.error = error.graphQLErrors
							.map((error) => error.message)
							.join(", ");
						this.isSaving = false;
					})
					.finally(() => {});
			}
		},

		addSeverity() {
			this.severityDialog = true;
		},

		severityAdded(item) {
			this.$apollo.queries.severities.refresh();
			this.editedItem.severityNodeId = item.id;
		},
	},
};
</script>
