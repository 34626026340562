<template>
	<v-container fluid class="pa-0">
        <!-- Main -->
		<v-row class="align-center pl-3">
			<!-- Breadcrumbs -->
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
			<v-spacer />
		</v-row>

        <!-- Announcement Page Title -->
        <v-row align="center" class="mt-10 pl-5">
            <h1 style="text-align: center; font-weight: bold;">{{$t("announcements.announcementTitle")}}</h1>
        </v-row>

        <!-- Announcement Text Section -->
        <v-row class="mt-5 pl-5">
            <v-col>
            <p style="font-size: 18px;">
                {{$t("announcements.announcementHead1")}}
            </p>
            <p style="font-size: 18px;">
                {{$t("announcements.announcementHead2")}}
            </p>
            <p style="font-size: 18px;">
                {{$t("announcements.announcementMessage1")}}
            </p>
            <p style="font-size: 18px;" v-html="$t('announcements.announcementMessage2')"></p>
            <p style="font-size: 18px;">
                {{$t("announcements.announcementMessage3")}}
            </p>
            </v-col>
        </v-row>

        <!-- Image Section -->
        <v-row>
            <v-col cols="12" md="6" style="font-weight: bold;">
                {{$t("announcements.announcementOld")}}
                <img src="/static/NavigatieOud.png" alt="Announcement Old" style="width: 100%; border-radius: 8px;" />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="6" style="font-weight: bold;">
                {{$t("announcements.announcementNew")}}
            <img src="/static/NieuweLayoutVolledigScherm.png" alt="Announcement New" style="width: 100%; border-radius: 8px;" />
            </v-col>
            <v-col cols="12" md="6" style="font-weight: bold;">
                {{$t("announcements.announcementNewZoom")}}
            <img src="/static/NavigatieNieuw.png" alt="Announcement New Zoom" style="width: 100%; border-radius: 8px;" />
            </v-col>
        </v-row>

        <!-- Conclusion Section -->
        <v-row class="mt-5 pl-5">
            <v-col>
            <p style="font-size: 18px;">
                {{$t("announcements.announcementMid")}}
            </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
	computed: {
		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("announcements.breadcrumbs.home"),
					disable: false,
					href: "/#/",
				},
				{
					text: this.$t("announcements.breadcrumbs.announcements"),
					disabled: true,
				},
			];
			return breadcrumbs;
		},
	},
};
</script>
