<template>
	<v-row justify="center">
		<v-dialog v-model="dialog" persistent max-width="500px">
			<v-card>
				<v-alert v-if="error" tile type="error">{{ error }}</v-alert>
				<v-card-title class="primary white--text">{{
					formTitle
				}}</v-card-title>
				<v-card-text class="pt-5">
					<v-form ref="form">
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.name"
									:counter="50"
									:rules="[
										rules.required,
										rules.maxLength(50),
									]"
									v-bind:label="
										$t('groupEditDialog.groupNameLabel')
									"
									outlined
									class="subtitle-2"
									@keydown.enter="save"
									@submit.prevent
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn text rounded @click="close">{{
						$t("button.cancelBtn")
					}}</v-btn>
					<v-btn
						outlined
						rounded
						:loading="isSaving"
						color="primary"
						@click="save"
						>{{ $t("button.saveBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
	name: "group-edit-dialog",
	props: ["dialog", "object"],
	data() {
		return {
			error: null,
			isSaving: false,
			panel: 0,
			editedItem: {
				id: "",
				name: "",
			},
			defaultItem: {
				id: "",
				name: "",
			},
			rules: {
				required: (v) => !helper.isEmpty(v) || "This field is required",
				minLength: (len) => (v) =>
					(v || "").length >= len ||
					`Invalid character length, required ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len || "Too long",
			},
		};
	},
	computed: {
		formTitle() {
			return this.object
				? this.$t("groupEditDialog.editTitle")
				: this.$t("groupEditDialog.newTitle");
		},
	},
	watch: {
		// reset form when dialog open or close
		dialog() {
			this.resetForm();
		},
	},
	methods: {
		resetForm() {
			// reset form state
			this.error = null;
			this.isSaving = false;
			this.panel = 0;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			// when editing, set form with object
			if (this.dialog && this.object) {
				this.editedItem.id = this.object.id;
				this.editedItem.name = this.object.name;
			}
			// when new or closing, set form with default
			else {
				this.editedItem = Object.assign({}, this.defaultItem);
			}
		},

		close() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		save() {
			if (!this.$refs.form.validate()) {
				return;
			}

			// set form state
			this.error = null;
			this.isSaving = true;

			// prepare api call payload
			var payload = {
				name: this.editedItem.name,
			};

			// if editing
			if (this.object) {
				// prepare graphql mutation payload
				payload.id = this.editedItem.id;

				// update
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation updateGroup($input: UpdateGroupInput!) {
								updateGroup(input: $input) {
									group {
										id
										name
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// update parent object
						this.$emit("update:object", response);
						this.close();

						// show snackbar
						const payload = {
							color: "success",
							message: `Group successfully edited`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
						this.isSaving = false;
					})
					.finally(() => {});
			}
			// new
			else {
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation createGroup($input: CreateGroupInput!) {
								createGroup(input: $input) {
									group {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						this.$emit("item-created", response);
						this.close();

						// show snackbar
						const payload = {
							color: "success",
							message: `Group successfully added`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						console.log(error);

						this.error = error.graphQLErrors
							.map((error) => error.message)
							.join(", ");
						this.isSaving = false;
					})
					.finally(() => {});
			}
		},
	},
};
</script>
