const state = {
	exportingStatus: {
		isExporting: false,
		reportNodeId: null,
		exportId: null,
	},

	searchReports: "",
	searchUsermappings: "",
	searchReportGroups: "",
};

const mutations = {
	setExportingStatus(state, exportingStatus) {
		state.exportingStatus = exportingStatus;
		console.log(state.isExporting);
	},

	setSearchReports(state, search) {
		state.searchReports = search;
	},

	setSearchUsermappings(state, search) {
		state.searchUsermappings = search;
	},

	setSearchReportGroups(state, search) {
		state.searchReportGroups = search;
	},
};

const actions = {
	mutateExportingStatus(context, exportingStatus) {
		console.log(exportingStatus);
		context.commit("setExportingStatus", exportingStatus);
	},

	searchReports(context, search) {
		context.commit("setSearchReports", search);
	},

	searchUsermappings(context, search) {
		context.commit("setSearchUsermappings", search);
	},

	searchReportGroups(context, search) {
		context.commit("setSearchReportGroups", search);
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
