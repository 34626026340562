<template>
  <v-container fluid>
    <!-- Main -->
    <v-row class="align-center pl-3">
      <!-- Breadcrumbs -->
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
    </v-row>

    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card
          :class="{
            'mx-0 elevation-5': $vuetify.breakpoint.mobile,
          }"
          :loading="$apollo.queries.report.loading"
        >
          <v-card-title class="pb-0">
            <v-icon color="primary" class="mx-2">bar_chart</v-icon
            >{{ report.tenant.tenantName }} -
            {{ report.reportName }}
            <v-btn
              icon
              fab
              small
              v-if="!$vuetify.breakpoint.mobile"
              @click="goToReportDetailSettings(report.id)"
            >
              <v-icon>settings</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-title>

          <!-- report card -->
          <v-container fluid>
            <!-- button row -->
            <div v-if="!$vuetify.breakpoint.mobile">
              <v-row>
                <v-btn
                  class="mt-1 mb-4 ml-4 mr-2"
                  color="primary"
                  outlined
                  rounded
                  small
                  @click="fullscreenReport"
                  ><v-icon left outline>fullscreen</v-icon
                  >{{ $t("reportDetail.reportBtn.fullscreen") }}</v-btn
                >
                <v-btn
                  class="mt-1 mb-4 mr-2"
                  color="primary"
                  outlined
                  rounded
                  small
                  @click="printReport"
                  ><v-icon left>print</v-icon
                  >{{ $t("reportDetail.reportBtn.print") }}</v-btn
                >
                <v-btn
                  class="mt-1 mb-4 mr-2"
                  color="primary"
                  outlined
                  rounded
                  small
                  @click="reloadReport"
                  :disabled="reloadIsDisabled"
                  ><v-icon left>refresh</v-icon
                  >{{ $t("reportDetail.reportBtn.reload") }}</v-btn
                >
                <v-btn
                  v-if="
                    (hasPermission('dashboards.change_embedded_report') &&
                      this.report.isEditable) ||
                      this.me.isSuperuser
                  "
                  class="mt-1 mb-4 mr-2"
                  color="primary"
                  outlined
                  rounded
                  small
                  @click="editReport"
                  ><v-icon left>edit</v-icon
                  >{{ $t("reportDetail.reportBtn.edit") }}</v-btn
                >
                <v-btn
                  v-if="
                    (hasPermission('dashboards.change_embedded_report') &&
                      this.report.isEditable) ||
                      this.me.isSuperuser
                  "
                  class="mt-1 mb-4 mr-2"
                  color="primary"
                  outlined
                  rounded
                  small
                  @click="saveReport"
                  ><v-icon left>save</v-icon
                  >{{ $t("reportDetail.reportBtn.save") }}</v-btn
                >
                <v-btn
                  v-if="
                    (hasPermission('dashboards.change_embedded_report') &&
                      this.report.isEditable) ||
                      this.me.isSuperuser
                  "
                  class="mt-1 mb-4 mr-2"
                  color="primary"
                  outlined
                  rounded
                  small
                  @click="viewReport"
                  ><v-icon left>remove_red_eye</v-icon
                  >{{ $t("reportDetail.reportBtn.view") }}</v-btn
                >

                <v-col class="mt-1 mb-2 ml-1 mr-2 pa-0" cols="12" md="2">
                  <v-text-field
                    @submit.prevent
                    ref="form"
                    class="ma-0"
                    dense
                    clearable
                    hide-details
                    :loading="isSaving"
                    :rules="[rules.required, rules.maxLength(100)]"
                    v-bind:label="$t('reportDetail.reportBtn.filterNameLabel')"
                    append-outer-icon="save"
                    v-model="inputFilterName"
                    @click:append-outer="saveReportFilters"
                    @keydown.enter="saveReportFilters"
                  ></v-text-field>
                </v-col>
                <v-col class="mt-1 mb-1 ml-1 mr-2 pa-0" cols="12" md="2">
                  <v-select
                    v-model="selectedUserReportFilter"
                    class="ma-0"
                    dense
                    v-bind:label="
                      $t('reportDetail.reportBtn.selectFilterLabel')
                    "
                    :items="userReportFilters.edges"
                    item-text="node.filterName"
                    item-key="node.id"
                    item-value="node.filterJson"
                  ></v-select>
                </v-col>
              </v-row>
            </div>

            <!-- Select page filter -->
            <div v-if="$vuetify.breakpoint.mobile">
              <v-row>
                <v-col cols="8">
                  <v-select
                    v-model="selectedReportPage"
                    dense
                    label="Select Page"
                    :items="reportPages"
                    item-text="displayName"
                    item-key="name"
                    item-value="name"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    icon
                    color="primary"
                    :disabled="reloadIsDisabled"
                    @click="reloadReport"
                  >
                    <v-icon>refresh</v-icon>
                  </v-btn>
                  <v-btn icon color="primary" @click="fullscreenReport">
                    <v-icon>fullscreen</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <!-- report iframe -->
            <div
              v-if="$vuetify.breakpoint.smAndUp"
              id="reportContainer"
              style="width: 100%; height: 83vh; border-style: none"
            ></div>
            <div
              class="mx-n2"
              v-if="$vuetify.breakpoint.xsOnly"
              id="reportContainer"
              style="width: 105%; height: 100vh; border-style: none"
            ></div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import * as pbi from "powerbi-client";
import { apolloClient } from "@/vue-apollo";

export default {
  name: "report-detail-page",
  components: {},

  apollo: {
    pbiEmbedToken: {
      query: gql`
        query pbiEmbedToken($reportNodeId: ID!) {
          pbiEmbedToken(reportNodeId: $reportNodeId) {
            expiration
            odataContext
            token
            tokenId
          }
        }
      `,
      variables() {
        return {
          reportNodeId: this.$route.params.reportNodeId,
        };
      },
      fetchPolicy: "network-only",
      skip: true,
      update: (data) => data.pbiEmbedToken,
      result({ data }) {
        var expiration = Date.parse(data.pbiEmbedToken.expiration);

        var safetyInterval = 5 * 60 * 1000;
        var currentTime = Date.now();

        // time until token refresh in milliseconds
        var timeout = expiration - currentTime - safetyInterval;

        console.log(
        	"Report Embed Token will be updated in " +
        		timeout / 60000 +
        		" minutes."
        );

        // set the refresh of the next token
        this.$apollo.queries.pbiEmbedToken.stopPolling();
        this.$apollo.queries.pbiEmbedToken.startPolling(timeout); //ms

        if (this.nrOfTokens == 0) {
          this.loadReport();
        } else {
          this.updateToken();
        }

        this.nrOfTokens++;
      },
      // Error handling
      error(error) {
        console.error("We've got an error!", error);
        this.error = error.graphQLErrors
          .map((error) => error.message)
          .join(", ");
      },
    },

    report: {
      query: gql`
        query report($reportNodeId: ID!) {
          report(id: $reportNodeId) {
            id
            reportName
            groupId
            reportId
            datasetId
            isBinded
            bindedGroupId
            bindedDatasetId
            pbiDateColumn
            pbiDateTable
            isEditable
            reportType
            tenant {
              id
              tenantName
            }
          }
        }
      `,
      variables() {
        return {
          reportNodeId: this.$route.params.reportNodeId,
        };
      },
      fetchPolicy: "network-only",
      update: (data) => data.report,
      pollInterval: 60000,
      result() {
        this.$apollo.queries.pbiEmbedToken.skip = false;
      },
    },

    userReportFilters: {
      query: gql`
        query userReportFilters($reportNodeId: ID!) {
          userReportFilters(report_Id: $reportNodeId) {
            edges {
              node {
                filterJson
                filterName
                id
                user {
                  id
                  email
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          reportNodeId: this.$route.params.reportNodeId,
        };
      },
      // Additional options here
      fetchPolicy: "network-only",
      update: (data) => data.userReportFilters,
      pollInterval: 60000, // ms
    },
  },

  data() {
    return {
      error: null,
      inputFilterName: null,
      isInitialLoad: true,
      isSaving: false,
      nrOfTokens: 0,
      pbiEmbedToken: {},
      reloadIsDisabled: true,
      report: {
        tenant: {},
      },
      reportPages: [],
      selectedReportPage: {},
      selectedUserReportFilter: {},
      userReportFilters: {},

      rules: {
        required: (v) => !helper.isEmpty(v) || "This field is required",
        minLength: (len) => (v) =>
          (v || "").length >= len ||
          `Invalid character length, required ${len}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len || "Invalid character length, too long",
      },
    };
  },

  computed: {
    breadcrumbs() {
      let breadcrumbs = [
        {
          text: this.$t("reportDetail.breadcrumbs.home"),
          disable: false,
          href: "/#/",
        },
        {
          text: this.$t("reportDetail.breadcrumbs.reports"),
          disable: false,
          href: "/#/reports",
        },
        {
          text: `${this.report.tenant.tenantName} - ${this.report.reportName}`,
          disabled: true,
        },
      ];
      return breadcrumbs;
    },

    me() {
      return this.$store.state.user.me;
    },

    exportingStatus() {
      return this.$store.state.reports.exportingStatus;
    },
  },
  watch: {
    selectedUserReportFilter: function(val) {
      if (val) {
        this.setSelectedFilter(val);
      }
    },

    selectedReportPage: function(val) {
      if (val) {
        this.setSelectedPage(val);
      }
    },
  },
  created() {
    this.hasPermission = helper.hasPermission;

    this.logPageView();
  },
  mounted() {
    window.addEventListener("orientationchange", this.handleOrientationChange);
  },
  methods: {
    getReportContainer() {
      // Get a reference to the embedded report HTML element
      var embedContainer = document.getElementById("reportContainer");

      // Get a reference to the embedded report.
      var report = powerbi.get(embedContainer);

      return report;
    },

    async updateToken() {
      let report = this.getReportContainer();

      // Set the new access token
      await report.setAccessToken(this.pbiEmbedToken.token);
    },

    loadReport() {
      const self = this;

      var reportId = this.report.reportId;
      var datasetId = this.report.datasetId;
      var isBinded = this.report.isBinded;
      var bindedDatasetId = this.report.bindedDatasetId;

      var models = pbi.models;
      var pageNavigationSettings = {
        visible: true,
        position: models.PageNavigationPosition.Left,
      }

      // different settings for mobile and desktop
      var settings = {};
      if (self.$vuetify.breakpoint.mobile && !self.$vuetify.breakpoint.xsOnly) {
        settings = {
          settings: {
            filterPaneEnabled: true,
            navContentPaneEnabled: false,
            panes: {
              pageNavigation: pageNavigationSettings,
            }
          }
        };
      } else if (self.$vuetify.breakpoint.xsOnly) {
        settings = {
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            layoutType: models.LayoutType.MobilePortrait,
            panes: {
              pageNavigation: pageNavigationSettings,
            }
          }
        };
      } else {
        settings = {
          settings: {
            filterPaneEnabled: true,
            panes: {
              pageNavigation: pageNavigationSettings,
            }
          }
        };
      }

      // if binded dataset then use that else normal dataset
      var datasetIdToLoad = null;
      if (isBinded) {
        datasetIdToLoad = bindedDatasetId;
      } else {
        datasetIdToLoad = datasetId;
      }

      // if there is an initial bookmark set the state
      var bookmark = null;
      if (localStorage.getItem(self.$route.params.reportNodeId) !== null) {
        bookmark = {
          bookmark: {
            state: JSON.parse(
              localStorage.getItem(self.$route.params.reportNodeId)
            ).state,
          }
        };
      }

      var config = {
        accessToken: this.pbiEmbedToken.token,
        datasetBinding: {
          // If the binded dataset is empty then use the dataset otherwise use the binded dataset
          datasetId: datasetIdToLoad, // The wanted dataset id
        },
        embedUrl: "https://app.powerbi.com/reportEmbed",
        id: reportId,
        permissions: models.Permissions.All,
        tokenType: models.TokenType.Embed,
        type: "report",
        viewMode: models.ViewMode.View,
        settings: {
          panes: {
            pageNavigation: pageNavigationSettings,
          }
        }
      };

      var embedConfiguration = Object.assign(config, settings, bookmark);

      var element = document.getElementById("reportContainer");
      var report = powerbi.embed(element, embedConfiguration);

      // Report.off removes a given event handler if it exists.
      report.off("loaded");
      report.off("rendered");

      // Report.on will add an event handler which prints to Log window.
      report.on("loaded", function() {
        self.getReportPages();

        if (self.$vuetify.breakpoint.xsOnly) {
          self.handleOrientationChange();
        }
      });

      // report.on will add an event handler which prints to Log window.
      report.on("rendered", function() {
        if (bookmark !== null) {
          self.reloadIsDisabled = false;
        }

        if (!self.isInitialLoad) {
          self.saveCurrentReportState();
          self.reloadIsDisabled = false;
        } else {
          self.isInitialLoad = false;
        }
      });

      // After embedded delete the border of the iframe
      var iframe = document.getElementsByTagName("iframe")[0];
      iframe.setAttribute(
        "style",
        "width: 100%; height: 100%; border-style: none;"
      );
    },

    goToReportDetailSettings(reportNodeId) {
      this.$router.push({
        name: "report-detail-settings",
        params: { id: reportNodeId },
      });
    },

    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (
        this.$vuetify.breakpoint.xsOnly &&
        orientation === "portrait-primary"
      ) {
        // portrait mode
        var models = pbi.models;

        var newSettings = {
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
          layoutType: models.LayoutType.MobilePortrait,
          settings: {
            pane: {
              pageNavigation: {
                visible: true,
                position: models.PageNavigationPosition.Left,
              },
            }
          }
        };

        let report = this.getReportContainer();

        // Update the settings by passing in the new settings you have configured.
        report.updateSettings(newSettings).catch(function(errors) {
          console.log(errors);
        });
      } else if (orientation === "landscape-primary") {
        // portrait mode
        var models = pbi.models;

        var newSettings = {
          filterPaneEnabled: true,
          navContentPaneEnabled: false,
          layoutType: models.LayoutType.MobileLandscape,
          settings: {
            panes: {
              pageNavigation: {
                visible: true,
                position: models.PageNavigationPosition.Left,
              }
            }
          }
        };

        let report = this.getReportContainer();

        // Update the settings by passing in the new settings you have configured.
        report.updateSettings(newSettings).catch(function(errors) {
          console.log(errors);
        });
      }
    },

    async saveCurrentReportState() {
      let report = this.getReportContainer();

      // Get current report state
      let bookmarkCapturePromise = new Promise((resolve, reject) => {
        report.bookmarksManager
          .capture()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });

      let bookmarkCapture = await bookmarkCapturePromise;

      localStorage.setItem(
        this.$route.params.reportNodeId,
        JSON.stringify(bookmarkCapture)
      );
    },

    async getReportPages() {
      let report = this.getReportContainer();

      // Get current report state
      let getReportPages = new Promise((resolve, reject) => {
        report
          .getPages()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });

      let reportPages = await getReportPages;

      this.reportPages = reportPages.filter(function(reportpage) {
        return reportpage.visibility == 0;
      });
    },

    fullscreenReport() {
      let report = this.getReportContainer();

      // Displays the report in full screen mode.
      report.fullscreen();
    },

    printReport() {
      let report = this.getReportContainer();

      // Trigger the print dialog for your browser.
      report.print().catch(function(errors) {
        Log.log(errors);
      });
    },

    reloadReport() {
      localStorage.removeItem(this.$route.params.reportNodeId);

      this.reloadIsDisabled = true;
      this.isInitialLoad = true;

      this.loadReport();

      // Set filter name input to null
      this.inputFilterName = null;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      // Deselect the user report filter
      this.selectedUserReportFilter = {};

      this.selectedReportPage = {};
    },

    editReport() {
      let report = this.getReportContainer();

      // Displays the report in edit mode
      report.switchMode("edit");
    },

    saveReport() {
      let report = this.getReportContainer();

      // Save report
      report.save();
    },

    viewReport() {
      let report = this.getReportContainer();

      // Displays the report in view mode
      report.switchMode("view");
    },

    saveReportFilters() {
      let report = this.getReportContainer();

      if (!this.inputFilterName) {
        // show snackbar
        const payload = {
          color: "error",
          message: `Filter name is empty`,
        };
        this.$store.dispatch("snackbar/showMessage", payload);
      }

      report.getFilters().then((filters) => {
        // set form state
        this.error = null;
        this.isSaving = true;

        // prepare api call payload
        var payload = {
          reportNodeId: this.$route.params.reportNodeId,
          filterJson: filters,
          filterName: this.inputFilterName,
        };

        // new
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation createUserReportFilter(
                $input: CreateUserReportFilterInput!
              ) {
                createUserReportFilter(input: $input) {
                  userReportFilter {
                    id
                  }
                }
              }
            `,
            // Parameters
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            this.$emit("item-created", response);

            this.error = null;
            this.isSaving = false;
            if (this.$refs.form) {
              this.$refs.form.reset();
            }

            this.$apollo.queries.userReportFilters.refresh();

            // show snackbar
            const payload = {
              color: "success",
              message: `Filter successfully added!`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);
          })
          .catch((error) => {
            console.log(error);

            this.error = error.graphQLErrors
              .map((error) => error.message)
              .join(", ");
            this.isSaving = false;

            // show snackbar
            const payload = {
              color: "error",
              message: this.error,
            };
            this.$store.dispatch("snackbar/showMessage", payload);
          })
          .finally(() => {});
      });
    },

    logPageView() {
      // prepare api call payload
      var payload = {
        logType: "pageview",
        refType: "report",
        refNodeId: this.$route.params.reportNodeId,
      };

      // new
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation createLog($input: CreateLogInput!) {
              createLog(input: $input) {
                log {
                  id
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: payload,
          },
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    setSelectedPage(val) {
      let report = this.getReportContainer();

      report.setPage(val);
    },

    setSelectedFilter(item) {
      if (item) {
        var filter = JSON.parse(item);

        let report = this.getReportContainer();

        report.setFilters(filter);
      }
    },
  },
};
</script>
