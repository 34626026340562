import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { createProvider } from "./vue-apollo";
import Print from "vue-print-nb";
import i18n from "./i18n";
import VueGtag from "vue-gtag";

Vue.use(Print);

Vue.config.productionTip = false;

const moment = require("moment");
require("moment/locale/nl");
Vue.use(require("vue-moment"), {
	moment,
});

Vue.use(
	VueGtag,
	{
		config: {
			id: "G-CE2E26ZLST",
			// params: {
			// 	user_email: "user123",
			// },
		},
		// appName: "BI Portal",
		// pageTrackerScreenviewEnabled: true,
	},
	router
);

new Vue({
	router,
	store,
	vuetify,
	apolloProvider: createProvider(),
	i18n,
	render: (h) => h(App),
}).$mount("#app");
