<template>
	<v-container fluid>
		<!-- Main -->
		<v-row class="align-center pl-3">
			<!-- Breadcrumbs -->
			<!-- Breadcrumbs -->
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>

			<v-spacer></v-spacer>
			<v-btn
				color="primary"
				class="mr-2"
				rounded
				outlined
				small
				@click="groupDialog = true"
				>{{ $t("button.renameBtn") }}</v-btn
			>

			<v-btn
				color="error"
				class="mr-8"
				rounded
				outlined
				small
				@click="deleteGroupDialog = true"
				>{{ $t("button.deleteBtn") }}</v-btn
			>
		</v-row>

		<!-- Delete Group Dialog -->
		<DeleteDialog
			:dialog.sync="deleteGroupDialog"
			:item="group"
			v-on:confirmed="deleteGroupConfirmed"
		>
			<template v-slot:title>{{ $t("deleteDialog.title") }}</template>
			<template v-slot:content>{{ $t("deleteDialog.content") }}</template>
		</DeleteDialog>

		<!-- New Group Dialog-->
		<GroupEditDialog
			:object.sync="group"
			:dialog.sync="groupDialog"
		></GroupEditDialog>

		<!-- permission list -->
		<v-row>
			<v-col cols="12" class="pt-0">
				<v-card
					:class="{
						'mx-0 elevation-5': $vuetify.breakpoint.xsOnly,
						'mx-3 elevation-5': $vuetify.breakpoint.smAndUp,
					}"
					:loading="$apollo.queries.group.loading"
				>
					<v-alert v-if="error" tile type="error">{{
						error
					}}</v-alert>
					<!-- Header -->
					<v-card-title>
						<v-icon color="primary" class="mx-2">extension</v-icon
						>{{ $t("groupDetail.title") }}
						<v-btn
							v-if="isEditingPermissions"
							text
							rounded
							small
							color="primary"
							class="ml-5"
							@click="selectAllPermissions(true)"
							>{{ $t("button.selectAllBtn") }}</v-btn
						>
						<v-btn
							v-if="isEditingPermissions"
							text
							rounded
							small
							color="primary"
							class="ml-5"
							@click="selectAllPermissions(false)"
							>{{ $t("button.deselectAllBtn") }}</v-btn
						>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							dark
							outlined
							rounded
							small
							v-if="!isEditingPermissions"
							@click="startEditingPermissions"
							>{{ $t("button.editBtn") }}</v-btn
						>
						<v-btn
							text
							rounded
							small
							:disabled="isSavingPermissions"
							v-if="isEditingPermissions"
							@click="cancelEditingPermission"
							>{{ $t("button.cancelBtn") }}</v-btn
						>
						<v-btn
							color="primary"
							outlined
							rounded
							small
							:loading="isSavingPermissions"
							v-if="isEditingPermissions"
							@click="savePermissions"
							>{{ $t("button.saveBtn") }}</v-btn
						>
					</v-card-title>

					<v-card-text
						v-for="(sector, index) in permissionList"
						:key="index"
					>
						<v-card-title>
							<v-icon class="mr-3 primary--text"
								>lock_open</v-icon
							>
							{{ sector.header }}
						</v-card-title>

						<v-simple-table>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-left" width="40%">
											Module
										</th>
										<th>View</th>
										<th class="text-left">Add</th>
										<th class="text-left">
											Change
										</th>
										<th class="text-left">
											Delete
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item, index) in sector.items"
										:key="index"
									>
										<td>{{ item.name }}</td>
										<td>
											<v-checkbox
												v-model="
													permissions[
														'view_' + item.model
													]
												"
												:readonly="
													!isEditingPermissions
												"
												:loading="
													isSelectingPermissions
												"
												hide-details
												class="ma-0"
											></v-checkbox>
										</td>
										<td>
											<v-checkbox
												v-model="
													permissions[
														'add_' + item.model
													]
												"
												:readonly="
													!isEditingPermissions
												"
												hide-details
												class="ma-0"
											></v-checkbox>
										</td>
										<td>
											<v-checkbox
												v-model="
													permissions[
														'change_' + item.model
													]
												"
												:readonly="
													!isEditingPermissions
												"
												hide-details
												class="ma-0"
											></v-checkbox>
										</td>
										<td>
											<v-checkbox
												v-model="
													permissions[
														'delete_' + item.model
													]
												"
												:readonly="
													!isEditingPermissions
												"
												hide-details
												class="ma-0"
											></v-checkbox>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import GroupEditDialog from "@/components/users/GroupEditDialog.vue";

export default {
	name: "group-detail-page",
	components: {
		DeleteDialog,
		GroupEditDialog,
	},

	apollo: {
		group: {
			query: gql`
				query group($id: ID!) {
					group(id: $id) {
						id
						name
						userSet {
							edges {
								node {
									id
									email
									firstName
									lastName
									require2fa
									enabled2fa
									tenant {
										id
										tenantName
									}
								}
							}
						}
						permissions {
							edges {
								node {
									id
									codename
								}
							}
						}
					}
				}
			`,
			variables() {
				return {
					id: this.$route.params.groupNodeId,
				};
			},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update(data) {
				//send data to parent component
				this.$emit("item-fetched", data.group);

				if (!data.group) {
					alert("Unknown group!");
				}

				data.group.permissions.edges.forEach((item) => {
					this.permissions[item.node.codename] = true;
				});

				//return result to current componet
				return data.group;
			},
			pollInterval: 60000, // ms
		},
	},

	data() {
		return {
			groupDialog: false,
			deleteGroupDialog: false,
			error: null,
			isSelectingPermissions: false,
			isSavingPermissions: false,
			editGroupDialog: false,
			deleteGroupDialog: false,

			backedupPermissions: {},
			isEditingPermissions: false,
			allViewPermissions: false,
			// viewIndeterminate: true,

			group: {
				id: null,
				name: "",
				userSet: {},
				permissions: {},
			},

			permissionList: [
				{
					header: "Reports",
					items: [
						{ model: "embedded_report", name: "Embedded Report" },
						{ model: "usermapping", name: "User Mapping" },
					],
				},
				{
					header: "Users",
					items: [{ model: "customuser", name: "Users" }],
				},
				{
					header: "Status",
					items: [
						{ model: "incident", name: "Status" },
						{ model: "knowledgebase", name: "Knowledge Base" },
					],
				},
			],

			permissions: {},

			rules: {
				required: (v) => !helper.isEmpty(v) || "This field is required",
				minLength: (len) => (v) =>
					(v || "").length >= len ||
					`Invalid character length, required ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len || "Too long",
			},
		};
	},
	computed: {
		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("groupDetail.breadcrumbs.home"),
					disable: false,
					href: "/#/",
				},
				{
					text: this.$t("groupDetail.breadcrumbs.users"),
					disable: false,
					href: "/#/users",
				},
				{
					text: this.$t("groupDetail.breadcrumbs.settings"),
					disable: false,
					href: "/#/users/settings",
				},
				{
					text: this.group.name,
					disabled: true,
				},
			];
			return breadcrumbs;
		},
	},
	watch: {},
	created() {
		this.hasPermission = helper.hasPermission;
	},
	methods: {
		selectAllPermissions(isAllow) {
			this.isSelectingPermissions = true;
			// view
			this.selectSingleOperationPermissions("view", isAllow);
			// add
			this.selectSingleOperationPermissions("add", isAllow);
			// change
			this.selectSingleOperationPermissions("change", isAllow);
			// delete
			this.selectSingleOperationPermissions("delete", isAllow);

			this.isSelectingPermissions = false;
		},

		selectSingleOperationPermissions(operation, isAllow) {
			// e.g. setOperationPermissions("view", true)

			this.permissionList.forEach((element) => {
				element.items.forEach((item) => {
					this.permissions[operation + "_" + item.model] = isAllow;
				});
			});
		},

		startEditingPermissions() {
			// backup permissions
			this.backedupPermissions = JSON.parse(
				JSON.stringify(this.permissions)
			);

			this.isEditingPermissions = true;
		},

		cancelEditingPermission() {
			this.permissions = JSON.parse(
				JSON.stringify(this.backedupPermissions)
			);

			this.isEditingPermissions = false;
		},

		savePermissions() {
			this.error = null;

			const _selected_permissions = Object.keys(this.permissions).filter(
				(key) => this.permissions[key]
			);

			this.isSavingPermissions = true;

			const payload = {
				id: this.$route.params.groupNodeId,
				permissions: _selected_permissions,
			};

			// Call to the graphql mutation
			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation($input: UpdateGroupPermissionsInput!) {
							updateGroupPermissions(input: $input) {
								group {
									id
									name
									permissions {
										edges {
											node {
												id
												codename
											}
										}
									}
								}
							}
						}
					`,
					// Parameters
					variables: {
						input: payload,
					},
				})
				.then(() => {
					// Result
					this.isEditingPermissions = false;

					// show snackbar
					const payload = {
						color: "success",
						message: `Permissions successfully edited`,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					console.log(error);

					this.error = error.graphQLErrors
						.map((error) => error.message)
						.join(", ");
					this.isSaving = false;
				})
				.finally(() => {
					this.isSavingPermissions = false;
				});
		},

		onGroupDeleted() {
			// redirect to products list page
			this.$router.push("/users/settings");
		},

		deleteGroupConfirmed() {
			if (this.group) {
				const payload = {
					id: this.group.id,
				};
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation deleteGroup($input: DeleteGroupInput!) {
								deleteGroup(input: $input) {
									group {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// close delete dialog
						this.$router.push("/users/settings");

						// show snackbar
						const payload = {
							color: "success",
							message: `Group successfully deleted`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
					});
			}
		},
	},
};
</script>
