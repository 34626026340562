import Vue from "vue";
import Vuex from "vuex";
import snackbar from "@/store/modules/snackbar";
import user from "@/store/modules/user";
import reports from "@/store/modules/reports";

Vue.use(Vuex);

export default new Vuex.Store({
	// getters: getters,
	modules: {
		snackbar,
		user,
		reports,
	},
});
