var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('KnowledgeBaseTypeEditDialog',{attrs:{"dialog":_vm.knowledgeBaseTypeDialog},on:{"update:dialog":function($event){_vm.knowledgeBaseTypeDialog=$event},"item-created":_vm.knowledgeBaseTypeAdded}}),_c('v-row',{staticClass:"ma-0 pa-0"}),_c('v-dialog',{attrs:{"persistent":"","max-width":"1200px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.error)?_c('v-alert',{attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.formTitle)+" "),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pt-5"},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":100,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(100),
								],"label":_vm.$t(
										'knowledgeBaseEditDialog.knowledgeBaseItemTitleLabel'
									),"outlined":""},model:{value:(_vm.editedItem.knowledgeBaseTitle),callback:function ($$v) {_vm.$set(_vm.editedItem, "knowledgeBaseTitle", $$v)},expression:"editedItem.knowledgeBaseTitle"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{staticClass:"subtitle-2",attrs:{"items":_vm.knowledgeBaseTypes.edges,"item-text":"node.knowledgeBaseTypeName","item-value":"node.id","loading":_vm.$apollo.queries.knowledgeBaseTypes
										.loading,"rules":[_vm.rules.required],"label":_vm.$t('knowledgeBaseEditDialog.typeLabel'),"outlined":"","clearable":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"text":"","small":"","tabindex":"-1"}},[_c('v-icon',{attrs:{"color":"grey","tabindex":"-1"},on:{"click":_vm.addKnowledgeBaseType}},[_vm._v("add")])],1)]},proxy:true}]),model:{value:(_vm.editedItem.knowledgeBaseTypeNodeId),callback:function ($$v) {_vm.$set(_vm.editedItem, "knowledgeBaseTypeNodeId", $$v)},expression:"editedItem.knowledgeBaseTypeNodeId"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-textarea',{staticClass:"subtitle-2",attrs:{"label":_vm.$t(
										'knowledgeBaseEditDialog.descriptionLabel'
									),"outlined":"","rows":"7","counter":255,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(255),
								]},model:{value:(
									_vm.editedItem.knowledgeBaseDescription
								),callback:function ($$v) {_vm.$set(_vm.editedItem, "knowledgeBaseDescription", $$v)},expression:"\n\t\t\t\t\t\t\t\t\teditedItem.knowledgeBaseDescription\n\t\t\t\t\t\t\t\t"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-file-input',{attrs:{"outlined":"","small-chips":"","label":_vm.$t(
										'knowledgeBaseEditDialog.avatarLabel'
									),"prepend-icon":"","accept":"image/*"},on:{"change":_vm.onFileChange},model:{value:(_vm.selectedImage),callback:function ($$v) {_vm.selectedImage=$$v},expression:"selectedImage"}}),_c('v-switch',{staticClass:"my-0 py-0 mb-7",attrs:{"label":_vm.$t(
										'knowledgeBaseEditDialog.hasVideoLabel'
									),"hide-details":""},model:{value:(_vm.editedItem.hasVideo),callback:function ($$v) {_vm.$set(_vm.editedItem, "hasVideo", $$v)},expression:"editedItem.hasVideo"}}),(_vm.editedItem.hasVideo)?_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":100,"rules":[_vm.rules.maxLength(100)],"label":_vm.$t(
										'knowledgeBaseEditDialog.youtubeVideoUrlLabel'
									),"outlined":""},model:{value:(_vm.editedItem.videoUrl),callback:function ($$v) {_vm.$set(_vm.editedItem, "videoUrl", $$v)},expression:"editedItem.videoUrl"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-avatar',{attrs:{"size":"125","tile":""}},[_c('v-img',{staticStyle:{"border":"1px dashed #ccc"},attrs:{"src":_vm.imageUrl,"contain":""}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('tiptap-vuetify',{attrs:{"extensions":_vm.extensions},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)])],1)],1)],1),_c('v-card-actions',{staticClass:"pb-5 pr-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isSaving,"text":"","rounded":""},on:{"click":_vm.closeKnowledgeBaseDialog}},[_vm._v(_vm._s(_vm.$t("button.cancelBtn")))]),_c('v-btn',{attrs:{"outlined":"","rounded":"","loading":_vm.isSaving,"color":"primary"},on:{"click":_vm.saveKnowledgeBase}},[_vm._v(_vm._s(_vm.$t("button.saveBtn")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }