var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":"/"}}),_c('v-spacer')],1),_c('DeleteDialog',{attrs:{"dialog":_vm.deleteDialog},on:{"update:dialog":function($event){_vm.deleteDialog=$event},"confirmed":_vm.deletingSelectedUserReportFiltersConfirmed},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("deleteDialog.title")))]},proxy:true},{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.$t("deleteDialog.content")))]},proxy:true}])}),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{class:{
					'mx-0 elevation-5': _vm.$vuetify.breakpoint.xsOnly,
					'mx-3 elevation-5': _vm.$vuetify.breakpoint.smAndUp,
				}},[_c('v-tabs',{attrs:{"centered":"","show-arrows":""}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v("collections_bookmark")]),_vm._v(_vm._s(_vm.$t( "reportsDetailSettings.userReportFilters.title" ))+" ")],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pb-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}}),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","append-outer-icon":"refresh","label":_vm.$t('datatable.searchLabel'),"clearable":"","hide-details":""},on:{"click:append-outer":function($event){return _vm.refreshUserReportFiltersCurrentPage()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.refreshUserReportFiltersCurrentPage()},"click:clear":function($event){return _vm.onClearUserReportFiltersSearch()}},model:{value:(_vm.searchUserReportFilter),callback:function ($$v) {_vm.searchUserReportFilter=$$v},expression:"searchUserReportFilter"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"mr",attrs:{"color":"error","dark":"","outlined":"","rounded":"","small":"","disabled":_vm.selectedUserReportFilters.length <=
											0},on:{"click":function($event){return _vm.deletingSelectedUserReportFilters()}}},[_vm._v(_vm._s(_vm.$t( "reportsDetailSettings.userReportFilters.deleteBtn" )))])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.userReportFiltersHeaders,"loading":_vm.$apollo.queries.userReportFilters
											.loading,"items":_vm.userReportFilters.edges,"options":_vm.userReportFiltersDataTableOptions,"server-items-length":_vm.userReportFilters.totalCount,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{
										'items-per-page-options': [
											10,
											25,
											50,
											75,
											100,
										],
										'disable-pagination':
											_vm.$apollo.queries
												.userReportFilters.loading,
									},"item-key":"node.id","show-select":""},on:{"update:options":function($event){_vm.userReportFiltersDataTableOptions=$event}},model:{value:(_vm.selectedUserReportFilters),callback:function ($$v) {_vm.selectedUserReportFilters=$$v},expression:"selectedUserReportFilters"}})],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }