var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.error)?_c('v-alert',{attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.formTitle)+" "),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pt-5"},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"subtitle-2",attrs:{"items":_vm.incidents.edges,"item-text":"node.incidentName","item-value":"node.id","loading":_vm.$apollo.queries.incidents.loading,"rules":[_vm.rules.required],"label":_vm.$t(
										'incidentUpdateEditDialog.incidentLabel'
									),"outlined":"","clearable":"","disabled":""},model:{value:(_vm.editedItem.incident.id),callback:function ($$v) {_vm.$set(_vm.editedItem.incident, "id", $$v)},expression:"editedItem.incident.id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":100,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(100),
								],"label":_vm.$t(
										'incidentUpdateEditDialog.incidentUpdateNameLabel'
									),"outlined":""},model:{value:(_vm.editedItem.incidentUpdateName),callback:function ($$v) {_vm.$set(_vm.editedItem, "incidentUpdateName", $$v)},expression:"editedItem.incidentUpdateName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{staticClass:"subtitle-2",attrs:{"counter":255,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(255),
								],"label":_vm.$t(
										'incidentUpdateEditDialog.incidentUpdateDescriptionLabel'
									),"outlined":""},model:{value:(
									_vm.editedItem.incidentUpdateDescription
								),callback:function ($$v) {_vm.$set(_vm.editedItem, "incidentUpdateDescription", $$v)},expression:"\n\t\t\t\t\t\t\t\t\teditedItem.incidentUpdateDescription\n\t\t\t\t\t\t\t\t"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pb-5 pr-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isSaving,"text":"","rounded":""},on:{"click":_vm.closeIncidentUpdateDialog}},[_vm._v(_vm._s(_vm.$t("button.cancelBtn")))]),_c('v-btn',{attrs:{"outlined":"","rounded":"","loading":_vm.isSaving,"color":"primary"},on:{"click":_vm.saveIncidentUpdate}},[_vm._v(_vm._s(_vm.$t("button.saveBtn")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }