<template>
	<v-container fluid>
		<!-- Main -->
		<v-row class="align-center pl-3">
			<!-- Breadcrumbs -->
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
		</v-row>

		<!-- Delete Report Dialog -->
		<DeleteDialog
			:dialog.sync="deleteDialog"
			:item="deletedItem"
			v-on:confirmed="deletingConfirmed"
		>
			<template v-slot:title>{{ $t("deleteDialog.title") }}</template>
			<template v-slot:content>{{ $t("deleteDialog.content") }}</template>
		</DeleteDialog>

		<!-- Usermapping Dialog-->
		<UsermappingEditDialog
			:object.sync="editedUsermapping"
			:dialog.sync="usermappingDialog"
			v-on:item-created="onUsermappingCreated"
		></UsermappingEditDialog>
		<UsermappingAddDialog
			:object.sync="editedUsermapping"
			:dialog.sync="usermappingAddDialog"
			v-on:item-created="onUsermappingCreated"
		></UsermappingAddDialog>

		<!-- Report Group Dialog-->
		<ReportGroupEditDialog
			:object.sync="editedReportGroup"
			:dialog.sync="reportGroupDialog"
			v-on:item-created="onReportGroupCreated"
		></ReportGroupEditDialog>

		<v-row>
			<v-col cols="12" class="pt-0">
				<v-card
					:class="{
						'mx-0 elevation-5': $vuetify.breakpoint.xsOnly,
						'mx-3 elevation-5': $vuetify.breakpoint.smAndUp,
					}"
				>
					<v-tabs centered show-arrows>
						<v-tabs-slider color="primary"></v-tabs-slider>

						<!-- tab header -->
						<v-tab>
							<v-icon left>collections_bookmark</v-icon
							>{{ $t("reportsSettings.usermappings.title") }}
						</v-tab>
						<!-- tab header -->
						<v-tab v-if="me.isSuperuser">
							<v-icon left>tab</v-icon
							>{{
								$t("reportsSettings.reportGroups.title")
							}}</v-tab
						>

						<!-- tab body -->
						<!-- User Report Filter -->
						<v-tab-item>
							<v-card flat>
								<v-card-title class="pb-0">
									<v-col cols="12" sm="4"></v-col>
									<v-col cols="12" sm="4">
										<v-text-field
											dense
											:value="searchUsermappings"
											@input="handleSearchUsermappings"
											append-outer-icon="refresh"
											@click:append-outer="
												refreshUsermappingsCurrentPage()
											"
											v-bind:label="
												$t('datatable.searchLabel')
											"
											clearable
											hide-details
											@keydown.enter="
												refreshUsermappingsCurrentPage()
											"
											@click:clear="
												onClearSearchUsermappings()
											"
										></v-text-field>
									</v-col>
									<v-spacer></v-spacer>
									<v-btn
										v-if="
											hasPermission(
												'dashboards.delete_usermapping'
											)
										"
										class="mr-2"
										color="error"
										dark
										outlined
										rounded
										small
										:disabled="
											selectedUsermappings.length <= 0
										"
										@click="deletingSelectedUsermappings()"
										>{{
											$t(
												"reportsSettings.usermappings.deleteBtn"
											)
										}}</v-btn
									>
									<v-btn
										v-if="
											hasPermission(
												'dashboards.add_usermapping'
											)
										"
										color="primary"
										dark
										outlined
										rounded
										small
										@click="addUsermapping()"
										>{{
											$t(
												"reportsSettings.usermappings.newBtn"
											)
										}}</v-btn
									>
								</v-card-title>

								<v-container fluid>
									<v-data-table
										:headers="
											me.isSuperuser
												? usermappingHeadersSuperuser
												: usermappingHeaders
										"
										:loading="
											$apollo.queries.usermappings.loading
										"
										:items="usermappings.edges"
										:options.sync="
											usermappingsDataTableOptions
										"
										:server-items-length="
											usermappings.totalCount
										"
										:items-per-page="defaultItemsPerPage"
										:footer-props="{
											'items-per-page-options': [
												10,
												25,
												50,
												75,
												100,
											],
											'disable-pagination':
												$apollo.queries.usermappings
													.loading,
										}"
										v-model="selectedUsermappings"
										item-key="node.id"
										:show-select="
											hasPermission(
												'dashboards.delete_usermapping'
											)
												? 'show-select'
												: undefined
										"
									>
										<template
											v-slot:[`item.action`]="{ item }"
										>
											<v-btn
												v-if="
													hasPermission(
														'dashboards.change_usermapping'
													)
												"
												icon
												color="primary"
												@click="
													editUsermapping(item.node)
												"
											>
												<v-icon>edit</v-icon>
											</v-btn>
										</template>
									</v-data-table>
								</v-container>
							</v-card>
						</v-tab-item>

						<!-- Report Groups -->
						<v-tab-item v-if="me.isSuperuser">
							<v-card flat>
								<v-card-title class="pb-0">
									<v-col cols="12" sm="4"></v-col>
									<v-col cols="12" sm="4">
										<v-text-field
											dense
											:value="searchReportGroups"
											@input="handleSearchReportGroups"
											append-outer-icon="refresh"
											@click:append-outer="
												refreshReportGroupsCurrentPage()
											"
											v-bind:label="
												$t('datatable.searchLabel')
											"
											clearable
											hide-details
											@keydown.enter="
												refreshReportGroupsCurrentPage()
											"
											@click:clear="
												onClearSearchReportGroups()
											"
										></v-text-field>
									</v-col>
									<v-spacer></v-spacer>
									<v-btn
										class="mr-2"
										color="error"
										dark
										outlined
										rounded
										small
										:disabled="
											selectedReportGroups.length <= 0
										"
										@click="deletingSelectedReportGroups()"
										>{{
											$t(
												"reportsSettings.reportGroups.deleteBtn"
											)
										}}</v-btn
									>
									<v-btn
										color="primary"
										dark
										outlined
										rounded
										small
										@click="addReportGroup()"
										>{{
											$t(
												"reportsSettings.reportGroups.newBtn"
											)
										}}</v-btn
									>
								</v-card-title>

								<v-container fluid>
									<v-data-table
										:headers="reportGroupHeadersSuperuser"
										:loading="
											$apollo.queries.reportGroups.loading
										"
										:items="reportGroups.edges"
										:options.sync="
											reportGroupsDataTableOptions
										"
										:server-items-length="
											reportGroups.totalCount
										"
										:items-per-page="defaultItemsPerPage"
										:footer-props="{
											'items-per-page-options': [
												10,
												25,
												50,
												75,
												100,
											],
											'disable-pagination':
												$apollo.queries.reportGroups
													.loading,
										}"
										v-model="selectedReportGroups"
										item-key="node.id"
										:show-select="
											me.isSuperuser
												? 'show-select'
												: undefined
										"
									>
										<template
											v-slot:[`item.action`]="{ item }"
										>
											<v-btn
												v-if="me.isSuperuser"
												icon
												color="primary"
												@click="
													editReportGroup(item.node)
												"
											>
												<v-icon>edit</v-icon>
											</v-btn>
										</template>
									</v-data-table>
								</v-container>
							</v-card>
						</v-tab-item>
					</v-tabs>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import UsermappingEditDialog from "@/components/reports/UsermappingEditDialog.vue";
import UsermappingAddDialog from "@/components/reports/UsermappingAddDialog.vue";
import ReportGroupEditDialog from "@/components/reports/ReportGroupEditDialog.vue";

export default {
	name: "reports-settings-page",
	components: {
		DeleteDialog,
		UsermappingEditDialog,
		UsermappingAddDialog,
		ReportGroupEditDialog,
	},

	apollo: {
		usermappings: {
			query: gql`
				query usermappings(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$orderBy: String
					$search: String
				) {
					usermappings(
						first: $first
						last: $last
						before: $before
						after: $after
						search: $search
						orderBy: $orderBy
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								id
								user {
									id
									email
									firstName
									lastName
									tenant {
										id
										tenantName
									}
								}
								report {
									id
									reportName
									tenant {
										id
										tenantName
									}
								}
								rlsRole
							}
						}
					}
				}
			`,
			variables() {
				return {
					first: 25,
					// orderStatus_In: this.orderStatusFilter
				};
			},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.usermappings,
			skip: true,
			pollInterval: 60000, // ms
		},

		reportGroups: {
			query: gql`
				query reportGroups(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$orderBy: String
					$search: String
				) {
					reportGroups(
						first: $first
						last: $last
						before: $before
						after: $after
						search: $search
						orderBy: $orderBy
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								id
								reportGroupName
							}
						}
					}
				}
			`,
			variables() {
				return {
					first: 25,
					// orderStatus_In: this.orderStatusFilter
				};
			},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.reportGroups,
			skip: true,
			pollInterval: 60000, // ms
		},
	},

	data() {
		return {
			breadcrumbs: [
				{
					text: this.$t("reportsSettings.breadcrumbs.home"),
					disable: false,
					href: "/#/",
				},
				{
					text: this.$t("reportsSettings.breadcrumbs.reports"),
					disable: false,
					href: "/#/reports",
				},
				{
					text: this.$t("reportsSettings.breadcrumbs.settings"),
					disabled: true,
				},
			],

			defaultItemsPerPage: 25,
			page: 1,

			deleteDialog: false,
			deletedItem: {
				ids: [],
				type: null,
			},

			// usermappings
			usermappings: {},
			usermappingDialog: false,
			usermappingAddDialog: false,
			usermappingsDataTableOptions: {},
			editedUsermapping: {},
			selectedUsermappings: [],
			usermappingHeaders: [
				{
					text: this.$t(
						"reportsSettings.usermappings.datatableHeaders.email"
					),
					value: "node.user.email",
					sortable: true,
				},
				{
					text: this.$t(
						"reportsSettings.usermappings.datatableHeaders.reportName"
					),
					value: "node.report.reportName",
					sortable: true,
				},
				{
					text: this.$t(
						"reportsSettings.usermappings.datatableHeaders.rlsRole"
					),
					value: "node.rlsRole",
					sortable: true,
				},
				{
					text: this.$t("datatable.actionHeader"),
					align: "center",
					value: "action",
					sortable: false,
				},
			],
			usermappingHeadersSuperuser: [
				{
					text: this.$t(
						"reportsSettings.usermappings.datatableHeaders.userTenant"
					),
					value: "node.user.tenant.tenantName",
					sortable: true,
				},
				{
					text: this.$t(
						"reportsSettings.usermappings.datatableHeaders.email"
					),
					value: "node.user.email",
					sortable: true,
				},
				{
					text: this.$t(
						"reportsSettings.usermappings.datatableHeaders.reportTenant"
					),
					value: "node.report.tenant.tenantName",
					sortable: true,
				},
				{
					text: this.$t(
						"reportsSettings.usermappings.datatableHeaders.reportName"
					),
					value: "node.report.reportName",
					sortable: true,
				},
				{
					text: this.$t(
						"reportsSettings.usermappings.datatableHeaders.rlsRole"
					),
					value: "node.rlsRole",
					sortable: true,
				},
				{
					text: this.$t("datatable.actionHeader"),
					align: "center",
					value: "action",
					sortable: false,
				},
			],

			// report groups
			reportGroups: {},
			reportGroupDialog: false,
			reportGroupsDataTableOptions: {},
			editedReportGroup: {},
			selectedReportGroups: [],
			reportGroupHeadersSuperuser: [
				{
					text: this.$t(
						"reportsSettings.reportGroups.datatableHeaders.reportGroupName"
					),
					value: "node.reportGroupName",
					sortable: true,
				},
				{
					text: this.$t("datatable.actionHeader"),
					align: "center",
					value: "action",
					sortable: false,
				},
			],
		};
	},
	computed: {
		me() {
			return this.$store.state.user.me;
		},

		searchUsermappings() {
			return this.$store.state.reports.searchUsermappings;
		},

		searchReportGroups() {
			return this.$store.state.reports.searchReportGroups;
		},
	},
	watch: {
		usermappingsDataTableOptions: {
			handler() {
				this.refreshUsermappingsCurrentPage();
			},
			deep: true,
		},

		reportGroupsDataTableOptions: {
			handler() {
				this.refreshReportGroupsCurrentPage();
			},
			deep: true,
		},
	},
	created() {
		this.hasPermission = helper.hasPermission;
	},
	mounted() {},
	methods: {
		// usermappings
		refreshUsermappingsCurrentPage() {
			const {
				sortBy,
				sortDesc,
				page,
				itemsPerPage,
			} = this.usermappingsDataTableOptions;

			var _first = itemsPerPage;
			var _last = null;
			var _before = null;
			var _after = null;
			var _orderBy = null;

			if (page < this.page) {
				_first = null;
				_last = itemsPerPage;
				_before = this.usermappings.pageInfo.startCursor;
				_after = null;
			}

			if (page > this.page) {
				_first = itemsPerPage;
				_last = null;
				_before = null;
				_after = this.usermappings.pageInfo.endCursor;
			}

			switch (sortBy[0]) {
				case "node.user.tenant.tenantName":
					_orderBy = "userTenantName";
					break;
				case "node.user.email":
					_orderBy = "email";
					break;
				case "node.report.tenant.tenantName":
					_orderBy = "reportTenantName";
					break;
				case "node.report.reportName":
					_orderBy = "reportName";
					break;
				case "node.rlsRole":
					_orderBy = "rlsRole";
					break;
				default:
					_orderBy = "email";
					break;
			}

			if (sortDesc[0] === true) {
				_orderBy = "-" + _orderBy;
			}

			this.$apollo.queries.usermappings.setVariables({
				first: _first,
				last: _last,
				before: _before,
				after: _after,
				orderBy: _orderBy,
				search: this.searchUsermappings,
			});

			// set skip false to execute the query
			this.$apollo.queries.usermappings.skip = false;
			this.$apollo.queries.usermappings.refresh();

			// update this.page
			this.page = page;
		},

		handleSearchUsermappings(search) {
			this.$store
				.dispatch("reports/searchUsermappings", search)
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		onClearSearchUsermappings() {
			this.handleSearchUsermappings("");
			this.refreshUsermappingsCurrentPage();
		},

		onUsermappingCreated() {
			this.usermappingDialog = false;
			this.$apollo.queries.usermappings.refresh();
		},

		addUsermapping() {
			this.usermappingAddDialog = true;
		},

		editUsermapping(usermapping) {
			this.editedUsermapping = JSON.parse(JSON.stringify(usermapping));
			this.usermappingDialog = true;
		},

		deletingSelectedUsermappings() {
			this.deletedItem.type = "usermappings";
			var selectedUsermappings = this.selectedUsermappings;
			function getUsermappingIds(selectedUsermappings) {
				var usermappingIds = [];
				for (var i = 0; i < selectedUsermappings.length; i++) {
					usermappingIds.push(selectedUsermappings[i].node.id);
				}
				return usermappingIds;
			}
			this.deletedItem.ids = getUsermappingIds(selectedUsermappings);
			this.deleteDialog = true;
		},

		// report groups
		refreshReportGroupsCurrentPage() {
			const {
				sortBy,
				sortDesc,
				page,
				itemsPerPage,
			} = this.reportGroupsDataTableOptions;

			var _first = itemsPerPage;
			var _last = null;
			var _before = null;
			var _after = null;
			var _orderBy = null;

			if (page < this.page) {
				_first = null;
				_last = itemsPerPage;
				_before = this.reportGroups.pageInfo.startCursor;
				_after = null;
			}

			if (page > this.page) {
				_first = itemsPerPage;
				_last = null;
				_before = null;
				_after = this.reportGroups.pageInfo.endCursor;
			}

			switch (sortBy[0]) {
				case "node.reportGroupName":
					_orderBy = "reportGroupName";
					break;
				default:
					_orderBy = "reportGroupName";
					break;
			}

			if (sortDesc[0] === true) {
				_orderBy = "-" + _orderBy;
			}

			this.$apollo.queries.reportGroups.setVariables({
				first: _first,
				last: _last,
				before: _before,
				after: _after,
				orderBy: _orderBy,
				search: this.searchReportGroups,
			});

			// set skip false to execute the query
			this.$apollo.queries.reportGroups.skip = false;
			this.$apollo.queries.reportGroups.refresh();

			// update this.page
			this.page = page;
		},

		handleSearchReportGroups(search) {
			this.$store
				.dispatch("reports/searchReportGroups", search)
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		onClearSearchReportGroups() {
			this.handleSearchReportGroups("");
			this.refreshReportGroupsCurrentPage();
		},

		onReportGroupCreated() {
			this.reportGroupDialog = false;
			this.$apollo.queries.reportGroups.refresh();
		},

		addReportGroup() {
			this.editedReportGroup = null;
			this.reportGroupDialog = true;
		},

		editReportGroup(reportGroup) {
			this.editedReportGroup = JSON.parse(JSON.stringify(reportGroup));
			this.reportGroupDialog = true;
		},

		deletingSelectedReportGroups() {
			this.deletedItem.type = "reportGroups";
			var selectedReportGroups = this.selectedReportGroups;
			function getReportGroupIds(selectedReportGroups) {
				var reportGroupIds = [];
				for (var i = 0; i < selectedReportGroups.length; i++) {
					reportGroupIds.push(selectedReportGroups[i].node.id);
				}
				return reportGroupIds;
			}
			this.deletedItem.ids = getReportGroupIds(selectedReportGroups);
			this.deleteDialog = true;
		},

		// delete confirmed
		deletingConfirmed() {
			switch (this.deletedItem.type) {
				case "usermappings":
					this.callDeleteUsermapping();
					break;
				case "reportGroups":
					this.callDeleteReportGroup();
					break;
				default:
					return;
			}
		},

		callDeleteUsermapping() {
			if (this.deletedItem) {
				const payload = {
					ids: this.deletedItem.ids,
				};

				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation deleteUsermappings(
								$input: DeleteUsermappingsInput!
							) {
								deleteUsermappings(input: $input) {
									usermapping {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// refresh plants
						this.$apollo.queries.usermappings.refresh();

						// close delete dialog
						this.deleteDialog = false;

						// show snackbar
						const payload = {
							color: "success",
							message: `Usermappings successfully deleted`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
					});
				// update state of selectedReports
				this.selectedUsermappings = [];
			}
		},

		callDeleteReportGroup() {
			if (this.deletedItem) {
				const payload = {
					ids: this.deletedItem.ids,
				};

				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation deleteReportGroups(
								$input: DeleteReportGroupsInput!
							) {
								deleteReportGroups(input: $input) {
									reportGroup {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// refresh plants
						this.$apollo.queries.reportGroups.refresh();

						// close delete dialog
						this.deleteDialog = false;

						// show snackbar
						const payload = {
							color: "success",
							message: `Report Groups successfully deleted`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
					});
				// update state of selectedReports
				this.selectedReportGroups = [];
			}
		},
	},
};
</script>
