<template>
	<v-container fluid>
		<!-- Main -->
		<v-row class="align-center pl-3">
			<!-- Breadcrumbs -->
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
		</v-row>

		<!-- Delete Report Dialog -->
		<DeleteDialog
			:dialog.sync="deleteDialog"
			v-on:confirmed="deletingSelectedSeveritiesConfirmed"
		>
			<template v-slot:title>{{ $t("deleteDialog.title") }}</template>
			<template v-slot:content>{{ $t("deleteDialog.content") }}</template>
		</DeleteDialog>

		<!-- Severity Dialog-->
		<SeverityEditDialog
			:object.sync="editedSeverity"
			:dialog.sync="severityDialog"
			v-on:item-created="onSeverityCreated"
		></SeverityEditDialog>

		<v-row>
			<v-col cols="12" class="pt-0">
				<v-card
					:class="{
						'mx-0 elevation-5': $vuetify.breakpoint.xsOnly,
						'mx-3 elevation-5': $vuetify.breakpoint.smAndUp,
					}"
				>
					<v-tabs centered show-arrows>
						<v-tabs-slider color="primary"></v-tabs-slider>

						<!-- tab header -->
						<v-tab v-if="me.isSuperuser"
							><v-icon left>error</v-icon
							>{{ $t("statusSettings.severities.title") }}</v-tab
						>

						<!-- tab body -->
						<!-- Severity -->
						<v-tab-item v-if="me.isSuperuser">
							<v-card flat>
								<v-card-title class="pb-0">
									<v-col cols="12" sm="4"></v-col>
									<v-col cols="12" sm="4">
										<v-text-field
											dense
											v-model="searchSeverity"
											append-outer-icon="refresh"
											@click:append-outer="
												refreshSeveritiesCurrentPage()
											"
											v-bind:label="
												$t('datatable.searchLabel')
											"
											clearable
											hide-details
											@keydown.enter="
												refreshSeveritiesCurrentPage()
											"
											@click:clear="
												onClearSeveritiesSearch()
											"
										></v-text-field>
									</v-col>
									<v-spacer></v-spacer>
									<v-btn
										v-if="me.isSuperuser"
										class="mr-2"
										color="error"
										dark
										outlined
										rounded
										small
										:disabled="
											selectedSeverities.length <= 0
										"
										@click="deletingSelectedSeverities()"
										>{{
											$t(
												"statusSettings.severities.deleteBtn"
											)
										}}</v-btn
									>
									<v-btn
										v-if="me.isSuperuser"
										color="primary"
										dark
										outlined
										rounded
										small
										@click="addSeverity()"
										>{{
											$t(
												"statusSettings.severities.newBtn"
											)
										}}</v-btn
									>
								</v-card-title>

								<v-container fluid>
									<v-data-table
										:headers="severityHeaders"
										:loading="
											$apollo.queries.severities.loading
										"
										:items="severities.edges"
										:options.sync="
											severitiesDataTableOptions
										"
										:items-per-page="defaultItemsPerPage"
										:footer-props="{
											'items-per-page-options': [
												10,
												25,
												50,
												75,
												100,
											],
											'disable-pagination':
												$apollo.queries.severities
													.loading,
										}"
										v-model="selectedSeverities"
										item-key="node.id"
										:show-select="
											me.isSuperuser
												? 'show-select'
												: undefined
										"
									>
										<template
											v-slot:[`item.action`]="{ item }"
										>
											<v-btn
												v-if="me.isSuperuser"
												icon
												color="primary"
												@click="editSeverity(item.node)"
											>
												<v-icon>edit</v-icon>
											</v-btn>
										</template>
									</v-data-table>
								</v-container>
							</v-card>
						</v-tab-item>
					</v-tabs>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import helper from "@/utils/helper.js";
import gql from "graphql-tag";
import store from "@/store/index.js";
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import SeverityEditDialog from "@/components/status/SeverityEditDialog.vue";

export default {
	name: "status-settings-page",
	components: {
		DeleteDialog,
		SeverityEditDialog,
	},

	apollo: {
		severities: {
			query: gql`
				query severities(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$orderBy: String
					$search: String
				) {
					severities(
						first: $first
						last: $last
						before: $before
						after: $after
						search: $search
						orderBy: $orderBy
					) {
						edges {
							node {
								id
								index
								severityName
								icon
								color
							}
						}
					}
				}
			`,
			variables() {
				return {
					first: 10,
					// orderStatus_In: this.orderStatusFilter
				};
			},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.severities,
			skip: true,
			pollInterval: 60000, // ms
		},
	},

	data() {
		return {
			deleteDialog: false,
			severityDialog: false,

			severities: {},

			componentKey: 0,

			searchSeverity: "",

			defaultItemsPerPage: 10,
			severitiesDataTableOptions: {},
			page: 1,

			editedSeverity: {},

			selectedSeverities: [],

			// table headers
			severityHeaders: [
				{
					text: this.$t(
						"statusSettings.severities.datatableHeaders.index"
					),
					value: "node.index",
					sortable: true,
				},
				{
					text: this.$t(
						"statusSettings.severities.datatableHeaders.severityName"
					),
					value: "node.severityName",
					sortable: true,
				},
				{
					text: this.$t(
						"statusSettings.severities.datatableHeaders.icon"
					),
					value: "node.icon",
					sortable: false,
				},
				{
					text: this.$t(
						"statusSettings.severities.datatableHeaders.color"
					),
					value: "node.color",
					sortable: false,
				},
				{
					text: this.$t("datatable.actionHeader"),
					align: "center",
					value: "action",
					sortable: false,
				},
			],
		};
	},
	computed: {
		me() {
			return this.$store.state.user.me;
		},

		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("statusSettings.breadcrumbs.home"),
					disable: false,
					href: "/#/",
				},
				{
					text: this.$t("statusSettings.breadcrumbs.status"),
					disable: false,
					href: "/#/status",
				},
				{
					text: this.$t("statusSettings.breadcrumbs.settings"),
					disabled: true,
				},
			];
			return breadcrumbs;
		},
	},
	watch: {
		severitiesDataTableOptions: {
			handler() {
				this.refreshSeveritiesCurrentPage();
			},
			deep: true,
		},
	},
	created() {},
	mounted() {},
	methods: {
		refreshSeveritiesCurrentPage() {
			const {
				sortBy,
				sortDesc,
				page,
				itemsPerPage,
			} = this.severitiesDataTableOptions;

			var _first = itemsPerPage;
			var _last = null;
			var _before = null;
			var _after = null;
			var _orderBy = null;

			if (page < this.page) {
				_first = null;
				_last = itemsPerPage;
				_before = this.severities.pageInfo.startCursor;
				_after = null;
			}

			if (page > this.page) {
				_first = itemsPerPage;
				_last = null;
				_before = null;
				_after = this.severities.pageInfo.endCursor;
			}

			switch (sortBy[0]) {
				case "node.index":
					_orderBy = "index";
					break;
				case "node.severityName":
					_orderBy = "severityName";
					break;
				default:
					_orderBy = "index";
					break;
			}

			if (sortDesc[0] === true) {
				_orderBy = "-" + _orderBy;
			}

			this.$apollo.queries.severities.setVariables({
				first: _first,
				last: _last,
				before: _before,
				after: _after,
				orderBy: _orderBy,
				search: this.searchSeverity,
			});

			// set skip false to execute the query
			this.$apollo.queries.severities.skip = false;
			this.$apollo.queries.severities.refresh();

			// update this.page
			this.page = page;
		},

		onClearSeveritiesSearch() {
			this.searchSeverity = "";

			this.refreshSeveritiesCurrentPage();
		},

		onSeverityCreated() {
			this.severityDialog = false;
			this.$apollo.queries.severities.refresh();
		},

		addSeverity() {
			this.editedSeverity = null;
			this.severityDialog = true;
		},

		editSeverity(severity) {
			this.editedSeverity = JSON.parse(JSON.stringify(severity));
			this.severityDialog = true;
		},

		deletingSelectedSeverities() {
			this.deleteDialog = true;
		},

		deletingSelectedSeveritiesConfirmed() {
			var selectedSeverities = this.selectedSeverities;
			function getSeverityIds(selectedSeverities) {
				var severityIds = [];
				for (var i = 0; i < selectedSeverities.length; i++) {
					severityIds.push(selectedSeverities[i].node.id);
				}
				return severityIds;
			}
			var severityIds = getSeverityIds(selectedSeverities);

			const payload = {
				ids: severityIds,
			};

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation deleteSeverities(
							$input: DeleteSeveritiesInput!
						) {
							deleteSeverities(input: $input) {
								severity {
									id
								}
							}
						}
					`,
					// Parameters
					variables: {
						input: payload,
					},
				})
				.then((response) => {
					// refresh plants
					this.$apollo.queries.severities.refresh();

					// close delete dialog
					this.deleteDialog = false;

					// show snackbar
					const payload = {
						color: "success",
						message: `Tenants successfully deleted`,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					this.error = error.graphQLErrors[0].message;
				});
			// update state of selectedReports
			this.selectedSeverities = [];
		},
	},
};
</script>
