<template>
	<v-row justify="center">
		<!-- System Dialog -->
		<SystemEditDialog
			:dialog.sync="systemDialog"
			v-on:item-created="systemAdded"
		></SystemEditDialog>

		<!-- add empty row to fit to page on mobile -->
		<v-row class="ma-0 pa-0"></v-row>

		<!-- Edit Maintenance -->
		<v-dialog v-model="dialog" persistent max-width="900px">
			<v-card>
				<v-alert v-if="error" tile type="error">{{ error }}</v-alert>

				<v-card-title class="primary white--text">
					{{ formTitle }}
					<v-spacer></v-spacer>
				</v-card-title>

				<v-card-text class="pt-5">
					<v-form ref="form">
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.maintenanceName"
									:counter="100"
									:rules="[
										rules.required,
										rules.maxLength(100),
									]"
									v-bind:label="
										$t(
											'maintenanceEditDialog.maintenanceNameLabel'
										)
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="12">
								<v-textarea
									v-model="editedItem.maintenanceDescription"
									:counter="255"
									:rules="[
										rules.required,
										rules.maxLength(255),
									]"
									v-bind:label="
										$t(
											'maintenanceEditDialog.maintenanceDescriptionLabel'
										)
									"
									outlined
									class="subtitle-2"
								></v-textarea>
							</v-col>
							<!-- Date range -->
							<v-col cols="12" sm="6" md="4">
								<v-menu
									ref="dateMenu"
									v-model="dateMenu"
									:close-on-content-click="false"
									:return-value.sync="dateMenu"
									transition="scale-transition"
									offset-y
									min-width="290px"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="dateRange"
											v-bind:label="
												$t(
													'maintenanceEditDialog.dateRangeLabel'
												)
											"
											readonly
											v-bind="attrs"
											v-on="on"
											outlined
											:rules="[rules.required]"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="dateRange"
										scrollable
										first-day-of-week="1"
										locale-first-day-of-year="4"
										show-week
										range
										:min="minDate"
									>
										<v-spacer></v-spacer>
										<v-btn
											text
											color="primary"
											@click="dateMenu = false"
										>
											{{ $t("button.cancelBtn") }}
										</v-btn>
										<v-btn
											text
											color="primary"
											@click="
												$refs.dateMenu.save(dateRange)
											"
										>
											OK
										</v-btn>
									</v-date-picker>
								</v-menu>
							</v-col>
							<!-- Start Time -->
							<v-col cols="12" sm="6" md="4">
								<v-menu
									ref="timeMenuStart"
									v-model="timeMenuStart"
									:close-on-content-click="false"
									:return-value.sync="timeStart"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="290px"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="timeStart"
											v-bind:label="
												$t(
													'maintenanceEditDialog.startTimeLabel'
												)
											"
											readonly
											v-bind="attrs"
											v-on="on"
											outlined
										></v-text-field>
									</template>
									<v-time-picker
										v-if="timeMenuStart"
										v-model="timeStart"
										full-width
										@click:minute="
											$refs.timeMenuStart.save(timeStart)
										"
										format="24hr"
										scrollable
									></v-time-picker>
								</v-menu>
							</v-col>
							<!-- End Time -->
							<v-col cols="12" sm="6" md="4">
								<v-menu
									ref="timeMenuEnd"
									v-model="timeMenuEnd"
									:close-on-content-click="false"
									:return-value.sync="timeEnd"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="290px"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="timeEnd"
											v-bind:label="
												$t(
													'maintenanceEditDialog.endTimeLabel'
												)
											"
											readonly
											v-bind="attrs"
											v-on="on"
											outlined
										></v-text-field>
									</template>
									<v-time-picker
										v-if="timeMenuEnd"
										v-model="timeEnd"
										full-width
										@click:minute="
											$refs.timeMenuEnd.save(timeEnd)
										"
										format="24hr"
										scrollable
									></v-time-picker>
								</v-menu>
							</v-col>
							<!-- Systems -->
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.selectedSystems"
									:items="systems.edges"
									item-text="node.systemName"
									item-value="node.id"
									:loading="$apollo.queries.systems.loading"
									:rules="[rules.required]"
									v-bind:label="
										$t('maintenanceEditDialog.systemsLabel')
									"
									class="subtitle-2"
									outlined
									clearable
									multiple
								>
									<template v-slot:append-outer>
										<v-btn text small tabindex="-1">
											<v-icon
												color="grey"
												@click="addSystem"
												tabindex="-1"
												>add</v-icon
											>
										</v-btn>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn
						:disabled="isSaving"
						text
						rounded
						@click="closeMaintenanceDialog"
						>{{ $t("button.cancelBtn") }}</v-btn
					>
					<v-btn
						outlined
						rounded
						:loading="isSaving"
						color="primary"
						@click="saveMaintenance"
						>{{ $t("button.saveBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import SystemEditDialog from "@/components/status/SystemEditDialog.vue";
import moment from "moment";

export default {
	name: "maintenance-edit-dialog",
	props: ["dialog", "object"],
	components: { SystemEditDialog },

	apollo: {
		systems: {
			query: gql`
				query systems {
					systems {
						edges {
							node {
								id
								systemName
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.systems,
			skip: true,
			pollInterval: 60000, // ms
		},
	},

	data() {
		return {
			error: null,
			isSaving: false,
			systemDialog: false,

			systems: {},

			// date and time picker
			minDate: new Date().toISOString().slice(0, 10),
			dateRange: [],
			dateMenu: false,
			timeStart: null,
			timeMenuStart: false,
			timeEnd: null,
			timeMenuEnd: false,

			defaultItem: {
				id: null,
				maintenanceName: null,
				maintenanceDescription: null,
				start: null,
				end: null,
				selectedSystems: [],
			},
			editedItem: {
				id: null,
				maintenanceName: null,
				maintenanceDescription: null,
				start: null,
				end: null,
				selectedSystems: [],
			},
			rules: {
				required: (v) => !helper.isEmpty(v) || "This field is required",
				minLength: (len) => (v) =>
					(v || "").length >= len ||
					`Invalid character length, required ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len || "Too long",
			},
		};
	},
	computed: {
		formTitle() {
			return this.object
				? this.$t("maintenanceEditDialog.editTitle")
				: this.$t("maintenanceEditDialog.newTitle");
		},
		me() {
			return this.$store.state.user.me;
		},
	},
	watch: {
		// reset form when dialog open or close
		dialog(val) {
			// if open dialog, initialize selections
			if (val) {
				this.$apollo.queries.systems.skip = false;
			}

			this.resetForm();
		},
	},
	created() {
		this.fetchMe();
	},
	methods: {
		fetchMe() {
			this.$store
				.dispatch("user/fetchMe")
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		resetForm() {
			// reset form state
			this.error = null;
			this.isSaving = false;
			this.dateRange = [];
			this.panel = 0;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			// when editing, set form with object
			if (this.dialog && this.object) {
				this.minDate = null;
				var dateRangeStart = moment(this.object.start).format(
					"yyyy-MM-DD"
				);
				var dateRangeEnd = moment(this.object.end).format("yyyy-MM-DD");
				this.dateRange.push(dateRangeStart, dateRangeEnd);
				this.timeStart = moment(this.object.start).format("hh:mm");
				this.timeEnd = moment(this.object.end).format("hh:mm");
				this.editedItem.id = this.object.id;
				this.editedItem.maintenanceName = this.object.maintenanceName;
				this.editedItem.maintenanceDescription = this.object.maintenanceDescription;
				this.editedItem.start = this.object.start;
				this.editedItem.end = this.object.end;
				this.editedItem.selectedSystems = this.object
					.maintenanceSystemSet
					? this.object.maintenanceSystemSet.edges.map((item) => {
							return item.node.system.id;
					  })
					: [];
			}
			// when new or closing, set form with default
			else {
				this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
				this.minDate = new Date().toISOString().slice(0, 10);
				this.dateRange = [];
				this.dateMenu = false;
				this.timeStart = null;
				this.timeMenuStart = false;
				this.timeEnd = null;
				this.timeMenuEnd = false;
			}
		},

		closeMaintenanceDialog() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		saveMaintenance() {
			if (!this.$refs.form.validate()) {
				return;
			}

			// set form state
			this.error = null;
			this.isSaving = true;

			var orderedDateRange = this.dateRange.sort(function(a, b) {
				return Date.parse(a) > Date.parse(b);
			});
			var startDate = orderedDateRange[0];
			var endDate = orderedDateRange[1];
			var startTime = "00:00";
			if (this.timeStart !== null) {
				var startTime = this.timeStart;
			}
			var endTime = "23:59";
			if (this.timeEnd !== null) {
				var endTime = this.timeEnd;
			}

			var startDateTime = new Date(startDate + " " + startTime);
			var endDateTime = new Date(endDate + " " + endTime);

			// prepare api call payload
			var payload = {
				maintenanceName: this.editedItem.maintenanceName,
				maintenanceDescription: this.editedItem.maintenanceDescription,
				start: startDateTime,
				end: endDateTime,
				systems: this.editedItem.selectedSystems,
			};

			// if editing
			if (this.object) {
				// prepare graphql mutation payload
				payload.id = this.editedItem.id;

				// update
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation updateMaintenance(
								$input: UpdateMaintenanceInput!
							) {
								updateMaintenance(input: $input) {
									maintenance {
										id
										maintenanceName
										maintenanceDescription
										start
										end
										maintenanceSystemSet {
											edges {
												node {
													id
													system {
														id
														systemName
														systemDescription
													}
												}
											}
										}
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// update parent object
						this.$emit("item-created", response);
						this.closeMaintenanceDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Maintenance successfully edited`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
						this.isSaving = false;
					})
					.finally(() => {});
			}
			// new
			else {
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation createMaintenance(
								$input: CreateMaintenanceInput!
							) {
								createMaintenance(input: $input) {
									maintenance {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						this.$emit("item-created", response);
						this.closeMaintenanceDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Maintenance successfully added`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						console.log(error);

						this.error = error.graphQLErrors
							.map((error) => error.message)
							.join(", ");
						this.isSaving = false;
					})
					.finally(() => {});
			}
		},

		addSystem() {
			this.systemDialog = true;
		},

		systemAdded(item) {
			this.$apollo.queries.systems.refresh();
			this.editedItem.selectedSystems = [item.id];
		},
	},
};
</script>
