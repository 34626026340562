var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('ThemeEditDialog',{attrs:{"dialog":_vm.themeDialog},on:{"update:dialog":function($event){_vm.themeDialog=$event},"item-created":_vm.themeAdded}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.error)?_c('v-alert',{attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")]),_c('v-card-text',{staticClass:"pt-5"},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":200,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(200),
								],"label":_vm.$t('tenantEditDialog.tenantNameLabel'),"outlined":""},model:{value:(_vm.editedItem.tenantName),callback:function ($$v) {_vm.$set(_vm.editedItem, "tenantName", $$v)},expression:"editedItem.tenantName"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":50,"rules":[_vm.rules.maxLength(50)],"label":_vm.$t('tenantEditDialog.externalIdLabel'),"outlined":""},model:{value:(_vm.editedItem.externalId),callback:function ($$v) {_vm.$set(_vm.editedItem, "externalId", $$v)},expression:"editedItem.externalId"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"label":_vm.$t('tenantEditDialog.maxUsersLabel'),"outlined":"","type":"number"},model:{value:(_vm.editedItem.maxUsers),callback:function ($$v) {_vm.$set(_vm.editedItem, "maxUsers", $$v)},expression:"editedItem.maxUsers"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{staticClass:"subtitle-2",attrs:{"counter":8000,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(8000),
								],"label":_vm.$t('tenantEditDialog.allowedHostsLabel'),"outlined":"","hint":"[\"127.0.0.1\", \"192.168.2.3\"]","persistent-hint":""},model:{value:(_vm.editedItem.allowedHosts),callback:function ($$v) {_vm.$set(_vm.editedItem, "allowedHosts", $$v)},expression:"editedItem.allowedHosts"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"subtitle-2",attrs:{"items":_vm.groups.edges,"item-text":"node.name","item-value":"node.id","loading":_vm.$apollo.queries.groups.loading,"rules":[_vm.rules.required],"label":_vm.$t('tenantEditDialog.groupsLabel'),"outlined":"","clearable":"","multiple":""},model:{value:(_vm.editedItem.selectedGroups),callback:function ($$v) {_vm.$set(_vm.editedItem, "selectedGroups", $$v)},expression:"editedItem.selectedGroups"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"subtitle-2",attrs:{"items":_vm.themes.edges,"item-text":"node.themeName","item-value":"node.id","loading":_vm.$apollo.queries.themes.loading,"rules":[_vm.rules.required],"label":_vm.$t('tenantEditDialog.themeLabel'),"outlined":"","clearable":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"text":"","small":"","tabindex":"-1"}},[_c('v-icon',{attrs:{"color":"grey","tabindex":"-1"},on:{"click":_vm.addTheme}},[_vm._v("add")])],1)]},proxy:true}]),model:{value:(_vm.editedItem.themeNodeId),callback:function ($$v) {_vm.$set(_vm.editedItem, "themeNodeId", $$v)},expression:"editedItem.themeNodeId"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pb-5 pr-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isSaving,"text":"","rounded":""},on:{"click":_vm.closeTenantDialog}},[_vm._v(_vm._s(_vm.$t("button.cancelBtn")))]),_c('v-btn',{attrs:{"outlined":"","rounded":"","loading":_vm.isSaving,"color":"primary"},on:{"click":_vm.saveTenant}},[_vm._v(_vm._s(_vm.$t("button.saveBtn")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }