<template>
	<v-container fluid>
		<v-row class="align-center pl-3">
			<!-- Breadcrumbs -->
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
			<v-spacer />
		</v-row>

		<!-- Edit Profile dialog -->
		<v-dialog v-model="editProfileDialog" persistent max-width="600px">
			<v-card>
				<v-card-title class="primary white--text">
					{{ $t("settings.myProfile.profile.editProfileBtn") }}
				</v-card-title>

				<v-card-text class="pt-10 pb-0">
					<v-form ref="editProfileForm" class="mt-3">
						<v-row>
							<v-col cols="6">
								<v-text-field
									v-model="editedProfile.firstName"
									:rules="[
										rules.required,
										rules.maxLength(100),
									]"
									v-bind:label="
										$t(
											'settings.myProfile.profile.firstNameLabel'
										)
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedProfile.lastName"
									:rules="[
										rules.required,
										rules.maxLength(100),
									]"
									v-bind:label="
										$t(
											'settings.myProfile.profile.lastNameLabel'
										)
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedProfile.email"
									:rules="[
										rules.required,
										rules.maxLength(100),
									]"
									v-bind:label="
										$t(
											'settings.myProfile.profile.emailLabel'
										)
									"
									type="email"
									v-bind:hint="
										$t(
											'settings.myProfile.profile.emailHint'
										)
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn
						text
						rounded
						:disabled="isSavingUserProfile"
						@click="closeEditProfileDialog()"
						>{{ $t("button.cancelBtn") }}</v-btn
					>
					<v-btn
						outlined
						rounded
						:loading="isSavingUserProfile"
						color="primary"
						@click="saveUserProfile()"
						>{{ $t("button.saveBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Change Password dialog -->
		<v-dialog v-model="changePasswordDialog" persistent max-width="500px">
			<v-card>
				<v-card-title class="primary white--text">
					{{ $t("settings.myProfile.password.changePasswordBtn") }}
				</v-card-title>

				<v-card-text class="pt-10 pb-0">
					<v-form ref="changePasswordForm">
						<v-text-field
							id="oldPassword"
							v-bind:label="
								$t(
									'settings.myProfile.password.currentPasswordLabel'
								)
							"
							prepend-icon="lock"
							:type="showOldPassword ? 'text' : 'password'"
							:rules="[rules.required]"
							:error-messages="changePasswordError"
							outlined
							v-model="oldPassword"
							:append-icon="
								showOldPassword
									? 'visibility'
									: 'visibility_off'
							"
							@click:append="showOldPassword = !showOldPassword"
						/>
						<v-text-field
							id="newPassword"
							v-bind:label="
								$t(
									'settings.myProfile.password.newPasswordLabel'
								)
							"
							prepend-icon="lock_open"
							:type="showNewPassword ? 'text' : 'password'"
							:rules="[
								rules.required,
								rules.minLength(8),
								rules.maxLength(40),
							]"
							outlined
							v-model="newPassword"
							:append-icon="
								showNewPassword
									? 'visibility'
									: 'visibility_off'
							"
							@click:append="showNewPassword = !showNewPassword"
						/>
						<v-text-field
							id="newPassword"
							v-bind:label="
								$t(
									'settings.myProfile.password.confirmNewPasswordLabel'
								)
							"
							prepend-icon="lock_open"
							:type="showNewPassword ? 'text' : 'password'"
							:rules="[
								rules.required,
								rules.minLength(8),
								rules.maxLength(40),
							]"
							outlined
							v-model="newPasswordConfirm"
							:append-icon="
								showNewPassword
									? 'visibility'
									: 'visibility_off'
							"
							@click:append="showNewPassword = !showNewPassword"
							@keydown.enter="changePassword()"
						/>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5">
					<v-spacer></v-spacer>
					<v-btn
						:disabled="isChangingPassword"
						text
						rounded
						@click="closeChangePasswordDialog()"
						>{{ $t("button.cancelBtn") }}</v-btn
					>
					<v-btn
						outlined
						rounded
						:disabled="
							newPassword.length == 0 ||
								newPassword !== newPasswordConfirm
						"
						:loading="isChangingPassword"
						color="primary"
						@click="changePassword()"
						>{{ $t("button.saveBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Enable 2FA dialog -->
		<v-dialog v-model="enable2FADialog" persistent max-width="500px">
			<v-card>
				<v-card-title class="primary white--text justify-center">
					<v-icon color="success">verified_user</v-icon>
					{{ $t("settings.myProfile.2fa.2faDialog.title") }}
				</v-card-title>

				<v-card-text class="pt-5 pb-0">
					<p class="text-center">
						{{ $t("settings.myProfile.2fa.2faDialog.description") }}
					</p>

					<p class="subtitle-2 text-center mt-5">
						{{ $t("settings.myProfile.2fa.2faDialog.step1") }}
					</p>

					<v-row class="d-flex justify-space-around">
						<v-card class="pa-3 elevation-0">
							<qrcode-vue
								:value="tfaQrCodeValue"
								size="300"
								level="H"
							/>
						</v-card>
					</v-row>

					<p class="subtitle-2 text-center mt-5">
						{{ $t("settings.myProfile.2fa.2faDialog.step2") }}
					</p>

					<v-form ref="enable2FAForm" @submit.prevent>
						<v-row class="justify-space-around">
							<v-col class="col-6">
								<v-text-field
									v-model="tfaCode"
									counter="6"
									:rules="[
										rules.required,
										rules.maxLength(6),
									]"
									v-bind:label="
										$t(
											'settings.myProfile.2fa.2faDialog.2faLabel'
										)
									"
									type="number"
									outlined
									class="headline"
									:error-messages="tfaCodeError"
									v-bind:hint="
										$t(
											'settings.myProfile.2fa.2faDialog.2faHint'
										)
									"
									autocomplete="off"
									@keydown.enter="verify2FA()"
									autofocus
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5">
					<v-spacer></v-spacer>
					<v-btn
						outlined
						rounded
						:disabled="tfaCode.length != 6"
						:loading="isVerifying2FA"
						color="primary"
						@click="verify2FA()"
						>{{ $t("button.verifyBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Disable 2FA dialog -->
		<v-dialog v-model="disable2FADialog" persistent max-width="500px">
			<v-card>
				<v-card-title class="primary white--text">
					{{ $t("settings.myProfile.2fa.disable2faBtn") }}
				</v-card-title>

				<v-card-text class="pt-10 pb-0">
					<v-form ref="disable2FAForm" @submit.prevent>
						<v-text-field
							id="password"
							v-bind:label="
								$t('settings.myProfile.2fa.passwordLabel')
							"
							name="password"
							prepend-icon="lock"
							:type="showpassword ? 'text' : 'password'"
							:rules="[rules.required]"
							:error-messages="disable2FAError"
							outlined
							v-model="password"
							:append-icon="
								showpassword ? 'visibility' : 'visibility_off'
							"
							@click:append="showpassword = !showpassword"
							@keydown.enter="disable2FA"
						/>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn text rounded @click="closeDisable2FADialog()">{{
						$t("button.cancelBtn")
					}}</v-btn>
					<v-btn
						outlined
						rounded
						:disabled="password.length == 0"
						:loading="isDisabling2FA"
						color="primary"
						@click="disable2FA"
						>{{ $t("button.disableBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- main content -->
		<v-row class="ma-0">
			<v-col>
				<template>
					<v-card class="elevation-0">
						<v-tabs
							centered
							show-arrows
							:vertical="!$vuetify.breakpoint.xs"
						>
							<v-tab class="justify-start">
								<v-icon left>person</v-icon>
								{{ $t("settings.myProfile.title") }}
							</v-tab>

							<v-tab-item>
								<v-card flat>
									<v-card-text>
										<!-- Profile -->
										<v-subheader class="subtitle-2"
											><v-icon left>person</v-icon
											>{{
												$t(
													"settings.myProfile.profile.title"
												)
											}}</v-subheader
										>

										<v-card class="pa-3">
											<h3>
												<v-icon
													left
													color="grey lighten-2"
													>person_outline</v-icon
												>
												{{ me.firstName }}
												{{ me.lastName }}
											</h3>
											<p>
												<v-icon
													left
													color="grey lighten-2"
													>mail_outline</v-icon
												>
												{{ me.email }}
											</p>

											<p class="mb-0">
												<v-btn
													outlined
													rounded
													small
													color="primary"
													@click="
														openEditProfileDialog()
													"
													>{{
														$t(
															"settings.myProfile.profile.editProfileBtn"
														)
													}}</v-btn
												>
											</p>
										</v-card>

										<!-- Password -->
										<v-subheader class="subtitle-2 mt-2"
											><v-icon left>lock</v-icon
											>{{
												$t(
													"settings.myProfile.password.title"
												)
											}}</v-subheader
										>

										<v-card class="pa-3">
											<p class="mb-0">
												<v-btn
													outlined
													rounded
													small
													color="primary"
													@click="
														changePasswordDialog = true
													"
													>{{
														$t(
															"settings.myProfile.password.changePasswordBtn"
														)
													}}</v-btn
												>
											</p>
										</v-card>

										<!-- 2FA -->
										<v-subheader class="subtitle-2 mt-8"
											><v-icon left
												>perm_device_information</v-icon
											>
											{{
												$t(
													"settings.myProfile.2fa.title"
												)
											}}
										</v-subheader>

										<v-card class="pa-3">
											<v-row
												v-if="
													me.enabled2fa &&
														me.verified2fa
												"
											>
												<v-col>
													<v-icon color="success"
														>verified_user</v-icon
													>
													{{
														$t(
															"settings.myProfile.2fa.2faEnabledText"
														)
													}}
													<v-btn
														outlined
														rounded
														small
														color="error"
														class="ml-5"
														@click="
															disable2FADialog = true
														"
														>{{
															$t(
																"settings.myProfile.2fa.disable2faBtn"
															)
														}}</v-btn
													>
												</v-col>
											</v-row>

											<v-row
												v-else-if="
													me.enabled2fa &&
														!me.verified2fa
												"
											>
												<v-col>
													<v-icon color="warning"
														>highlight_off</v-icon
													>
													{{
														$t(
															"settings.myProfile.2fa.2faEnabledNotVerifiedText"
														)
													}}
													<v-btn
														outlined
														rounded
														small
														color="error"
														class="ml-5"
														@click="
															disable2FADialog = true
														"
														>{{
															$t(
																"settings.myProfile.2fa.disable2faBtn"
															)
														}}</v-btn
													>
												</v-col>
											</v-row>

											<v-row v-else>
												<v-col>
													<v-btn
														outlined
														rounded
														small
														color="primary"
														:v-loading="
															isEnabling2FA
														"
														@click="enable2FA()"
														>{{
															$t(
																"settings.myProfile.2fa.enable2faBtn"
															)
														}}</v-btn
													>
												</v-col>
											</v-row>
										</v-card>
									</v-card-text>
								</v-card>
							</v-tab-item>
							<!-- <v-tab-item>
                                <v-card flat>
                                    <v-card-text>
                                        <p>TODO: Organisation info</p>

                                        <p class="mb-0">TODO: Edit form</p>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item> -->
						</v-tabs>
					</v-card>
				</template>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Config from "@/utils/config.json";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import QrcodeVue from "qrcode.vue";

export default {
	name: "settings-page",
	components: {
		QrcodeVue,
	},

	data: function() {
		return {
			editProfileDialog: false,
			isSavingUserProfile: false,

			editedProfile: {
				email: null,
				firstName: null,
				lastName: null,
			},

			changePasswordDialog: false,
			isChangingPassword: false,
			oldPassword: "",
			newPassword: "",
			newPasswordConfirm: "",
			showOldPassword: "",
			showNewPassword: "",
			changePasswordError: "",

			enable2FADialog: false,
			disable2FADialog: false,
			isEnabling2FA: false,
			isVerifying2FA: false,
			isDisabling2FA: false,
			secretKey2FA: "",
			tfaCode: "",
			tfaCodeError: "",
			tfaCodeIsVerified: false,
			password: "",
			showpassword: false,
			disable2FAError: "",

			rules: {
				required: (v) => !helper.isEmpty(v) || "This field is required",
				listRequired: (v) =>
					(v || "").length >= 1 || `This field is required`,
				minLength: (len) => (v) =>
					(v || "").length >= len || `Require at least ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len || "Too long",
			},
		};
	},
	computed: {
		me() {
			return this.$store.state.user.me || {};
		},

		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("settings.breadcrumbs.home"),
					disable: false,
					href: "/#/",
				},
				{
					text: this.$t("settings.breadcrumbs.settings"),
					disabled: true,
				},
			];
			return breadcrumbs;
		},

		tfaQrCodeValue() {
			const applicationName = Config.APPLICATION_NAME;

			if (!this.me) {
				return "";
			}

			return (
				"otpauth://totp/Insurance%20Data%3A" +
				encodeURIComponent(this.me.email) +
				"?secret=" +
				this.secretKey2FA +
				"&issuer=Insurance%20Data&algorithm=SHA1&digits=6&period=30"
			);
		},
	},
	watch: {
		oldPassword() {
			this.changePasswordError = "";
		},
	},
	created() {
		this.hasPermission = helper.hasPermission;
	},
	mounted() {
		this.fetchMe();
	},
	methods: {
		fetchMe() {
			this.$store
				.dispatch("user/fetchMe")
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		openEditProfileDialog() {
			this.editProfileDialog = true;
			this.resetEiteProfileForm();
		},

		resetEiteProfileForm() {
			// reset form state
			this.isSavingUserProfile = false;

			this.panel = 0;
			if (this.$refs.editProfileForm) {
				this.$refs.editProfileForm.resetValidation();
			}

			// when editing, set form with object
			if (this.editProfileDialog && this.me) {
				this.editedProfile.email = this.me.email;
				this.editedProfile.firstName = this.me.firstName;
				this.editedProfile.lastName = this.me.lastName;
			}
			// when new or closing, set form with default
			else {
				this.editedProfile = {
					email: null,
					firstName: null,
					lastName: null,
				};
			}
		},

		saveUserProfile() {
			if (!this.$refs.editProfileForm.validate()) {
				return;
			}

			this.isSavingUserProfile = true;

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation updateMyProfile(
							$input: UpdateMyProfileInput!
						) {
							updateMyProfile(input: $input) {
								user {
									id
									firstName
									lastName
									email
									enabled2fa
									require2fa
								}
							}
						}
					`,
					// Parameters
					variables: {
						input: {
							email: this.editedProfile.email,
							firstName: this.editedProfile.firstName,
							lastName: this.editedProfile.lastName,
						},
					},
				})
				.then(() => {
					this.resetEiteProfileForm();
					this.editProfileDialog = false;
					this.fetchMe();

					// show snackbar
					const payload = {
						color: "success",
						message: "Your profile is update!",
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					// Error
					console.error(error);

					// show snackbar
					const payload = {
						color: "error",
						message: error.message,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.finally(() => {
					this.isSavingUserProfile = false;
				});
		},

		closeEditProfileDialog() {
			this.resetEiteProfileForm();
			this.editProfileDialog = false;
		},

		changePassword() {
			if (!this.$refs.changePasswordForm.validate()) {
				return;
			}

			this.isChangingPassword = true;

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation changeMyPassword(
							$input: ChangeMyPasswordInput!
						) {
							changeMyPassword(input: $input) {
								user {
									id
									firstName
									lastName
									email
									enabled2fa
									require2fa
								}
							}
						}
					`,
					// Parameters
					variables: {
						input: {
							oldPassword: this.oldPassword,
							newPassword: this.newPassword,
						},
					},
				})
				.then(() => {
					this.closeChangePasswordDialog();

					// show snackbar
					const payload = {
						color: "success",
						message: "Password is changed!",
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					// Error
					console.error(error);
					this.changePasswordError = error.message;
				})
				.finally(() => {
					this.isChangingPassword = false;
					this.fetchMe();
				});
		},

		closeChangePasswordDialog() {
			this.oldPassword = "";
			this.newPassword = "";
			this.newPasswordConfirm = "";
			this.changePasswordError = "";
			this.showOldPassword = false;
			this.showNewPassword = false;
			this.$refs.changePasswordForm.resetValidation();
			this.changePasswordDialog = false;
		},

		enable2FA() {
			this.isEnabling2FA = true;

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation enable2fa {
							enable2fa(input: {}) {
								secretKey
							}
						}
					`,
					// Parameters
					variables: {},
				})
				.then((response) => {
					// // Result
					this.secretKey2FA = response.data.enable2fa.secretKey;
					this.enable2FADialog = true;
					this.fetchMe();
				})
				.catch((error) => {
					// Error
					console.error(error);
				})
				.finally(() => {
					this.isEnabling2FA = false;
				});
		},

		verify2FA() {
			if (!this.$refs.enable2FAForm.validate()) {
				return;
			}

			this.isVerifying2FA = true;

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation verify2fa($input: Verify2FAInput!) {
							verify2fa(input: $input) {
								success
							}
						}
					`,
					// Parameters
					variables: {
						input: {
							tfaCode: this.tfaCode,
						},
					},
				})
				.then((response) => {
					// // Result
					// TODO: show success for 2 seconds, then close dialog
					const success = response.data.verify2fa.success;

					// this.tfaCodeError = JSON.stringify( response.data.verify2fa.success)
					if (success) {
						this.tfaCodeIsVerified = true;
						// this.tfaCodeError = "";

						// show snackbar
						const payload = {
							color: "success",
							message: "2FA is verified!",
						};
						this.$store.dispatch("snackbar/showMessage", payload);

						this.closeEnable2FADialog();
						this.fetchMe();
					} else {
						// this.tfaCodeError = "Invalide Ga-code";

						// show snackbar
						const payload = {
							color: "error",
							message: "2FA-Code is invalid!",
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					}
				})
				.catch((error) => {
					// Error
					console.error(error);
				})
				.finally(() => {
					this.isVerifying2FA = false;

					// setTimeout(() => {
					//     //your code to be executed after 1 second
					//     this.isLoadingPlannedTaskPhases = false;
					// }, 1000);
				});
		},

		closeEnable2FADialog() {
			this.tfaCode = "";
			this.$refs.enable2FAForm.resetValidation();
			this.enable2FADialog = false;
		},

		disable2FA() {
			if (!this.$refs.disable2FAForm.validate()) {
				return;
			}

			this.isEnabling2FA = true;

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation disable2fa($input: Disable2FAInput!) {
							disable2fa(input: $input) {
								success
							}
						}
					`,
					// Parameters
					variables: {
						input: {
							password: this.password,
						},
					},
				})
				.then(() => {
					this.closeDisable2FADialog();

					// show snackbar
					const payload = {
						color: "success",
						message: "2FA is disabled!",
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					// Error
					console.error(error);
					this.disable2FAError = error.message;
				})
				.finally(() => {
					this.isDisabling2FA = false;
					this.fetchMe();
				});
		},

		closeDisable2FADialog() {
			this.password = "";
			this.showpassword = false;
			this.$refs.disable2FAForm.resetValidation();
			this.disable2FADialog = false;
		},
	},
};
</script>
