const state = {
	snackbars: [],
};

const mutations = {
	setMessage(state, snackbar) {
		state.snackbars = state.snackbars.concat(snackbar);
		//console.log(state.snackbars);
	},

	filterPermanentMessage(state, snackbar) {
		state.snackbars = state.snackbars.filter(
			(obj) => obj.isPermanent !== true
		);
		//console.log(state.snackbars);
	},
};

const actions = {
	showMessage(context, snackbar) {
		snackbar.showing = true;
		snackbar.timeout = snackbar.timeout || 2000;
		snackbar.isPermanent = snackbar.isPermanent || false;
		//console.log(snackbar);
		context.commit("setMessage", snackbar);
	},

	deletePermanentMessage(context, snackbar) {
		context.commit("filterPermanentMessage", snackbar);
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
