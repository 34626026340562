<template>
	<v-container fluid>
		<v-row class="align-center pl-3">
			<!-- Breadcrumbs -->
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
			<v-spacer />
			<v-btn-toggle
				v-model="toggleRefreshStatus"
				class="ml-5 mr-6"
				dense
				rounded
				mandatory
			>
				<v-btn small value="ALL">{{
					$t("reports.toggleRefreshStatus.all")
				}}</v-btn>
				<v-btn
					v-for="reportGroup in reportGroups.edges"
					:key="reportGroup.node.id"
					:value="reportGroup.node.reportGroupName"
					small
				>
					{{ reportGroup.node.reportGroupName }}
				</v-btn>
			</v-btn-toggle>
		</v-row>
		
		<!-- Delete Report Dialog -->
		<DeleteDialog
			:dialog.sync="deleteDialog"
			v-on:confirmed="deletingSelectedReportsConfirmed"
		>
			<template v-slot:title>{{ $t("deleteDialog.title") }}</template>
			<template v-slot:content>{{ $t("deleteDialog.content") }}</template>
		</DeleteDialog>

		<!-- Report Dialog-->
		<ReportEditDialog
			:object.sync="editedItem"
			:dialog.sync="reportDialog"
			v-on:item-created="onReportCreated"
		></ReportEditDialog>

		<!-- Report Dialog-->
		<ReportAddDialog
			:object.sync="editedItem"
			:dialog.sync="reportDialogAPI"
			v-on:item-created="onReportCreated"
		></ReportAddDialog>

		<v-row>
			<v-col cols="12" class="pt-0">
				<v-card
					:class="{
						'mx-0 elevation-5': $vuetify.breakpoint.xsOnly,
						'mx-3 elevation-5': $vuetify.breakpoint.smAndUp,
					}"
				>
					<!-- Header -->
					<v-card-title class="pb-0">
						<v-icon color="primary" class="mx-2">bar_chart</v-icon
						>{{ $t("reports.title") }}
						<v-btn
							v-if="hasPermission('dashboards.view_usermapping')"
							icon
							fab
							small
							class="mr-5"
							@click="goToReportsSettings()"
						>
							<v-icon>settings</v-icon>
						</v-btn>
						<v-spacer></v-spacer>
						<v-col cols="12" sm="4">
							<v-text-field
								dense
								:value="searchReports"
								@input="handleSearch"
								append-outer-icon="refresh"
								@click:append-outer="refreshCurrentPage()"
								v-bind:label="$t('datatable.searchLabel')"
								clearable
								hide-details
								@keydown.enter="refreshCurrentPage()"
								@click:clear="onClearSearch()"
							></v-text-field>
						</v-col>
						<v-spacer></v-spacer>
						<v-btn
							v-if="me.isSuperuser"
							class="mr-2"
							color="error"
							dark
							outlined
							rounded
							small
							:disabled="selectedReports.length <= 0"
							@click="deletingSelectedReports()"
							>{{ $t("reports.deleteBtn") }}</v-btn
						>
						<v-btn
							v-if="me.isSuperuser"
							color="primary"
							dark
							outlined
							rounded
							small
							@click="addReportAPI()"
							>{{ $t("reports.newBtn") }}</v-btn
						>
					</v-card-title>

					<v-card-text>
						<v-container fluid>
							<v-data-table
								:headers="
									me.isSuperuser ? headersSuperuser : headers
								"
								:loading="$apollo.queries.reports.loading"
								:items="reports.edges"
								:options.sync="dataTableOptions"
								:server-items-length="reports.totalCount"
								:items-per-page="defaultItemsPerPage"
								:footer-props="{
									'items-per-page-options': [
										10,
										25,
										50,
										75,
										100,
									],
									'disable-pagination':
										$apollo.queries.reports.loading,
								}"
								v-model="selectedReports"
								item-key="node.id"
								:show-select="
									me.isSuperuser ? 'show-select' : undefined
								"
							>
								<template
									v-slot:[`item.node.reportType`]="{
										item,
									}"
								>
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-icon
												v-on="on"
												v-if="
													item.node.reportType ===
														'POWERBIREPORT'
												"
												color="primary"
												>dashboard</v-icon
											>
											<v-icon
												v-on="on"
												v-else-if="
													item.node.reportType ===
														'PAGINATEDREPORT'
												"
												color="primary"
												>layers</v-icon
											>
										</template>
										<span
											v-if="
												item.node.reportType ===
													'POWERBIREPORT'
											"
										>
											{{ "Dashboard" }}
										</span>
										<span
											v-else-if="
												item.node.reportType ===
													'PAGINATEDREPORT'
											"
										>
											{{ "Paginated Report" }}
										</span>
									</v-tooltip>
								</template>
								<template
									v-slot:[`item.node.reportName`]="{
										item,
									}"
								>
									<!-- <v-btn text>{{ item.node.reportName }}</v-btn> -->
									<span
										class="row-pointer "
										@click="
											goToReportDetail(
												item.node.id,
												item.node.reportType
											)
										"
									>
										{{ item.node.reportName }}
									</span>
								</template>
								<template
									v-slot:[`item.node.lastRefreshStatus`]="{
										item,
									}"
								>
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-icon
												v-on="on"
												v-if="
													item.node
														.lastRefreshStatus ===
														'Completed'
												"
												color="success"
												>check_circle</v-icon
											>
											<v-icon
												v-on="on"
												v-else-if="
													item.node
														.lastRefreshStatus ===
														'Failed'
												"
												color="error"
												>cancel</v-icon
											>
											<v-icon
												v-on="on"
												v-else-if="
													item.node
														.lastRefreshStatus ===
														'Disabled'
												"
												color="error"
												>cancel</v-icon
											>
											<v-icon
												v-on="on"
												v-else-if="
													item.node
														.lastRefreshStatus ===
														'Unknown'
												"
												color="warning"
												>error</v-icon
											>
										</template>
										<span
											v-if="
												item.node.lastRefreshStatus ===
													'Unknown'
											"
										>
											Refreshing
										</span>
										<span v-else>
											{{ item.node.lastRefreshStatus }}
										</span>
									</v-tooltip>
								</template>
								<template
									v-slot:[`item.node.lastRefreshEndTime`]="{
										item,
									}"
								>
									<span v-if="item.node.lastRefreshEndTime">{{
										item.node.lastRefreshEndTime
											| moment("YYYY-MM-DD HH:mm")
									}}</span>
									<span v-else> </span>
								</template>
								<template
									v-slot:[`item.node.refreshScheduleIsEnabled`]="{
										item,
									}"
								>
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-icon
												v-on="on"
												v-if="item.node.isDirectQuery"
												color="info"
												>swap_horizontal_circle</v-icon
											>
											<v-icon
												v-on="on"
												v-else-if="
													item.node
														.refreshScheduleIsEnabled
												"
												color="success"
												>check_circle</v-icon
											>
											<v-icon
												v-on="on"
												v-else
												color="error"
												>cancel</v-icon
											>
										</template>
										<span v-if="item.node.isDirectQuery">
											Direct Query
										</span>
										<span
											v-else-if="
												item.node
													.refreshScheduleIsEnabled
											"
										>
											Enabled
										</span>
										<span v-else>
											Disabled
										</span>
									</v-tooltip>
								</template>
								<template
									v-slot:[`item.node.refreshScheduleTimes`]="{
										item,
									}"
								>
									<span v-if="item.node.isDirectQuery"></span>
									<span v-else>{{
										item.node.refreshScheduleTimes
									}}</span>
								</template>
								<template v-slot:[`item.action`]="{ item }">
									<template
										v-if="
											item.node.favoriteSet.edges.length >
												0
										"
									>
										<template
											v-for="favorite in item.node
												.favoriteSet.edges"
										>
											<v-btn
												:key="favorite.node.id"
												v-if="favorite.node.isFavorite"
												icon
												color="primary"
												@click="
													deleteReportFavorite(
														favorite.node.id
													)
												"
												:loading="
													$apollo.queries.reports
														.loading
												"
											>
												<v-icon>star</v-icon>
											</v-btn>
										</template>
									</template>
									<template v-else>
										<v-btn
											icon
											color="primary"
											@click="
												createReportFavorite(
													item.node.id
												)
											"
											:loading="
												$apollo.queries.reports.loading
											"
										>
											<v-icon>star_border</v-icon>
										</v-btn>
									</template>
									<v-btn
										icon
										color="primary"
										@click="
											goToReportDetail(
												item.node.id,
												item.node.reportType
											)
										"
									>
										<v-icon>remove_red_eye</v-icon>
									</v-btn>
									<v-btn
										v-if="me.isSuperuser"
										icon
										color="primary"
										@click="editReport(item.node)"
									>
										<v-icon>edit</v-icon>
									</v-btn>
								</template>
							</v-data-table>
						</v-container>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped>
.row-pointer {
	cursor: pointer;
}
</style>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import moment from "moment";
import ReportEditDialog from "@/components/reports/ReportEditDialog.vue";
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import ReportAddDialog from "@/components/reports/ReportAddDialog.vue";

export default {
	name: "report-list-page",
	components: {
		ReportEditDialog,
		DeleteDialog,
		ReportAddDialog,
	},

	apollo: {
		reports: {
			query: gql`
				query reports(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$search: String
					$orderBy: String
					$allReports: Boolean!
					$reportGroup_ReportGroupName_In: String
					$usermappingSet_User_Id_Iexact: ID
				) {
					reports(
						first: $first
						last: $last
						before: $before
						after: $after
						search: $search
						orderBy: $orderBy
						allReports: $allReports
						reportGroup_ReportGroupName_In: $reportGroup_ReportGroupName_In
						usermappingSet_User_Id_Iexact: $usermappingSet_User_Id_Iexact
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								id
								reportName
								groupId
								reportId
								datasetId
								isBinded
								bindedGroupId
								bindedDatasetId
								pbiDateTable
								pbiDateColumn
								isEditable
								lastRefreshType
								lastRefreshStartTime
								lastRefreshEndTime
								lastRefreshStatus
								refreshScheduleTimes
								refreshScheduleIsEnabled
								isActive
								isDirectQuery
								reportType
								reportGroup {
									id
									reportGroupName
								}
								tenant {
									id
									tenantName
								}
								favoriteSet {
									edges {
										node {
											id
											isFavorite
										}
									}
								}
							}
						}
					}
				}
			`,
			variables() {
				return {
					allReports: false,
					usermappingSet_User_Id_Iexact: this.me.id,
				};
			},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => {
				return data.reports;
			},
			skip() {
				return !this.me.id;
			},
			pollInterval: 60000, // ms
		},

		reportGroups: {
			query: gql`
				query reportGroups(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$search: String
					$orderBy: String
				) {
					reportGroups(
						first: $first
						last: $last
						before: $before
						after: $after
						search: $search
						orderBy: $orderBy
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								id
								reportGroupName
							}
						}
					}
				}
			`,
			variables() {
				return {};
			},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => {
				return data.reportGroups;
			},
			pollInterval: 60000, // ms
		},
	},

	data: function() {
		return {
			reportDialog: false,
			deleteDialog: false,
			reportDialogAPI: false,

			defaultItemsPerPage: 25,
			dataTableOptions: {},
			page: 1,
			selectedReports: [],

			reports: {},
			reportGroups: {},

			toggleRefreshStatus: "ALL",

			editedItem: {},
			defaultItem: {
				id: null,
				reportName: null,
				groupId: null,
				reportId: null,
				datasetId: null,
				pbiDateTable: null,
				pbiDateColumn: null,
				isEditable: null,
			},
			deletedReport: {},

			headers: [
				{
					text: "Report Type",
					value: "node.reportType",
					sortable: false,
				},
				{
					text: this.$t("reports.datatableHeaders.tenant"),
					value: "node.tenant.tenantName",
					sortable: true,
				},
				{
					text: this.$t("reports.datatableHeaders.report"),
					value: "node.reportName",
					sortable: true,
				},
				{
					text: this.$t("reports.datatableHeaders.reportGroup"),
					value: "node.reportGroup.reportGroupName",
					sortable: true,
				},
				{
					text: this.$t("reports.datatableHeaders.lastRefreshStatus"),
					value: "node.lastRefreshStatus",
					sortable: true,
				},
				{
					text: this.$t("reports.datatableHeaders.lastRefresh"),
					value: "node.lastRefreshEndTime",
					sortable: true,
				},
				{
					text: this.$t(
						"reports.datatableHeaders.refreshScheduleStatus"
					),
					value: "node.refreshScheduleIsEnabled",
					sortable: true,
				},
				{
					text: this.$t("reports.datatableHeaders.refreshSchedule"),
					value: "node.refreshScheduleTimes",
					sortable: true,
				},
				{
					text: this.$t("datatable.actionHeader"),
					align: "center",
					value: "action",
					sortable: false,
				},
			],

			headersSuperuser: [
				{
					text: "Report Type",
					value: "node.reportType",
					sortable: false,
				},
				{
					text: this.$t("reports.datatableHeaders.tenant"),
					value: "node.tenant.tenantName",
					sortable: true,
				},
				{
					text: this.$t("reports.datatableHeaders.report"),
					value: "node.reportName",
					sortable: true,
				},
				{
					text: this.$t("reports.datatableHeaders.reportGroup"),
					value: "node.reportGroup.reportGroupName",
					sortable: true,
				},
				{
					text: this.$t("reports.datatableHeaders.lastRefreshStatus"),
					value: "node.lastRefreshStatus",
					sortable: false,
				},
				{
					text: this.$t("reports.datatableHeaders.lastRefresh"),
					value: "node.lastRefreshEndTime",
					sortable: true,
				},
				{
					text: this.$t(
						"reports.datatableHeaders.refreshScheduleStatus"
					),
					value: "node.refreshScheduleIsEnabled",
					sortable: true,
				},
				{
					text: this.$t("reports.datatableHeaders.refreshSchedule"),
					value: "node.refreshScheduleTimes",
					sortable: true,
				},
				{
					text: this.$t("datatable.actionHeader"),
					align: "center",
					value: "action",
					sortable: false,
				},
			],

			editedIndex: -1,
		};
	},

	computed: {
		me() {
			return this.$store.state.user.me;
		},

		searchReports() {
			return this.$store.state.reports.searchReports;
		},

		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("reports.breadcrumbs.home"),
					disable: false,
					href: "/#/",
				},
				{
					text: this.$t("reports.breadcrumbs.reports"),
					disabled: true,
				},
			];
			return breadcrumbs;
		},
	},

	watch: {
		dataTableOptions: {
			handler() {
				this.refreshCurrentPage();
			},
			deep: true,
		},

		toggleRefreshStatus(value) {
			if (value != null) {
				this.refreshCurrentPage();
			}
		},
	},

	created() {
		this.hasPermission = helper.hasPermission;

		this.fetchMe();
	},

	mounted() {},

	methods: {
		fetchMe() {
			this.$store
				.dispatch("user/fetchMe")
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		handleSearch(search) {
			this.$store
				.dispatch("reports/searchReports", search)
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		onClearSearch() {
			this.handleSearch("");
			this.refreshCurrentPage();
		},

		refreshCurrentPage() {
			const {
				sortBy,
				sortDesc,
				page,
				itemsPerPage,
			} = this.dataTableOptions;

			var _first = itemsPerPage;
			var _last = null;
			var _before = null;
			var _after = null;
			var _orderBy = null;

			if (page < this.page) {
				_first = null;
				_last = itemsPerPage;
				_before = this.reports.pageInfo.startCursor;
				_after = null;
			}

			if (page > this.page) {
				_first = itemsPerPage;
				_last = null;
				_before = null;
				_after = this.reports.pageInfo.endCursor;
			}

			switch (sortBy[0]) {
				case "node.tenant.tenantName":
					_orderBy = "tenantName";
					break;
				case "node.reportName":
					_orderBy = "reportName";
					break;
				case "node.reportGroup.reportGroupName":
					_orderBy = "reportGroupName";
					break;
				case "node.lastRefreshStatus":
					_orderBy = "lastRefreshStatus";
					break;
				case "node.lastRefreshEndTime":
					_orderBy = "lastRefreshEndTime";
					break;
				case "node.refreshScheduleIsEnabled":
					_orderBy = "refreshScheduleIsEnabled";
					break;
				case "node.refreshScheduleTimes":
					_orderBy = "refreshScheduleTimes";
					break;
				default:
					_orderBy = "reportName";
					break;
			}

			if (sortDesc[0] === true) {
				_orderBy = "-" + _orderBy;
			}

			var userId = "";
			if (this.me.isSuperuser) {
				userId = "";
			} else {
				userId = this.me.id;
			}

			this.$apollo.queries.reports.setVariables({
				first: _first,
				last: _last,
				before: _before,
				after: _after,
				orderBy: _orderBy,
				search: this.searchReports,
				allReports: false,
				reportGroup_ReportGroupName_In:
					this.toggleRefreshStatus === "ALL"
						? ""
						: this.toggleRefreshStatus,

				usermappingSet_User_Id_Iexact: userId,
			});

			// set skip false to execute the query
			this.$apollo.queries.reports.skip = false;
			this.$apollo.queries.reports.refresh();

			// update this.page
			this.page = page;
		},

		goToReportsSettings() {
			this.$router.push({
				name: "reports-settings",
			});
		},

		goToReportDetail(reportNodeId, reportType) {
			if (reportType === "POWERBIREPORT") {
				this.$router.push({
					name: "report-detail",
					params: {
						reportNodeId: reportNodeId,
					},
				});
			} else if (reportType === "PAGINATEDREPORT") {
				this.$router.push({
					name: "paginated-report-detail",
					params: {
						reportNodeId: reportNodeId,
					},
				});
			}
		},

		onReportCreated() {
			this.reportDialog = false;
			this.$apollo.queries.reports.refresh();
		},

		addReport() {
			this.editedItem = null;
			this.reportDialog = true;
		},

		addReportAPI() {
			this.editedItem = null;
			this.reportDialogAPI = true;
		},

		editReport(report) {
			this.editedItem = JSON.parse(JSON.stringify(report));
			this.reportDialog = true;
		},

		deletingSelectedReports() {
			this.deleteDialog = true;
		},

		deletingSelectedReportsConfirmed() {
			var selectedReports = this.selectedReports;
			function getReportIds(selectedReports) {
				var reportIds = [];
				for (var i = 0; i < selectedReports.length; i++) {
					reportIds.push(selectedReports[i].node.id);
				}
				return reportIds;
			}
			var reportIds = getReportIds(selectedReports);

			const payload = {
				ids: reportIds,
			};

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation deleteReports($input: DeleteReportsInput!) {
							deleteReports(input: $input) {
								report {
									id
									reportName
								}
							}
						}
					`,
					// Parameters
					variables: {
						input: payload,
					},
				})
				.then(() => {
					// refresh plants
					this.$apollo.queries.reports.refresh();

					// close delete dialog
					this.deleteDialog = false;

					// show snackbar
					const payload = {
						color: "success",
						message: `Reports successfully deleted`,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					this.error = error.graphQLErrors[0].message;
				});
			// update state of selectedReports
			this.selectedReports = [];
		},

		createReportFavorite(reportNodeId) {
			// prepare api call payload
			var payload = {
				reportNodeId: reportNodeId,
				isFavorite: true,
			};

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation createReportFavorite(
							$input: CreateReportFavoriteInput!
						) {
							createReportFavorite(input: $input) {
								reportFavorite {
									id
								}
							}
						}
					`,
					// Parameters
					variables: {
						input: payload,
					},
				})
				.then(() => {
					// refresh plants
					this.$apollo.queries.reports.refresh();
				})
				.catch((error) => {
					this.error = error.graphQLErrors[0].message;
				});
		},

		deleteReportFavorite(reportFavoriteId) {
			// prepare api call payload
			var payload = {
				id: reportFavoriteId,
			};

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation deleteReportFavorite(
							$input: DeleteReportFavoriteInput!
						) {
							deleteReportFavorite(input: $input) {
								reportFavorite {
									id
								}
							}
						}
					`,
					// Parameters
					variables: {
						input: payload,
					},
				})
				.then(() => {
					// refresh plants
					this.$apollo.queries.reports.refresh();
				})
				.catch((error) => {
					this.error = error.graphQLErrors[0].message;
				});
		},
	},
};
</script>
