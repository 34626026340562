var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center pl-3"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":"/"}})],1),_c('DeleteDialog',{attrs:{"dialog":_vm.deleteDialog,"item":_vm.deletedItem},on:{"update:dialog":function($event){_vm.deleteDialog=$event},"confirmed":_vm.deletingConfirmed},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("deleteDialog.title")))]},proxy:true},{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.$t("deleteDialog.content")))]},proxy:true}])}),_c('UsermappingEditDialog',{attrs:{"object":_vm.editedUsermapping,"dialog":_vm.usermappingDialog},on:{"update:object":function($event){_vm.editedUsermapping=$event},"update:dialog":function($event){_vm.usermappingDialog=$event},"item-created":_vm.onUsermappingCreated}}),_c('UsermappingAddDialog',{attrs:{"object":_vm.editedUsermapping,"dialog":_vm.usermappingAddDialog},on:{"update:object":function($event){_vm.editedUsermapping=$event},"update:dialog":function($event){_vm.usermappingAddDialog=$event},"item-created":_vm.onUsermappingCreated}}),_c('ReportGroupEditDialog',{attrs:{"object":_vm.editedReportGroup,"dialog":_vm.reportGroupDialog},on:{"update:object":function($event){_vm.editedReportGroup=$event},"update:dialog":function($event){_vm.reportGroupDialog=$event},"item-created":_vm.onReportGroupCreated}}),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{class:{
					'mx-0 elevation-5': _vm.$vuetify.breakpoint.xsOnly,
					'mx-3 elevation-5': _vm.$vuetify.breakpoint.smAndUp,
				}},[_c('v-tabs',{attrs:{"centered":"","show-arrows":""}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v("collections_bookmark")]),_vm._v(_vm._s(_vm.$t("reportsSettings.usermappings.title"))+" ")],1),(_vm.me.isSuperuser)?_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v("tab")]),_vm._v(_vm._s(_vm.$t("reportsSettings.reportGroups.title")))],1):_vm._e(),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pb-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}}),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.searchUsermappings,"append-outer-icon":"refresh","label":_vm.$t('datatable.searchLabel'),"clearable":"","hide-details":""},on:{"input":_vm.handleSearchUsermappings,"click:append-outer":function($event){return _vm.refreshUsermappingsCurrentPage()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.refreshUsermappingsCurrentPage()},"click:clear":function($event){return _vm.onClearSearchUsermappings()}}})],1),_c('v-spacer'),(
										_vm.hasPermission(
											'dashboards.delete_usermapping'
										)
									)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","dark":"","outlined":"","rounded":"","small":"","disabled":_vm.selectedUsermappings.length <= 0},on:{"click":function($event){return _vm.deletingSelectedUsermappings()}}},[_vm._v(_vm._s(_vm.$t( "reportsSettings.usermappings.deleteBtn" )))]):_vm._e(),(
										_vm.hasPermission(
											'dashboards.add_usermapping'
										)
									)?_c('v-btn',{attrs:{"color":"primary","dark":"","outlined":"","rounded":"","small":""},on:{"click":function($event){return _vm.addUsermapping()}}},[_vm._v(_vm._s(_vm.$t( "reportsSettings.usermappings.newBtn" )))]):_vm._e()],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.me.isSuperuser
											? _vm.usermappingHeadersSuperuser
											: _vm.usermappingHeaders,"loading":_vm.$apollo.queries.usermappings.loading,"items":_vm.usermappings.edges,"options":_vm.usermappingsDataTableOptions,"server-items-length":_vm.usermappings.totalCount,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{
										'items-per-page-options': [
											10,
											25,
											50,
											75,
											100,
										],
										'disable-pagination':
											_vm.$apollo.queries.usermappings
												.loading,
									},"item-key":"node.id","show-select":_vm.hasPermission(
											'dashboards.delete_usermapping'
										)
											? 'show-select'
											: undefined},on:{"update:options":function($event){_vm.usermappingsDataTableOptions=$event}},scopedSlots:_vm._u([{key:`item.action`,fn:function({ item }){return [(
												_vm.hasPermission(
													'dashboards.change_usermapping'
												)
											)?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.editUsermapping(item.node)}}},[_c('v-icon',[_vm._v("edit")])],1):_vm._e()]}}],null,true),model:{value:(_vm.selectedUsermappings),callback:function ($$v) {_vm.selectedUsermappings=$$v},expression:"selectedUsermappings"}})],1)],1)],1),(_vm.me.isSuperuser)?_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pb-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}}),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.searchReportGroups,"append-outer-icon":"refresh","label":_vm.$t('datatable.searchLabel'),"clearable":"","hide-details":""},on:{"input":_vm.handleSearchReportGroups,"click:append-outer":function($event){return _vm.refreshReportGroupsCurrentPage()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.refreshReportGroupsCurrentPage()},"click:clear":function($event){return _vm.onClearSearchReportGroups()}}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","dark":"","outlined":"","rounded":"","small":"","disabled":_vm.selectedReportGroups.length <= 0},on:{"click":function($event){return _vm.deletingSelectedReportGroups()}}},[_vm._v(_vm._s(_vm.$t( "reportsSettings.reportGroups.deleteBtn" )))]),_c('v-btn',{attrs:{"color":"primary","dark":"","outlined":"","rounded":"","small":""},on:{"click":function($event){return _vm.addReportGroup()}}},[_vm._v(_vm._s(_vm.$t( "reportsSettings.reportGroups.newBtn" )))])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.reportGroupHeadersSuperuser,"loading":_vm.$apollo.queries.reportGroups.loading,"items":_vm.reportGroups.edges,"options":_vm.reportGroupsDataTableOptions,"server-items-length":_vm.reportGroups.totalCount,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{
										'items-per-page-options': [
											10,
											25,
											50,
											75,
											100,
										],
										'disable-pagination':
											_vm.$apollo.queries.reportGroups
												.loading,
									},"item-key":"node.id","show-select":_vm.me.isSuperuser
											? 'show-select'
											: undefined},on:{"update:options":function($event){_vm.reportGroupsDataTableOptions=$event}},scopedSlots:_vm._u([{key:`item.action`,fn:function({ item }){return [(_vm.me.isSuperuser)?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.editReportGroup(item.node)}}},[_c('v-icon',[_vm._v("edit")])],1):_vm._e()]}}],null,true),model:{value:(_vm.selectedReportGroups),callback:function ($$v) {_vm.selectedReportGroups=$$v},expression:"selectedReportGroups"}})],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }