<template>
	<v-row justify="center">
		<!-- Edit profile -->
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card>
				<v-alert v-if="error" tile type="error">{{ error }}</v-alert>

				<v-card-title class="primary white--text">
					{{ formTitle }}
					<v-spacer></v-spacer>
				</v-card-title>

				<v-card-text class="pt-5">
					<v-form ref="form">
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.severityName"
									:counter="100"
									:rules="[
										rules.required,
										rules.maxLength(100),
									]"
									v-bind:label="
										$t(
											'severityEditDialog.severityNameLabel'
										)
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.index"
									:rules="[rules.required]"
									v-bind:label="
										$t('severityEditDialog.indexLabel')
									"
									type="number"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.icon"
									:counter="50"
									:rules="[
										rules.required,
										rules.maxLength(50),
									]"
									v-bind:label="
										$t('severityEditDialog.iconLabel')
									"
									outlined
									class="subtitle-2"
									hint="https://material.io/resources/icons/"
									persistent-hint
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.color"
									:counter="50"
									:rules="[
										rules.required,
										rules.maxLength(50),
									]"
									v-bind:label="
										$t('severityEditDialog.colorLabel')
									"
									outlined
									class="subtitle-2"
									hint="success, info, warning, error"
									persistent-hint
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn
						:disabled="isSaving"
						text
						rounded
						@click="closeSeverityDialog"
						>{{ $t("button.cancelBtn") }}</v-btn
					>
					<v-btn
						outlined
						rounded
						:loading="isSaving"
						color="primary"
						@click="saveSeverity"
						>{{ $t("button.saveBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
	name: "severity-edit-dialog",
	props: ["dialog", "object"],
	components: {},
	data() {
		return {
			error: null,
			isSaving: false,

			defaultItem: {
				id: null,
				index: null,
				severityName: null,
				color: null,
				icon: null,
			},
			editedItem: {
				id: null,
				index: null,
				severityName: null,
				color: null,
				icon: null,
			},
			rules: {
				required: (v) => !helper.isEmpty(v) || "This field is required",
				minLength: (len) => (v) =>
					(v || "").length >= len ||
					`Invalid character length, required ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len || "Too long",
			},
		};
	},
	computed: {
		formTitle() {
			return this.object
				? this.$t("severityEditDialog.editTitle")
				: this.$t("severityEditDialog.newTitle");
		},
		me() {
			return this.$store.state.user.me;
		},
	},
	watch: {
		// reset form when dialog open or close
		dialog(val) {
			// if open dialog, initialize selections
			if (val) {
			}

			this.resetForm();
		},
	},
	created() {
		this.fetchMe();
	},
	methods: {
		fetchMe() {
			this.$store
				.dispatch("user/fetchMe")
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		resetForm() {
			// reset form state
			this.error = null;
			this.isSaving = false;
			this.panel = 0;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			// when editing, set form with object
			if (this.dialog && this.object) {
				this.editedItem.id = this.object.id;
				this.editedItem.index = this.object.index;
				this.editedItem.severityName = this.object.severityName;
				this.editedItem.color = this.object.color;
				this.editedItem.icon = this.object.icon;
			}
			// when new or closing, set form with default
			else {
				this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
			}
		},

		closeSeverityDialog() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		saveSeverity() {
			if (!this.$refs.form.validate()) {
				return;
			}

			// set form state
			this.error = null;
			this.isSaving = true;

			// prepare api call payload
			var payload = {
				index: this.editedItem.index,
				severityName: this.editedItem.severityName,
				color: this.editedItem.color,
				icon: this.editedItem.icon,
			};

			// if editing
			if (this.object) {
				// prepare graphql mutation payload
				payload.id = this.editedItem.id;

				// update
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation updateSeverity(
								$input: UpdateSeverityInput!
							) {
								updateSeverity(input: $input) {
									severity {
										id
										severityName
										index
										color
										icon
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// update parent object
						this.$emit(
							"item-created",
							response.data.updateSeverity.severity
						);
						this.closeSeverityDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Severity successfully edited`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
						this.isSaving = false;
					})
					.finally(() => {});
			}
			// new
			else {
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation createSeverity(
								$input: CreateSeverityInput!
							) {
								createSeverity(input: $input) {
									severity {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						this.$emit(
							"item-created",
							response.data.createSeverity.severity
						);
						this.closeSeverityDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Severity successfully added`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						console.log(error);

						this.error = error.graphQLErrors
							.map((error) => error.message)
							.join(", ");
						this.isSaving = false;
					})
					.finally(() => {});
			}
		},
	},
};
</script>
