var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0"},[(_vm.$apollo.queries.dashboardQuery.loading)?_c('v-progress-linear',{staticClass:"ma-0 pa-0",attrs:{"color":"primary","indeterminate":"","rounded":""}}):_vm._e()],1),_c('v-container',[_c('v-row',{staticClass:"mt-10",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"loading":_vm.isLoadingSearch,"items":_vm.searchQuery.reports,"item-text":(item) =>
							`${item.node.tenant.tenantName} - ${item.node.reportName}`,"item-value":"node.id","search-input":_vm.search,"cache-items":"","prepend-inner-icon":"search","solo":"","placeholder":_vm.$t('home.searchReportLabel'),"clearable":"","hide-no-data":"","hide-details":"","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":function($event){return _vm.goToReportDetail(
							_vm.selectedReport.node.id,
							_vm.selectedReport.node.reportType
						)}},model:{value:(_vm.selectedReport),callback:function ($$v) {_vm.selectedReport=$$v},expression:"selectedReport"}})],1),(_vm.me.isSuperuser)?_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-switch',{staticClass:"my-0 py-0",attrs:{"loading":_vm.isLoadingUnderMaintenance,"disabled":_vm.isLoadingUnderMaintenance,"label":_vm.$t('home.underMaintenanceLabel'),"hide-details":""},on:{"change":_vm.updateUnderMaintenance},model:{value:(_vm.underMaintenance.isUnderMaintenance),callback:function ($$v) {_vm.$set(_vm.underMaintenance, "isUnderMaintenance", $$v)},expression:"underMaintenance.isUnderMaintenance"}})],1):_vm._e()],1),_c('v-row',{staticClass:"mt-10",attrs:{"align":"center"}},[(_vm.dashboardQuery.reportsTotalCount)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"loading":_vm.$apollo.queries.dashboardQuery.loading
									? 'white'
									: null,"elevation":hover ? 10 : 1,"color":"primary lighten-1","dark":"","outlined":"","height":"160px","link":"","href":"/#/reports"}},[_c('v-card-title',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t("home.reportsTitle")))]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("bar_chart")])],1),_c('v-col',{staticClass:"display-3",attrs:{"cols":"10"}},[_vm._v(_vm._s(_vm.dashboardQuery.reportsTotalCount || 0))])],1)],1)],1)]}}],null,false,868069894)})],1):_vm._e(),(_vm.dashboardQuery.knowledgeBaseTotalCount)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"loading":_vm.$apollo.queries.dashboardQuery.loading
									? 'white'
									: null,"elevation":hover ? 10 : 1,"color":"primary lighten-1","dark":"","outlined":"","height":"160px","link":"","href":"/#/knowledge-base"}},[_c('v-card-title',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t("home.knowledgeBaseTitle")))]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("info")])],1),_c('v-col',{staticClass:"display-3",attrs:{"cols":"10"}},[_vm._v(_vm._s(_vm.dashboardQuery.knowledgeBaseTotalCount || 0))])],1)],1)],1)]}}],null,false,3841768125)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"loading":_vm.$apollo.queries.dashboardQuery.loading
									? 'white'
									: null,"elevation":hover ? 10 : 1,"color":!_vm.me.passed2fa
									? 'error'
									: 'primary lighten-1',"dark":"","outlined":"","height":"160px","link":"","href":"/#/settings"}},[_c('v-card-title',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t("home.settingsTitle"))+" "),_c('v-spacer'),(!_vm.me.passed2fa)?_c('v-icon',{attrs:{"left":""}},[_vm._v("warning")]):_vm._e()],1),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("settings")])],1),_c('v-col',{staticClass:"display-2",attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(_vm.me.firstName))])],1)],1)],1)]}}])})],1),(
					_vm.hasPermission('users.view_customuser') &&
						_vm.dashboardQuery.usersTotalCount
				)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"loading":_vm.$apollo.queries.dashboardQuery.loading
									? 'white'
									: null,"elevation":hover ? 10 : 1,"color":"primary lighten-1","dark":"","outlined":"","height":"160px","link":"","href":"/#/users"}},[_c('v-card-title',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t("home.usersTitle")))]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("contacts")])],1),_c('v-col',{staticClass:"display-3",attrs:{"cols":"10"}},[_vm._v(_vm._s(_vm.dashboardQuery.usersTotalCount || 0))])],1)],1)],1)]}}],null,false,2502388584)})],1):_vm._e(),(_vm.dashboardQuery.overallStatus)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"loading":_vm.$apollo.queries.dashboardQuery.loading
									? 'white'
									: null,"elevation":hover ? 10 : 1,"color":_vm.dashboardQuery.overallStatus.node.severity
									.color,"dark":"","outlined":"","height":"160px","link":"","href":"/#/status"}},[_c('v-card-title',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t("home.statusTitle")))]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.dashboardQuery.overallStatus .node.severity.icon))])],1),_c('v-col',{staticClass:"display-1",attrs:{"cols":"10"}},[_vm._v(_vm._s(_vm.dashboardQuery.overallStatus.node .severity.severityName))])],1)],1)],1)]}}],null,false,1476239845)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"loading":_vm.$apollo.queries.dashboardQuery.loading
									? 'white'
									: null,"elevation":hover ? 10 : 1,"color":"#EC6726","dark":"","outlined":"","height":"160px","link":"","href":"/#/announcements"}},[_c('v-card-title',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t("home.announcementTitle")))]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("lightbulb")])],1),_c('v-col',{staticClass:"display-1",attrs:{"cols":"10"}},[_vm._v(_vm._s(_vm.$t("announcements.announcementShort")))])],1)],1)],1)]}}])})],1),(_vm.dashboardQuery.maintenance)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"loading":_vm.$apollo.queries.dashboardQuery.loading
									? 'white'
									: null,"elevation":hover ? 10 : 1,"color":"primary lighten-1","dark":"","outlined":"","height":"160px","link":"","href":"/#/status"}},[_c('v-card-title',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t("home.nextMaintenanceTitle")))]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("build")])],1),_c('v-col',{staticClass:"display-2",attrs:{"cols":"10"}},[_vm._v(_vm._s(_vm._f("moment")(_vm.dashboardQuery.maintenance.node .start,"YYYY-MM-DD")))])],1)],1)],1)]}}],null,false,1699452489)})],1):_vm._e()],1),_c('v-row',{staticClass:"mt-10"},[_c('v-slide-group',{attrs:{"active-class":"success","show-arrows":""}},_vm._l((_vm.reportFavorites.edges),function(reportFavorite){return _c('v-slide-item',{key:reportFavorite.node.id},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"ma-4",attrs:{"loading":_vm.$apollo.queries.reportFavorites.loading
										? 'white'
										: null,"elevation":hover ? 10 : 1,"color":"grey lighten-1","dark":"","outlined":"","height":"160px","link":""}},[_c('v-card-title',{staticClass:"subtitle-1"},[_vm._v(_vm._s(reportFavorite.node.report .reportName)),_c('v-spacer'),_c('v-icon',{attrs:{"right":"","small":""},on:{"click":function($event){return _vm.deleteReportFavorite(
												reportFavorite.node.id
											)}}},[_vm._v("star")])],1),_c('v-card-text',{staticClass:"text-center",on:{"click":function($event){return _vm.goToReportDetail(
											reportFavorite.node.report.id,
											reportFavorite.node.report
												.reportType
										)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(
													reportFavorite.node
														.report
														.reportType ===
														'POWERBIREPORT'
												)?_c('v-icon',{attrs:{"x-large":""}},[_vm._v("dashboard")]):(
													reportFavorite.node
														.report
														.reportType ===
														'PAGINATEDREPORT'
												)?_c('v-icon',{attrs:{"x-large":""}},[_vm._v("layers")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[(
													reportFavorite.node
														.report
														.lastRefreshStatus ===
														'Completed'
												)?_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v(_vm._s(_vm._f("moment")(reportFavorite.node .report .lastRefreshEndTime, "YYYY-MM-DD HH:mm" )))]):(
													reportFavorite.node
														.report
														.lastRefreshStatus ===
														'Failed'
												)?_c('v-chip',{attrs:{"color":"error"}},[_vm._v(_vm._s(_vm._f("moment")(reportFavorite.node .report .lastRefreshEndTime, "YYYY-MM-DD HH:mm" )))]):(
													reportFavorite.node
														.report
														.lastRefreshStatus ===
														'Disabled'
												)?_c('v-chip',{attrs:{"color":"error"}},[_vm._v(_vm._s(_vm._f("moment")(reportFavorite.node .report .lastRefreshEndTime, "YYYY-MM-DD HH:mm" )))]):(
													reportFavorite.node
														.report
														.lastRefreshStatus ===
														'Unknown'
												)?_c('v-chip',{attrs:{"color":"warning"}},[_vm._v(_vm._s(_vm._f("moment")(reportFavorite.node .report .lastRefreshEndTime, "YYYY-MM-DD HH:mm" )))]):_vm._e()],1)],1)],1)],1)]}}],null,true)})],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }