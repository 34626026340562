import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import Announcements from "@/views/Announcements.vue";
import Home from "@/views/Home.vue";
import Users from "@/views/Users.vue";
import UsersSettings from "@/views/UsersSettings.vue";
import GroupDetail from "@/views/GroupDetail.vue";
import Reports from "@/views/Reports.vue";
import ReportsSettings from "@/views/ReportsSettings.vue";
import ReportDetail from "@/views/ReportDetail.vue";
import ReportDetailSettings from "@/views/ReportDetailSettings.vue";
import PaginatedReportDetail from "@/views/PaginatedReportDetail.vue";
import Status from "@/views/Status.vue";
import StatusSettings from "@/views/StatusSettings.vue";
import Settings from "@/views/Settings.vue";
import KnowledgeBase from "@/views/KnowledgeBase.vue";
import KnowledgeBaseSettings from "@/views/KnowledgeBaseSettings.vue";
import KnowledgeBaseDetail from "@/views/KnowledgeBaseDetail.vue";
import { ACCESS_TOKEN_KEY } from "@/vue-apollo";

Vue.use(VueRouter);

const routes = [
	{
		path: "/login",
		name: "login",
		component: Login,
	},
	{
		path: "/resetpassword/:uidb64/:token",
		name: "resetpassword",
		component: ResetPassword,
	},
	{
		path: "/",
		name: "home",
		component: Home,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/announcements",
		name: "announcements",
		component: Announcements,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/users",
		name: "users",
		component: Users,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/users/settings",
		name: "users-settings",
		component: UsersSettings,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/group/:groupNodeId",
		name: "group-detail",
		component: GroupDetail,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/reports",
		name: "reports",
		component: Reports,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/reports/settings",
		name: "reports-settings",
		component: ReportsSettings,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/report/:reportNodeId",
		name: "report-detail",
		component: ReportDetail,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/report/:id/settings",
		name: "report-detail-settings",
		component: ReportDetailSettings,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/paginated-report/:reportNodeId",
		name: "paginated-report-detail",
		component: PaginatedReportDetail,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/status",
		name: "status",
		component: Status,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/status/settings",
		name: "status-settings",
		component: StatusSettings,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/settings",
		name: "settings",
		component: Settings,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/knowledge-base",
		name: "knowledge-base",
		component: KnowledgeBase,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/knowledge-base/settings",
		name: "knowledge-base-settings",
		component: KnowledgeBaseSettings,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/knowledge-base/:id",
		name: "knowledge-base-detail",
		component: KnowledgeBaseDetail,
		meta: {
			requiresAuth: true,
		},
	},
];

const router = new VueRouter({
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
			next();
		} else {
			next({ name: "login", query: { redirect: to.path } });
		}
	} else {
		next();
	}
});

export default router;
