<template>
	<v-container class="fill-height" fluid>
		<!-- Login Dialog -->
		<v-row align="center" justify="center">
			<v-col cols="12" sm="8" md="6" lg="4">
				<v-card class="elevation-1">
					<v-toolbar color="primary">
						<v-toolbar-title class="white--text">{{
							$t("login.title")
						}}</v-toolbar-title>
						<v-spacer></v-spacer>
						<!-- Translations -->
						<v-menu
							offset-y
							origin="center center"
							:nudge-bottom="10"
							transition="scale-transition"
						>
							<template v-slot:activator="{ on }">
								<v-btn dark icon v-on="on">
									<v-icon>
										translate
									</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item
									v-for="item in locales"
									:key="item.locale"
									@click="changeLocale(item.locale)"
								>
									<v-list-item-title>{{
										item.localeName
									}}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-toolbar>
					<v-card-text class="mt-10 pb-0">
						<v-form ref="loginForm">
							<v-text-field
								v-bind:placeholder="$t('login.emailLabel')"
								name="login"
								prepend-icon="person"
								type="email"
								:rules="[rules.required]"
								:error-messages="error"
								outlined
								v-model="email"
							></v-text-field>

							<v-text-field
								id="password"
								v-bind:placeholder="$t('login.passwordLabel')"
								name="password"
								prepend-icon="lock"
								:type="showpassword ? 'text' : 'password'"
								:rules="[rules.required]"
								:error-messages="error"
								outlined
								v-model="password"
								@keydown.enter="login"
							>
								<template v-slot:append>
									<v-icon
										color="primary"
										@click="showpassword = !showpassword"
										tabindex="-1"
										>{{
											showpassword
												? "visibility"
												: "visibility_off"
										}}</v-icon
									>
								</template>
							</v-text-field>
						</v-form>
					</v-card-text>
					<p class="text-center">
						<v-btn
							color="primary"
							class="mr-3 mb-3"
							outlined
							rounded
							:disabled="email.length < 2 || password.length < 2"
							:loading="isLoggingIn"
							@click="login"
							>{{ $t("button.loginBtn") }}</v-btn
						>
						<br />
						<a @click="forgotPasswordDialog = true">
							{{ $t("login.forgotPassword") }}
						</a>
					</p>
					<v-divider></v-divider>
					<p class="text-center pb-3">
						<v-btn
							color="primary"
							class="mt-3 mr-3"
							rounded
							outlined
							@click="loginWithAzureAD"
						>
							<v-img
								class="mr-1"
								width="20"
								src="https://ltpbiportal.blob.core.windows.net/media/images/microsoft-logo.svg"
							></v-img>
							{{ $t("login.loginWithMicrosoft") }}</v-btn
						>
					</p>
				</v-card>
			</v-col>
		</v-row>

		<!-- 2FA dialog -->
		<v-dialog v-model="tfaDialog" persistent max-width="400px">
			<v-card>
				<v-card-title class="primary white--text">
					{{ $t("login.2fa.title") }}
					<v-spacer></v-spacer>
					<v-btn class="mr-n3" dark icon @click="close2FADialog()">
						<v-icon>close</v-icon>
					</v-btn>
				</v-card-title>

				<v-card-text class="pt-5 pb-0">
					<v-form ref="tfaForm" @submit.prevent>
						<v-text-field
							v-model="tfaCode"
							counter="6"
							:rules="[rules.required, rules.maxLength(6)]"
							v-bind:label="$t('login.2fa.2faLabel')"
							type="number"
							outlined
							class="headline"
							:error-messages="tfaCodeError"
							v-bind:hint="$t('login.2fa.2faHint')"
							@keydown.enter="loginWith2FA"
							autofocus
						></v-text-field>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-3">
					<v-spacer></v-spacer>
					<v-btn
						outlined
						rounded
						:disabled="tfaCode.length != 6"
						:loading="isLoggingInWith2FA"
						color="primary"
						@click="loginWith2FA"
						@keydown.enter="loginWith2FA"
						>Verify</v-btn
					>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Forgot Passwrod dialog -->
		<v-dialog
			v-model="forgotPasswordDialog"
			persistent
			max-width="500px"
			@keydown.esc="closeForgotPasswordDialog"
		>
			<v-card>
				<v-card-title class="primary white--text">
					{{ $t("login.resetPassword.title") }}
					<v-spacer></v-spacer>
					<v-btn
						class="mr-n3"
						dark
						icon
						@click="closeForgotPasswordDialog()"
					>
						<v-icon>close</v-icon>
					</v-btn>
				</v-card-title>

				<v-card-text v-if="!isResetPasswordEmailSent" class="pt-5 pb-0">
					<p>{{ $t("login.resetPassword.description") }}</p>
					<v-form ref="forgotPasswordForm" @submit.prevent>
						<v-text-field
							v-model="forgotPasswordEmail"
							:rules="[rules.required]"
							v-bind:label="$t('login.resetPassword.emailLabel')"
							type="email"
							outlined
							:error-messages="forgotPasswordEmailError"
							@keydown.enter="requestPasswordReset"
							autofocus
						></v-text-field>
					</v-form>
				</v-card-text>
				<v-card-text v-else>
					<h1 class="text-center my-5">
						{{ $t("login.resetPassword.confirmedTitle") }}
					</h1>
					<p>
						{{ $t("login.resetPassword.confirmedDescription") }}
					</p>
				</v-card-text>

				<v-card-actions class="pb-3">
					<v-spacer></v-spacer>
					<v-btn
						v-if="!isResetPasswordEmailSent"
						outlined
						rounded
						:disabled="!forgotPasswordEmail.includes('@')"
						:loading="isRequestingPasswordReset"
						color="primary"
						@click="requestPasswordReset()"
						>{{ $t("button.continueBtn") }}</v-btn
					>
					<v-btn
						v-else
						outlined
						rounded
						color="primary"
						@click="closeForgotPasswordDialog()"
						>{{ $t("button.closeBtn") }}</v-btn
					>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Under Maintenance dialog -->
		<v-dialog
			v-model="underMaintenance.isUnderMaintenance"
			persistent
			max-width="500px"
			@keydown.esc="closeUnderMaintenanceDialog"
		>
			<v-card>
				<v-card-title class="primary white--text">
					Under Maintenance
					<v-spacer></v-spacer>
					<v-btn
						class="mr-n3"
						dark
						icon
						@click="closeUnderMaintenanceDialog()"
					>
						<v-icon>close</v-icon>
					</v-btn>
				</v-card-title>

				<v-card-text>
					<h1 class="text-center my-5">English</h1>
					<p>
						Insurance Data BI Portal is down for planned
						maintenance. We'll be back with the usual report
						goodness as soon as possible. You will not be able to
						log in until then.
					</p>
					<v-divider></v-divider>
					<h1 class="text-center my-5">Nederlands</h1>
					<p>
						Insurance Data BI Portal is niet beschikbaar vanwege
						gepland onderhoud. We komen zo snel mogelijk terug met
						de gebruikelijke rapportages. Tot dan is het niet
						mogelijk om in te loggen.
					</p>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import msalAuth from "@/utils/msalAuth.js";

export default {
	apollo: {
		underMaintenance: {
			query: gql`
				query underMaintenance {
					underMaintenance(id: "VW5kZXJNYWludGVuYW5jZU5vZGU6MQ==") {
						id
						createdAt
						modifiedAt
						isUnderMaintenance
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "network-only",
			update: (data) => {
				return {
					isUnderMaintenance:
						data.underMaintenance.isUnderMaintenance,
				};
			},
			pollInterval: 60000, // ms
		},
	},

	data: function() {
		return {
			isLoggingIn: false,
			isLoggingInWith2FA: false,
			showpassword: false,
			tfaDialog: false,

			underMaintenance: {},

			forgotPasswordDialog: false,
			isRequestingPasswordReset: false,
			forgotPasswordEmail: "",
			forgotPasswordEmailError: "",
			isResetPasswordEmailSent: false,

			// User account object synced with MSAL getAccount()
			msalUser: {},
			// Access token fetched via MSAL for calling Graph API
			msalAccessToken: "",

			email: "",
			password: "",
			tfaCode: "",
			error: "",
			tfaCodeError: "",
			mess: "",
			rules: {
				required: (v) => !helper.isEmpty(v) || "This field is required",
				listRequired: (v) =>
					(v || "").length >= 1 || `This field is required`,
				minLength: (len) => (v) =>
					(v || "").length >= len || `Require at least ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len || "Too long",
			},

			locales: [
				{
					locale: "en",
					localeName: "English",
				},
				{
					locale: "nl",
					localeName: "Nederlands",
				},
				{
					locale: "de",
					localeName: "Deutsch",
				},
			],
		};
	},
	created() {
		this.setupMsalAuth();
	},
	computed: {},
	methods: {
		requestPasswordReset() {
			// check if form valid
			if (!this.$refs.forgotPasswordForm.validate()) {
				return;
			}

			this.isRequestingPasswordReset = true;

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation requestPasswordReset(
							$input: RequestPasswordResetInput!
						) {
							requestPasswordReset(input: $input) {
								success
							}
						}
					`,
					// Parameters
					variables: {
						input: {
							email: this.forgotPasswordEmail,
						},
					},
				})
				.then(() => {
					this.isResetPasswordEmailSent = true;
				})
				.catch((error) => {
					if (error.graphQLErrors) {
						for (let err of error.graphQLErrors) {
							this.forgotPasswordEmailError = err.message;
						}
					}
				})
				.finally(() => {
					this.isRequestingPasswordReset = false;
				});
		},

		closeForgotPasswordDialog() {
			this.isResetPasswordEmailSent = false;
			this.forgotPasswordEmail = "";
			this.forgotPasswordEmailError = "";
			if (this.$refs.forgotPasswordForm) {
				this.$refs.forgotPasswordForm.resetValidation();
			}
			this.forgotPasswordDialog = false;
		},

		closeUnderMaintenanceDialog() {
			this.underMaintenance.isUnderMaintenance = false;
		},

		login() {
			this.error = null;

			// check if form valid
			if (!this.$refs.loginForm.validate()) {
				return;
			}

			this.isLoggingIn = true;

			const payload = { email: this.email, password: this.password };

			this.$store
				.dispatch("user/login", payload)
				.then(() => {
					this.fetchMe();
					this.$router.push(this.$route.query.redirect || "/");
				})
				.catch((error) => {
					if (error.graphQLErrors) {
						for (let err of error.graphQLErrors) {
							/*2fa*/
							if (err.message === "2FA") {
								this.tfaDialog = true;
							} else {
								this.error = err.message;
							}
						}
					}
				})
				.finally(() => {
					this.isLoggingIn = false;
				});
		},

		close2FADialog() {
			this.tfaCode = "";
			this.$refs.tfaForm.resetValidation();
			this.tfaDialog = false;
		},

		loginWith2FA() {
			// check if form valid
			if (!this.$refs.tfaForm.validate()) {
				return;
			}

			this.isLoggingInWith2FA = true;

			const payload = {
				email: this.email,
				password: this.password,
				tfaCode: this.tfaCode,
			};

			this.$store
				.dispatch("user/login", payload)
				.then(() => {
					this.fetchMe();
					this.$router.push(this.$route.query.redirect || "/");
				})
				.catch((error) => {
					if (error.graphQLErrors) {
						for (let err of error.graphQLErrors) {
							/*2fa*/
							if (err.message === "INVALID_GACODE") {
								this.tfaCodeError = "Invalide 2FA-Code";
							} else {
								this.tfaCodeError = err.message;
							}
						}
					}
				})
				.finally(() => {
					this.isLoggingInWith2FA = false;
				});
		},

		fetchMe() {
			this.$store
				.dispatch("user/fetchMe")
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		changeLocale(locale) {
			console.log(locale);
			localStorage.setItem("locale", locale);
			this.$i18n.locale = locale;
		},

		setupMsalAuth() {
			// Basic setup of MSAL helper with client id, or give up
			if (process.env.VUE_APP_MSAL_CLIENT_ID) {
				msalAuth.configure(process.env.VUE_APP_MSAL_CLIENT_ID, false);

				// Restore any cached or saved local user
				this.msalUser = msalAuth.user();
				console.log(`configured ${msalAuth.isConfigured()}`);
			} else {
				this.error =
					"VUE_APP_MSAL_CLIENT_ID is not set, the app will not function! 😥";
			}
		},

		async loginWithAzureAD() {
			try {
				await msalAuth.login();
				this.msalUser = msalAuth.user();
				this.msalAccessToken = await msalAuth.acquireToken();
				this.isLoggingIn = true;

				const payload = { msalAccessToken: this.msalAccessToken };

				this.$store
					.dispatch("user/loginWithAzureAd", payload)
					.then(() => {
						this.fetchMe();
						this.$router.push(this.$route.query.redirect || "/");
						msalAuth.clearLocal();
					})
					.catch((error) => {
						if (error.graphQLErrors) {
							for (let err of error.graphQLErrors) {
								this.error = err.message;

								msalAuth.clearLocal();
								this.msalUser = {};
								this.msalAccessToken = "";
							}
						}
					})
					.finally(() => {
						this.isLoggingIn = false;
					});
			} catch (err) {
				this.error = err.toString();
			}
		},
	},
};
</script>
