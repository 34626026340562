var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center pl-3"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":"/"}}),_c('v-spacer'),_c('v-btn-toggle',{staticClass:"ml-5 mr-6",attrs:{"dense":"","rounded":"","mandatory":""},model:{value:(_vm.toggleRefreshStatus),callback:function ($$v) {_vm.toggleRefreshStatus=$$v},expression:"toggleRefreshStatus"}},[_c('v-btn',{attrs:{"small":"","value":"ALL"}},[_vm._v(_vm._s(_vm.$t("reports.toggleRefreshStatus.all")))]),_vm._l((_vm.reportGroups.edges),function(reportGroup){return _c('v-btn',{key:reportGroup.node.id,attrs:{"value":reportGroup.node.reportGroupName,"small":""}},[_vm._v(" "+_vm._s(reportGroup.node.reportGroupName)+" ")])})],2)],1),_c('DeleteDialog',{attrs:{"dialog":_vm.deleteDialog},on:{"update:dialog":function($event){_vm.deleteDialog=$event},"confirmed":_vm.deletingSelectedReportsConfirmed},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("deleteDialog.title")))]},proxy:true},{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.$t("deleteDialog.content")))]},proxy:true}])}),_c('ReportEditDialog',{attrs:{"object":_vm.editedItem,"dialog":_vm.reportDialog},on:{"update:object":function($event){_vm.editedItem=$event},"update:dialog":function($event){_vm.reportDialog=$event},"item-created":_vm.onReportCreated}}),_c('ReportAddDialog',{attrs:{"object":_vm.editedItem,"dialog":_vm.reportDialogAPI},on:{"update:object":function($event){_vm.editedItem=$event},"update:dialog":function($event){_vm.reportDialogAPI=$event},"item-created":_vm.onReportCreated}}),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{class:{
					'mx-0 elevation-5': _vm.$vuetify.breakpoint.xsOnly,
					'mx-3 elevation-5': _vm.$vuetify.breakpoint.smAndUp,
				}},[_c('v-card-title',{staticClass:"pb-0"},[_c('v-icon',{staticClass:"mx-2",attrs:{"color":"primary"}},[_vm._v("bar_chart")]),_vm._v(_vm._s(_vm.$t("reports.title"))+" "),(_vm.hasPermission('dashboards.view_usermapping'))?_c('v-btn',{staticClass:"mr-5",attrs:{"icon":"","fab":"","small":""},on:{"click":function($event){return _vm.goToReportsSettings()}}},[_c('v-icon',[_vm._v("settings")])],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.searchReports,"append-outer-icon":"refresh","label":_vm.$t('datatable.searchLabel'),"clearable":"","hide-details":""},on:{"input":_vm.handleSearch,"click:append-outer":function($event){return _vm.refreshCurrentPage()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.refreshCurrentPage()},"click:clear":function($event){return _vm.onClearSearch()}}})],1),_c('v-spacer'),(_vm.me.isSuperuser)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","dark":"","outlined":"","rounded":"","small":"","disabled":_vm.selectedReports.length <= 0},on:{"click":function($event){return _vm.deletingSelectedReports()}}},[_vm._v(_vm._s(_vm.$t("reports.deleteBtn")))]):_vm._e(),(_vm.me.isSuperuser)?_c('v-btn',{attrs:{"color":"primary","dark":"","outlined":"","rounded":"","small":""},on:{"click":function($event){return _vm.addReportAPI()}}},[_vm._v(_vm._s(_vm.$t("reports.newBtn")))]):_vm._e()],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.me.isSuperuser ? _vm.headersSuperuser : _vm.headers,"loading":_vm.$apollo.queries.reports.loading,"items":_vm.reports.edges,"options":_vm.dataTableOptions,"server-items-length":_vm.reports.totalCount,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{
								'items-per-page-options': [
									10,
									25,
									50,
									75,
									100,
								],
								'disable-pagination':
									_vm.$apollo.queries.reports.loading,
							},"item-key":"node.id","show-select":_vm.me.isSuperuser ? 'show-select' : undefined},on:{"update:options":function($event){_vm.dataTableOptions=$event}},scopedSlots:_vm._u([{key:`item.node.reportType`,fn:function({
									item,
								}){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(
												item.node.reportType ===
													'POWERBIREPORT'
											)?_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("dashboard")]):(
												item.node.reportType ===
													'PAGINATEDREPORT'
											)?_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("layers")]):_vm._e()]}}],null,true)},[(
											item.node.reportType ===
												'POWERBIREPORT'
										)?_c('span',[_vm._v(" "+_vm._s("Dashboard")+" ")]):(
											item.node.reportType ===
												'PAGINATEDREPORT'
										)?_c('span',[_vm._v(" "+_vm._s("Paginated Report")+" ")]):_vm._e()])]}},{key:`item.node.reportName`,fn:function({
									item,
								}){return [_c('span',{staticClass:"row-pointer",on:{"click":function($event){return _vm.goToReportDetail(
											item.node.id,
											item.node.reportType
										)}}},[_vm._v(" "+_vm._s(item.node.reportName)+" ")])]}},{key:`item.node.lastRefreshStatus`,fn:function({
									item,
								}){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(
												item.node
													.lastRefreshStatus ===
													'Completed'
											)?_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("check_circle")]):(
												item.node
													.lastRefreshStatus ===
													'Failed'
											)?_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("cancel")]):(
												item.node
													.lastRefreshStatus ===
													'Disabled'
											)?_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("cancel")]):(
												item.node
													.lastRefreshStatus ===
													'Unknown'
											)?_c('v-icon',_vm._g({attrs:{"color":"warning"}},on),[_vm._v("error")]):_vm._e()]}}],null,true)},[(
											item.node.lastRefreshStatus ===
												'Unknown'
										)?_c('span',[_vm._v(" Refreshing ")]):_c('span',[_vm._v(" "+_vm._s(item.node.lastRefreshStatus)+" ")])])]}},{key:`item.node.lastRefreshEndTime`,fn:function({
									item,
								}){return [(item.node.lastRefreshEndTime)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.node.lastRefreshEndTime,"YYYY-MM-DD HH:mm")))]):_c('span')]}},{key:`item.node.refreshScheduleIsEnabled`,fn:function({
									item,
								}){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(item.node.isDirectQuery)?_c('v-icon',_vm._g({attrs:{"color":"info"}},on),[_vm._v("swap_horizontal_circle")]):(
												item.node
													.refreshScheduleIsEnabled
											)?_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("check_circle")]):_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("cancel")])]}}],null,true)},[(item.node.isDirectQuery)?_c('span',[_vm._v(" Direct Query ")]):(
											item.node
												.refreshScheduleIsEnabled
										)?_c('span',[_vm._v(" Enabled ")]):_c('span',[_vm._v(" Disabled ")])])]}},{key:`item.node.refreshScheduleTimes`,fn:function({
									item,
								}){return [(item.node.isDirectQuery)?_c('span'):_c('span',[_vm._v(_vm._s(item.node.refreshScheduleTimes))])]}},{key:`item.action`,fn:function({ item }){return [(
										item.node.favoriteSet.edges.length >
											0
									)?[_vm._l((item.node
											.favoriteSet.edges),function(favorite){return [(favorite.node.isFavorite)?_c('v-btn',{key:favorite.node.id,attrs:{"icon":"","color":"primary","loading":_vm.$apollo.queries.reports
													.loading},on:{"click":function($event){return _vm.deleteReportFavorite(
													favorite.node.id
												)}}},[_c('v-icon',[_vm._v("star")])],1):_vm._e()]})]:[_c('v-btn',{attrs:{"icon":"","color":"primary","loading":_vm.$apollo.queries.reports.loading},on:{"click":function($event){return _vm.createReportFavorite(
												item.node.id
											)}}},[_c('v-icon',[_vm._v("star_border")])],1)],_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.goToReportDetail(
											item.node.id,
											item.node.reportType
										)}}},[_c('v-icon',[_vm._v("remove_red_eye")])],1),(_vm.me.isSuperuser)?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.editReport(item.node)}}},[_c('v-icon',[_vm._v("edit")])],1):_vm._e()]}}],null,true),model:{value:(_vm.selectedReports),callback:function ($$v) {_vm.selectedReports=$$v},expression:"selectedReports"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }