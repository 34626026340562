<template>
	<v-container fluid>
		<!-- Delete KnowledgeBase Dialog -->
		<DeleteDialog
			v-if="me.isSuperuser"
			:dialog.sync="deleteKnowledgeBaseDialog"
			:item="knowledgeBase"
			v-on:confirmed="deleteKnowledgeBaseConfirmed"
		>
			<template v-slot:title>{{ $t("deleteDialog.title") }}</template>
			<template v-slot:content>{{ $t("deleteDialog.content") }}</template>
		</DeleteDialog>

		<!-- Edit KnowledgeBase Dialog -->
		<KnowledgeBaseEditDialog
			v-if="me.isSuperuser"
			:dialog.sync="editKnowledgeBaseDialog"
			:object.sync="knowledgeBase"
		></KnowledgeBaseEditDialog>

		<v-row class="mt-6">
			<v-col cols="12" class="pt-0">
				<v-card
					:class="{
						'mx-0 elevation-5': $vuetify.breakpoint.xsOnly,
						'mx-3 elevation-5': $vuetify.breakpoint.smAndUp,
					}"
				>
					<v-card-title>
						<v-icon color="primary" class="mr-2">video_label</v-icon
						>{{ knowledgeBase.knowledgeBaseTitle }}
						<v-spacer></v-spacer>
						<v-btn
							v-if="me.isSuperuser"
							icon
							color="primary"
							@click="editKnowledgeBaseDialog = true"
						>
							<v-icon>edit</v-icon>
						</v-btn>
						<v-btn
							v-if="me.isSuperuser"
							icon
							color="error"
							@click="deleteKnowledgeBaseDialog = true"
						>
							<v-icon>delete</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-subtitle
						>{{ knowledgeBase.createdAt | moment("from") }}
						-
						{{
							knowledgeBase.knowledgeBaseType
								.knowledgeBaseTypeName
						}}</v-card-subtitle
					>
					<v-divider></v-divider>
					<v-card-text>
						<tiptap-vuetify
							v-model="content"
							:extensions="extensions"
							:editor-properties="{
								editable: false,
							}"
						>
							<template #toolbar="">
								<pre></pre>
							</template>
						</tiptap-vuetify>
						<!-- Dekstop view -->
						<template
							v-if="iframeSrc && $vuetify.breakpoint.smAndUp"
						>
							<v-container fluid>
								<v-row
									align="center"
									justify="center"
									class="mt-1"
								>
									<iframe
										allow="fullscreen;"
										:src="iframeSrc"
										style="width: 100vh; height: 50vh; border-style: none;"
									>
									</iframe>
								</v-row>
							</v-container>
						</template>
						<!-- Mobile view -->
						<template
							v-if="iframeSrc && $vuetify.breakpoint.xsOnly"
						>
							<v-container fluid>
								<v-row
									align="center"
									justify="center"
									class="mt-1"
								>
									<iframe
										allow="fullscreen;"
										:src="iframeSrc"
										style="width: 100%; height: 50vh; border-style: none"
									>
									</iframe>
								</v-row>
							</v-container>
						</template>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import KnowledgeBaseEditDialog from "@/components/status/KnowledgeBaseEditDialog.vue";
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import {
	TiptapVuetify,
	Heading,
	Bold,
	Italic,
	Strike,
	Underline,
	Code,
	Paragraph,
	BulletList,
	OrderedList,
	ListItem,
	Link,
	Blockquote,
	HardBreak,
	HorizontalRule,
	History,
} from "tiptap-vuetify";

export default {
	name: "knowledge-base-detail-component",
	props: ["knowledgeBaseNodeId"],
	components: {
		KnowledgeBaseEditDialog,
		DeleteDialog,
		TiptapVuetify,
	},

	apollo: {
		knowledgeBase: {
			query: gql`
				query knowledgeBase($id: ID!) {
					knowledgeBase(id: $id) {
						id
						createdAt
						modifiedAt
						knowledgeBaseTitle
						knowledgeBaseDescription
						knowledgeBaseType {
							id
							knowledgeBaseTypeName
						}
						knowledgeBaseContent
						hasVideo
						videoUrl
						knowledgeBaseAvatarPath
					}
				}
			`,
			variables() {
				return {
					id: this.knowledgeBaseNodeId,
				};
			},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update(data) {
				//send data to parent component
				this.$emit("item-fetched", data.knowledgeBase);

				if (!data.knowledgeBase) {
					alert("Unknown knowledgeBase!");
				}

				//return result to current component
				return data.knowledgeBase;
			},
			pollInterval: 60000, // ms
		},
	},

	data() {
		return {
			editKnowledgeBaseDialog: false,
			deleteKnowledgeBaseDialog: false,

			knowledgeBase: {
				id: null,
				createdAt: null,
				modifiedAt: null,
				knowledgeBaseTitle: null,
				knowledgeBaseDescription: null,
				knowledgeBaseType: {
					id: null,
					knowledgeBaseTypeName: null,
				},
				knowledgeBaseContent: null,
				hasVideo: false,
				videoUrl: null,
				knowledgeBaseAvatarPath: null,
			},

			content: null,
			iframeSrc: null,

			extensions: [
				History,
				Blockquote,
				Link,
				Underline,
				Strike,
				Italic,
				ListItem,
				BulletList,
				OrderedList,
				[
					Heading,
					{
						options: {
							levels: [1, 2, 3],
						},
					},
				],
				Bold,
				Code,
				HorizontalRule,
				Paragraph,
				HardBreak,
			],
		};
	},
	computed: {
		me() {
			return this.$store.state.user.me;
		},
	},
	watch: {
		knowledgeBase: {
			handler() {
				var content = this.knowledgeBase.knowledgeBaseContent;
				if (this.knowledgeBase.hasVideo) {
					this.iframeSrc =
						"https://www.youtube.com/embed/" +
						this.knowledgeBase.videoUrl +
						"?cc_load_policy=1&vq=hd720";
				}
				this.content = content;
			},
		},
	},
	created() {
		this.fetchMe();
	},
	methods: {
		fetchMe() {
			this.$store
				.dispatch("user/fetchMe")
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		deleteKnowledgeBaseConfirmed() {
			if (this.knowledgeBase) {
				const payload = {
					id: this.knowledgeBase.id,
				};
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation deleteKnowledgeBase(
								$input: DeleteKnowledgeBaseInput!
							) {
								deleteKnowledgeBase(input: $input) {
									knowledgeBase {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// close delete dialog
						this.deleteKnowledgeBaseDialog = false;
						this.$emit("item-deleted");

						// show snackbar
						const payload = {
							color: "success",
							message: `Knowledge Base is deleted!`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
					});
			}
		},
	},
};
</script>
