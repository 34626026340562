import gql from "graphql-tag";

//////////////////////////////////////////////////
// Users
/////////////////////////////////////////////////

// fragment
export const USER_FRAGMENT = gql`
	fragment user on UserNode {
		id
		email
		firstName
		lastName
		isSuperuser
		require2fa
		enabled2fa
		tenant {
			id
			tenantName
		}
	}
`;

export const ME_QUERY = gql`
	query {
		me {
			id
			email
			firstName
			lastName
			isSuperuser
			isActive
			require2fa
			enabled2fa
			verified2fa
			passed2fa
			tenant {
				id
				tenantName
				tenantLogoPath
				theme {
					id
					themeName
					primaryColor
					themeLogoPath
				}
			}
			allPermissions
			identityProvider
		}
	}
`;

export const LOGIN_MUTATION = gql`
	mutation TokenAuth($email: String!, $password: String!, $tfaCode: String) {
		tokenAuth(email: $email, password: $password, tfaCode: $tfaCode) {
			token
			refreshToken
		}
	}
`;

export const REFRESH_TOKEN_MUTATION = gql`
	mutation RefreshToken($refreshToken: String!) {
		refreshToken(refreshToken: $refreshToken) {
			token
			refreshToken
		}
	}
`;

export const REVOKE_TOKEN_MUTATION = gql`
	mutation RevokeToken($refreshToken: String!) {
		revokeToken(refreshToken: $refreshToken) {
			revoked
		}
	}
`;

export const LOGOUT_MUTATION = gql`
	mutation Logout($input: LogoutInput!) {
		logout(input: $input) {
			revoked
		}
	}
`;

export const LOGIN_AS_OTHER_USER_MUTATION = gql`
	mutation LoginAsOtherUser($input: LoginAsOtherUserInput!) {
		loginAsOtherUser(input: $input) {
			token
			refreshToken
		}
	}
`;

export const LOGIN_WITH_AZURE_AD_MUTATION = gql`
	mutation LoginWithAzureAd($input: LoginWithAzureAdInput!) {
		loginWithAzureAd(input: $input) {
			token
			refreshToken
		}
	}
`;

export const LOGOUT_AS_OTHER_USER_MUTATION = gql`
	mutation LogoutAsOtherUser($input: LogoutAsOtherUserInput!) {
		logoutAsOtherUser(input: $input) {
			revoked
		}
	}
`;

export const USERS_QUERY = gql`
	query UsersQuery {
		users {
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
			}
			edges {
				cursor
				node {
					...user
				}
			}
		}
	}
	${USER_FRAGMENT}
`;

// create
export const CREATE_USER_MUTATION = gql`
	mutation createUser($input: CreateUserInput!) {
		createUser(input: $input) {
			user {
				...user
			}
		}
	}
	${USER_FRAGMENT}
`;

// update
export const UPDATE_USER_MUTATION = gql`
	mutation updateUser($input: UpdateUserInput!) {
		updateUser(input: $input) {
			user {
				...user
			}
		}
	}
	${USER_FRAGMENT}
`;

// change password
export const CHANGE_PASSWORD_MUTATION = gql`
	mutation changePassword($input: ChangePasswordInput!) {
		changePassword(input: $input) {
			user {
				...user
			}
		}
	}
	${USER_FRAGMENT}
`;

// change my password
export const CHANGE_MY_PASSWORD_MUTATION = gql`
	mutation changeMyPassword($input: ChangeMyPasswordInput!) {
		changeMyPassword(input: $input) {
			user {
				...user
			}
		}
	}
	${USER_FRAGMENT}
`;

// delete
export const DELETE_USER_MUTATION = gql`
	mutation deleteUser($input: DeleteUserInput!) {
		deleteUser(input: $input) {
			user {
				...user
			}
		}
	}
	${USER_FRAGMENT}
`;

// delete multiple
export const DELETE_USERS_MUTATION = gql`
	mutation deleteUsers($input: DeleteUsersInput!) {
		deleteUsers(input: $input) {
			user {
				...user
			}
		}
	}
	${USER_FRAGMENT}
`;

// fragment
export const GROUP_FRAGMENT = gql`
	fragment group on GroupNode {
		id
		name
	}
`;

// all query
export const GROUPS_QUERY = gql`
	query {
		groups {
			edges {
				cursor
				node {
					...group
				}
			}
		}
	}
	${GROUP_FRAGMENT}
`;

// create
export const CREATE_GROUP_MUTATION = gql`
	mutation createGroup($input: CreateGroupInput!) {
		createGroup(input: $input) {
			group {
				...group
			}
		}
	}
	${GROUP_FRAGMENT}
`;

// update
export const UPDATE_GROUP_MUTATION = gql`
	mutation updateGroup($input: UpdateGroupInput!) {
		updateGroup(input: $input) {
			group {
				...group
			}
		}
	}
	${GROUP_FRAGMENT}
`;

// delete
export const DELETE_GROUP_MUTATION = gql`
	mutation deleteGroup($input: DeleteGroupInput!) {
		deleteGroup(input: $input) {
			group {
				...group
			}
		}
	}
	${GROUP_FRAGMENT}
`;
