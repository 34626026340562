var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center pl-3"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":"/"}}),_c('v-spacer')],1),_c('DeleteDialog',{attrs:{"dialog":_vm.deleteDialog},on:{"update:dialog":function($event){_vm.deleteDialog=$event},"confirmed":_vm.deletingSelectedUsersConfirmed},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("deleteDialog.title")))]},proxy:true},{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.$t("deleteDialog.content")))]},proxy:true}])}),_c('UserEditDialog',{attrs:{"object":_vm.editedItem,"dialog":_vm.userDialog},on:{"update:object":function($event){_vm.editedItem=$event},"update:dialog":function($event){_vm.userDialog=$event},"item-created":_vm.onUserCreated}}),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{class:{
					'mx-0 elevation-5': _vm.$vuetify.breakpoint.xsOnly,
					'mx-3 elevation-5': _vm.$vuetify.breakpoint.smAndUp,
				}},[_c('v-card-title',{staticClass:"pb-0"},[_c('v-icon',{staticClass:"mx-2",attrs:{"color":"primary"}},[_vm._v("contacts")]),_vm._v(" "+_vm._s(_vm.$t("users.title"))+" "),(_vm.me.isSuperuser)?_c('v-btn',{staticClass:"mr-5",attrs:{"icon":"","fab":"","small":""},on:{"click":function($event){return _vm.goToUsersSettings()}}},[_c('v-icon',[_vm._v("settings")])],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.searchUsers,"append-outer-icon":"refresh","label":_vm.$t('datatable.searchLabel'),"clearable":"","hide-details":""},on:{"input":_vm.handleSearch,"click:append-outer":function($event){return _vm.refreshCurrentPage()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.refreshCurrentPage()},"click:clear":function($event){return _vm.onClearSearch()}}})],1),_c('v-spacer'),(_vm.hasPermission('users.delete_customuser'))?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","dark":"","outlined":"","rounded":"","small":"","disabled":_vm.selectedUsers.length <= 0},on:{"click":_vm.deletingSelectedUsers}},[_vm._v(_vm._s(_vm.$t("users.deleteBtn")))]):_vm._e(),(_vm.hasPermission('users.add_customuser'))?_c('v-btn',{attrs:{"color":"primary","dark":"","outlined":"","rounded":"","small":""},on:{"click":_vm.addUser}},[_vm._v(_vm._s(_vm.$t("users.newBtn")))]):_vm._e()],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.me.isSuperuser ? _vm.headersSuperuser : _vm.headers,"loading":_vm.$apollo.queries.users.loading,"items":_vm.users.edges,"options":_vm.dataTableOptions,"server-items-length":_vm.users.totalCount,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{
							'items-per-page-options': [10, 25, 50, 75, 100],
							'disable-pagination':
								_vm.$apollo.queries.users.loading,
						},"item-key":"node.id","show-select":_vm.hasPermission('users.delete_customuser')
								? 'show-select'
								: undefined},on:{"update:options":function($event){_vm.dataTableOptions=$event}},scopedSlots:_vm._u([{key:`item.node.isSuperuser`,fn:function({ item }){return [(item.node.isSuperuser)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check_circle")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("cancel")])]}},{key:`item.node.require2fa`,fn:function({ item }){return [(item.node.require2fa)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check_circle")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("cancel")])]}},{key:`item.node.enabled2fa`,fn:function({ item }){return [(item.node.enabled2fa)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check_circle")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("cancel")])]}},{key:`item.action`,fn:function({ item }){return [(
									_vm.hasPermission('users.change_customuser')
								)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","loading":_vm.isLoggingInAsOtherUser,"disabled":_vm.isLoggedInAsOtherUser},on:{"click":function($event){return _vm.loginAsOtherUser(item.node.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mouse")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("users.loginAsOtherUser")))])]):_vm._e(),(
									_vm.hasPermission('users.change_customuser')
								)?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.editUser(item.node)}}},[_c('v-icon',[_vm._v("edit")])],1):_vm._e()]}}],null,true),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }