<template>
	<v-row justify="center">
		<!-- Tenant Dialog -->
		<TenantEditDialog
			:dialog.sync="tenantDialog"
			v-on:item-created="tenantAdded"
		></TenantEditDialog>

		<!-- add empty row to fit to page on mobile -->
		<v-row class="ma-0 pa-0"></v-row>

		<!-- Change Password -->
		<v-dialog v-model="passwordDialog" persistent max-width="500px">
			<v-card>
				<v-card-title class="primary white--text">
					{{ $t("userEditDialog.changePasswordTitle") }}
					<v-spacer></v-spacer>
				</v-card-title>

				<v-card-text class="pt-5">
					<v-form ref="passwordForm">
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.password"
									:counter="50"
									:rules="[
										rules.required,
										rules.minLength(8),
										rules.maxLength(50),
									]"
									v-bind:label="
										$t('userEditDialog.newPasswordLabel')
									"
									:type="showpassword ? 'text' : 'password'"
									outlined
									class="subtitle-2"
									hint="At least 8 characters"
									:append-icon="
										showpassword
											? 'visibility'
											: 'visibility_off'
									"
									@click:append="showpassword = !showpassword"
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn
						:disabled="isSavingPassword"
						text
						rounded
						@click="closePasswordDialog"
						>{{ $t("button.cancelBtn") }}</v-btn
					>
					<v-btn
						outlined
						rounded
						:loading="isSavingPassword"
						color="primary"
						@click="changePasswordConfirmed"
						@keydown.enter="changePasswordConfirmed"
						>{{ $t("button.saveBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Edit profile -->
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card>
				<v-alert v-if="error" tile type="error">{{ error }}</v-alert>

				<v-card-title class="primary white--text">
					{{ formTitle }}
					<v-spacer></v-spacer>
					<v-btn
						v-if="hasPermission('change_customuser') && object"
						outlined
						rounded
						small
						class="ml-2"
						dark
						@click="onChangePassword"
						>{{ $t("userEditDialog.changePasswordBtn") }}</v-btn
					>
				</v-card-title>

				<v-card-text class="pt-5">
					<v-form ref="form">
						<v-row>
							<!-- Tenant -->
							<v-col cols="12" v-if="me.isSuperuser">
								<v-autocomplete
									v-model="editedItem.tenantNodeId"
									:items="tenants.edges"
									item-text="node.tenantName"
									item-value="node.id"
									:loading="$apollo.queries.tenants.loading"
									:rules="[rules.required]"
									v-bind:label="
										$t('userEditDialog.tenantLabel')
									"
									class="subtitle-2"
									outlined
									clearable
								>
									<template
										v-if="me.isSuperuser"
										v-slot:append-outer
									>
										<v-btn text small tabindex="-1">
											<v-icon
												color="grey"
												@click="addTenant"
												tabindex="-1"
												>add</v-icon
											>
										</v-btn>
									</template>
								</v-autocomplete>
							</v-col>

							<v-col cols="6">
								<v-text-field
									v-model="editedItem.firstName"
									:counter="100"
									:rules="[
										rules.required,
										rules.maxLength(100),
									]"
									v-bind:label="
										$t('userEditDialog.firstNameLabel')
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.lastName"
									:counter="100"
									:rules="[
										rules.required,
										rules.maxLength(100),
									]"
									v-bind:label="
										$t('userEditDialog.lastNameLabel')
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.email"
									:counter="100"
									:rules="[
										rules.required,
										rules.maxLength(100),
									]"
									v-bind:label="
										$t('userEditDialog.emailLabel')
									"
									type="email"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>

							<!-- Identity provider -->
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.identityProvider"
									:items="identityProviders"
									item-text="name"
									item-value="id"
									:rules="[rules.required]"
									v-bind:label="
										$t(
											'userEditDialog.identityProviderLabel'
										)
									"
									class="subtitle-2"
									outlined
									clearable
								>
								</v-autocomplete>
							</v-col>

							<!-- groups -->
							<v-col v-if="!editedItem.isSuperuser" cols="12">
								<v-autocomplete
									v-model="editedItem.selectedGroups"
									:items="groups.edges"
									item-text="node.name"
									item-value="node.id"
									:loading="$apollo.queries.groups.loading"
									:rules="[rules.required]"
									v-bind:label="
										$t('userEditDialog.groupsLabel')
									"
									class="subtitle-2"
									outlined
									clearable
									multiple
								>
								</v-autocomplete>
							</v-col>
							<v-col cols="12" v-if="!object">
								<v-text-field
									v-model="editedItem.password"
									:counter="50"
									:rules="[
										rules.required,
										rules.minLength(8),
										rules.maxLength(50),
									]"
									v-bind:label="
										$t('userEditDialog.passwordLabel')
									"
									:type="showpassword ? 'text' : 'password'"
									outlined
									class="subtitle-2"
									hint="At least 8 characters"
									:append-icon="
										showpassword
											? 'visibility'
											: 'visibility_off'
									"
									@click:append="showpassword = !showpassword"
								></v-text-field>
							</v-col>
							<v-col v-if="me.isSuperuser" cols="6">
								<v-switch
									v-model="editedItem.isSuperuser"
									v-bind:label="
										$t('userEditDialog.isSuperuserLabel')
									"
									class="my-0 py-0"
									hide-details
								></v-switch>
							</v-col>
							<v-col
								cols="6"
								v-if="
									editedItem.identityProvider ==
										'INSURANCE_DATA'
								"
							>
								<v-switch
									v-model="editedItem.require2fa"
									v-bind:label="
										$t('userEditDialog.require2faLabel')
									"
									class="my-0 py-0"
									hide-details
								></v-switch>
							</v-col>
							<v-col
								v-if="
									!isNewUser &&
										editedItem.identityProvider ==
											'INSURANCE_DATA'
								"
								cols="12"
							>
								<v-row class="align-center">
									<v-col
										v-if="
											editedItem.enabled2fa &&
												!editedItem.clear2fa
										"
										cols="6"
										class="mb-0 pb-0"
									>
										<v-icon color="success"
											>verified_user</v-icon
										>
										{{
											$t("userEditDialog.2faEnabledText")
										}}
									</v-col>
									<v-col
										v-else-if="
											editedItem.enabled2fa &&
												editedItem.clear2fa
										"
										cols="6"
										class="mb-0 pb-0"
									>
										<v-icon color="error"
											>highlight_off</v-icon
										>
										{{ $t("userEditDialog.2faResetText") }}
									</v-col>
									<v-col v-else cols="6" class="mb-0 pb-0">
										<v-icon color="warning"
											>highlight_off</v-icon
										>
										{{ $t("userEditDialog.2faNotSetText") }}
									</v-col>
									<v-col
										v-if="editedItem.enabled2fa"
										cols="6"
										class="mb-0 pb-0"
									>
										<v-checkbox
											v-model="editedItem.clear2fa"
											color="error"
											v-bind:label="
												$t(
													'userEditDialog.2faResetLabel'
												)
											"
										></v-checkbox>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn
						:disabled="isSaving"
						text
						rounded
						@click="closeUserDialog"
						>{{ $t("button.cancelBtn") }}</v-btn
					>
					<v-btn
						outlined
						rounded
						:loading="isSaving"
						color="primary"
						@click="saveUser"
						>{{ $t("button.saveBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TenantEditDialog from "@/components/users/TenantEditDialog.vue";

export default {
	name: "user-edit-dialog",
	props: ["dialog", "object"],
	components: {
		TenantEditDialog,
	},

	apollo: {
		tenants: {
			query: gql`
				query tenants {
					tenants {
						edges {
							node {
								id
								tenantName
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.tenants,
			skip: true,
			pollInterval: 60000, // ms
		},

		groups: {
			query: gql`
				query groups {
					groups {
						edges {
							node {
								id
								name
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.groups,
			skip: true,
			pollInterval: 60000, // ms
		},
	},

	data() {
		return {
			error: null,
			isSaving: false,
			isSavingPassword: false,
			passwordDialog: false,
			tenantDialog: false,
			showpassword: false,

			tenants: {},
			groups: {},

			defaultItem: {
				id: null,
				email: null,
				password: Math.random()
					.toString(36)
					.slice(-10),
				firstName: null,
				lastName: null,
				isSuperuser: false,
				require2fa: true,
				enabled2fa: false,
				clear2fa: false,
				tenantNodeId: "",
				selectedGroups: [],
				identityProvider: null,
			},
			editedItem: {
				id: null,
				email: null,
				password: null,
				firstName: null,
				lastName: null,
				isSuperuser: false,
				require2fa: false,
				enabled2fa: false,
				clear2fa: false,
				tenantNodeId: "",
				selectedGroups: [],
				identityProvider: null,
			},

			identityProviders: [
				{
					id: "INSURANCE_DATA",
					name: "Insurance Data",
				},
				{
					id: "MICROSOFT_AZURE_AD",
					name: "Microsoft Azure AD",
				},
			],

			rules: {
				required: (v) => !helper.isEmpty(v) || "This field is required",
				minLength: (len) => (v) =>
					(v || "").length >= len ||
					`Invalid character length, required ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len || "Too long",
			},
		};
	},
	computed: {
		isNewUser() {
			return this.object ? false : true;
		},
		formTitle() {
			return this.object
				? this.$t("userEditDialog.editTitle")
				: this.$t("userEditDialog.newTitle");
		},
		me() {
			return this.$store.state.user.me;
		},
	},
	watch: {
		// reset form when dialog open or close
		dialog(val) {
			// if open dialog, initialize selections
			if (val) {
				this.$apollo.queries.tenants.skip = false;
				this.$apollo.queries.groups.skip = false;
			}

			this.resetForm();
		},
	},
	created() {
		this.hasPermission = helper.hasPermission;

		this.fetchMe();
	},
	methods: {
		fetchMe() {
			this.$store
				.dispatch("user/fetchMe")
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		resetForm() {
			// reset form state
			this.error = null;
			this.isSaving = false;
			this.panel = 0;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			// when editing, set form with object
			if (this.dialog && this.object) {
				this.editedItem.id = this.object.id;
				this.editedItem.email = this.object.email;
				this.editedItem.firstName = this.object.firstName;
				this.editedItem.lastName = this.object.lastName;
				this.editedItem.isSuperuser = this.object.isSuperuser;
				this.editedItem.require2fa = this.object.require2fa;
				this.editedItem.enabled2fa = this.object.enabled2fa;
				this.editedItem.clear2fa = false;
				this.editedItem.tenantNodeId = this.object.tenant.id;
				this.editedItem.selectedGroups = this.object.groups
					? this.object.groups.edges.map((item) => {
							return item.node.id;
					  })
					: [];
				this.editedItem.identityProvider = this.object.identityProvider;
			}
			// when new or closing, set form with default
			else {
				this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
			}
		},

		onChangePassword() {
			this.passwordDialog = true;
		},

		changePasswordConfirmed() {
			if (!this.$refs.passwordForm.validate()) {
				return;
			}

			// set form state
			this.error = null;
			this.isSavingPassword = true;

			// prepare api call payload
			var payload = {
				id: this.editedItem.id,
				newPassword: this.editedItem.password,
			};

			this.$store
				.dispatch("user/changePassword", payload)
				.then(() => {
					this.closePasswordDialog();

					// show snackbar
					const payload = {
						color: "success",
						message: `Password successfully changed`,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					console.log(error);

					this.error = error.graphQLErrors
						.map((error) => error.message)
						.join(", ");
				})
				.finally(() => {
					this.isSavingPassword = false;
				});
		},

		closePasswordDialog() {
			this.passwordDialog = false;
			this.editedItem.password = null;
			this.$refs.passwordForm.resetValidation();
		},

		closeUserDialog() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		saveUser() {
			if (!this.$refs.form.validate()) {
				return;
			}

			// set form state
			this.error = null;
			this.isSaving = true;

			// prepare api call payload
			var payload = {
				email: this.editedItem.email,
				firstName: this.editedItem.firstName,
				lastName: this.editedItem.lastName,
				isSuperuser: this.editedItem.isSuperuser,
				require2fa: this.editedItem.require2fa,
				tenantNodeId: this.editedItem.tenantNodeId,
				groups: this.editedItem.selectedGroups,
				identityProvider: this.editedItem.identityProvider,
			};

			// if editing
			if (this.object) {
				// prepare graphql mutation payload
				payload.id = this.editedItem.id;

				payload = {
					...payload,
					clear2fa: this.editedItem.clear2fa,
				};

				// update
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation updateUser($input: UpdateUserInput!) {
								updateUser(input: $input) {
									user {
										id
										email
										firstName
										lastName
										isSuperuser
										require2fa
										enabled2fa
										tenant {
											id
											tenantName
										}
										identityProvider
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// update parent object
						this.$emit("item-created", response);
						this.closeUserDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `User successfully edited`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
						this.isSaving = false;
					})
					.finally(() => {});
			}
			// new
			else {
				payload.password = this.editedItem.password;

				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation createUser($input: CreateUserInput!) {
								createUser(input: $input) {
									user {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						this.$emit("item-created", response);
						this.closeUserDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `User successfully added`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						console.log(error);

						this.error = error.graphQLErrors
							.map((error) => error.message)
							.join(", ");
						this.isSaving = false;
					})
					.finally(() => {});
			}
		},

		addTenant() {
			this.tenantDialog = true;
		},
		tenantAdded(item) {
			this.editedItem.tenantNodeId = item.id;
		},
	},
};
</script>
