var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('SeverityEditDialog',{attrs:{"dialog":_vm.severityDialog},on:{"update:dialog":function($event){_vm.severityDialog=$event},"item-created":_vm.severityAdded}}),_c('v-row',{staticClass:"ma-0 pa-0"}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.error)?_c('v-alert',{attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.formTitle)+" "),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pt-5"},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":100,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(100),
								],"label":_vm.$t('systemEditDialog.systemNameLabel'),"outlined":""},model:{value:(_vm.editedItem.systemName),callback:function ($$v) {_vm.$set(_vm.editedItem, "systemName", $$v)},expression:"editedItem.systemName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":100,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(255),
								],"label":_vm.$t('systemEditDialog.descriptionLabel'),"outlined":""},model:{value:(_vm.editedItem.systemDescription),callback:function ($$v) {_vm.$set(_vm.editedItem, "systemDescription", $$v)},expression:"editedItem.systemDescription"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"subtitle-2",attrs:{"items":_vm.severities.edges,"item-text":"node.severityName","item-value":"node.id","loading":_vm.$apollo.queries.severities.loading,"rules":[_vm.rules.required],"label":_vm.$t('systemEditDialog.severityLabel'),"outlined":"","clearable":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"text":"","small":"","tabindex":"-1"}},[_c('v-icon',{attrs:{"color":"grey","tabindex":"-1"},on:{"click":_vm.addSeverity}},[_vm._v("add")])],1)]},proxy:true}]),model:{value:(_vm.editedItem.severityNodeId),callback:function ($$v) {_vm.$set(_vm.editedItem, "severityNodeId", $$v)},expression:"editedItem.severityNodeId"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pb-5 pr-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isSaving,"text":"","rounded":""},on:{"click":_vm.closeSystemDialog}},[_vm._v(_vm._s(_vm.$t("button.cancelBtn")))]),_c('v-btn',{attrs:{"outlined":"","rounded":"","loading":_vm.isSaving,"color":"primary"},on:{"click":_vm.saveSystem}},[_vm._v(_vm._s(_vm.$t("button.saveBtn")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }