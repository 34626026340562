<template>
	<v-row justify="center">
		<!-- Edit system -->
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card>
				<v-alert v-if="error" tile type="error">{{ error }}</v-alert>

				<v-card-title class="primary white--text">
					{{ formTitle }}
					<v-spacer></v-spacer>
				</v-card-title>

				<v-card-text class="pt-5">
					<v-form ref="form">
						<v-row>
							<!-- Incident -->
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.incident.id"
									:items="incidents.edges"
									item-text="node.incidentName"
									item-value="node.id"
									:loading="$apollo.queries.incidents.loading"
									:rules="[rules.required]"
									v-bind:label="
										$t(
											'incidentUpdateEditDialog.incidentLabel'
										)
									"
									class="subtitle-2"
									outlined
									clearable
									disabled
								>
								</v-autocomplete>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.incidentUpdateName"
									:counter="100"
									:rules="[
										rules.required,
										rules.maxLength(100),
									]"
									v-bind:label="
										$t(
											'incidentUpdateEditDialog.incidentUpdateNameLabel'
										)
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="12">
								<v-textarea
									v-model="
										editedItem.incidentUpdateDescription
									"
									:counter="255"
									:rules="[
										rules.required,
										rules.maxLength(255),
									]"
									v-bind:label="
										$t(
											'incidentUpdateEditDialog.incidentUpdateDescriptionLabel'
										)
									"
									outlined
									class="subtitle-2"
								></v-textarea>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn
						:disabled="isSaving"
						text
						rounded
						@click="closeIncidentUpdateDialog"
						>{{ $t("button.cancelBtn") }}</v-btn
					>
					<v-btn
						outlined
						rounded
						:loading="isSaving"
						color="primary"
						@click="saveIncidentUpdate"
						>{{ $t("button.saveBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
	name: "incident-update-edit-dialog",
	props: ["dialog", "object", "parentNode"],
	components: {},

	apollo: {
		incidents: {
			query: gql`
				query incidents {
					incidents {
						edges {
							node {
								id
								incidentName
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.incidents,
			skip: true,
			pollInterval: 60000, // ms
		},
	},

	data() {
		return {
			error: null,
			isSaving: false,

			incidents: {},

			defaultItem: {
				id: null,
				incidentUpdateName: null,
				incidentUpdateDescription: null,
				incident: {
					id: null,
				},
			},
			editedItem: {
				id: null,
				incidentUpdateName: null,
				incidentUpdateDescription: null,
				incident: {
					id: null,
				},
			},
			rules: {
				required: (v) => !helper.isEmpty(v) || "This field is required",
				minLength: (len) => (v) =>
					(v || "").length >= len ||
					`Invalid character length, required ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len || "Too long",
			},
		};
	},
	computed: {
		formTitle() {
			return this.object
				? this.$t("incidentUpdateEditDialog.editTitle")
				: this.$t("incidentUpdateEditDialog.newTitle");
		},
		me() {
			return this.$store.state.user.me;
		},
	},
	watch: {
		// reset form when dialog open or close
		dialog(val) {
			// if open dialog, initialize selections
			if (val) {
				this.$apollo.queries.incidents.skip = false;
			}

			this.resetForm();
		},
	},
	created() {
		this.fetchMe();
	},
	methods: {
		fetchMe() {
			this.$store
				.dispatch("user/fetchMe")
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		resetForm() {
			// reset form state
			this.error = null;
			this.isSaving = false;
			this.panel = 0;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			// when editing, set form with object
			if (this.dialog && this.object) {
				this.editedItem.id = this.object.id;
				this.editedItem.incidentUpdateName = this.object.incidentUpdateName;
				this.editedItem.incidentUpdateDescription = this.object.incidentUpdateDescription;
				this.editedItem.incident.id = this.object.incident.id;
			}
			// when new or closing, set form with default
			else {
				this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
				this.editedItem.incident.id = this.parentNode.id;
			}
		},

		closeIncidentUpdateDialog() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		saveIncidentUpdate() {
			if (!this.$refs.form.validate()) {
				return;
			}

			// set form state
			this.error = null;
			this.isSaving = true;

			// prepare api call payload
			var payload = {
				incidentUpdateName: this.editedItem.incidentUpdateName,
				incidentUpdateDescription: this.editedItem
					.incidentUpdateDescription,
				incidentNodeId: this.editedItem.incident.id,
			};

			// if editing
			if (this.object) {
				// prepare graphql mutation payload
				payload.id = this.editedItem.id;

				// update
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation updateIncidentUpdate(
								$input: UpdateIncidentUpdateInput!
							) {
								updateIncidentUpdate(input: $input) {
									incidentUpdate {
										id
										createdAt
										modifiedAt
										incidentUpdateName
										incidentUpdateDescription
										isActive
										incident {
											id
											createdAt
											modifiedAt
											incidentName
											incidentDescription
											isResolved
										}
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// update parent object
						this.$emit("item-created", response);
						this.closeIncidentUpdateDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Incident update successfully edited`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
						this.isSaving = false;
					})
					.finally(() => {});
			}
			// new
			else {
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation createIncidentUpdate(
								$input: CreateIncidentUpdateInput!
							) {
								createIncidentUpdate(input: $input) {
									incidentUpdate {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						this.$emit("item-created", response);
						this.closeIncidentUpdateDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Incident update successfully added`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						console.log(error);

						this.error = error.graphQLErrors
							.map((error) => error.message)
							.join(", ");
						this.isSaving = false;
					})
					.finally(() => {});
			}
		},

		addSeverity() {
			this.severityDialog = true;
		},

		severityAdded(item) {
			this.editedItem.severityNodeId = item.id;
		},

		addSystem() {
			this.systemDialog = true;
		},

		systemAdded(item) {
			this.editedItem.systemNodeId = item.id;
		},
	},
};
</script>
