var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.error)?_c('v-alert',{attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.formTitle)+" "),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pt-5"},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":100,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(100),
								],"label":_vm.$t(
										'severityEditDialog.severityNameLabel'
									),"outlined":""},model:{value:(_vm.editedItem.severityName),callback:function ($$v) {_vm.$set(_vm.editedItem, "severityName", $$v)},expression:"editedItem.severityName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"rules":[_vm.rules.required],"label":_vm.$t('severityEditDialog.indexLabel'),"type":"number","outlined":""},model:{value:(_vm.editedItem.index),callback:function ($$v) {_vm.$set(_vm.editedItem, "index", $$v)},expression:"editedItem.index"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":50,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(50),
								],"label":_vm.$t('severityEditDialog.iconLabel'),"outlined":"","hint":"https://material.io/resources/icons/","persistent-hint":""},model:{value:(_vm.editedItem.icon),callback:function ($$v) {_vm.$set(_vm.editedItem, "icon", $$v)},expression:"editedItem.icon"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":50,"rules":[
									_vm.rules.required,
									_vm.rules.maxLength(50),
								],"label":_vm.$t('severityEditDialog.colorLabel'),"outlined":"","hint":"success, info, warning, error","persistent-hint":""},model:{value:(_vm.editedItem.color),callback:function ($$v) {_vm.$set(_vm.editedItem, "color", $$v)},expression:"editedItem.color"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pb-5 pr-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isSaving,"text":"","rounded":""},on:{"click":_vm.closeSeverityDialog}},[_vm._v(_vm._s(_vm.$t("button.cancelBtn")))]),_c('v-btn',{attrs:{"outlined":"","rounded":"","loading":_vm.isSaving,"color":"primary"},on:{"click":_vm.saveSeverity}},[_vm._v(_vm._s(_vm.$t("button.saveBtn")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }