<template>
	<v-row justify="center">
		<!-- KnowledgeBaseType Dialog -->
		<KnowledgeBaseTypeEditDialog
			:dialog.sync="knowledgeBaseTypeDialog"
			v-on:item-created="knowledgeBaseTypeAdded"
		></KnowledgeBaseTypeEditDialog>

		<!-- add empty row to fit to page on mobile -->
		<v-row class="ma-0 pa-0"></v-row>

		<!-- Edit system -->
		<v-dialog v-model="dialog" persistent max-width="1200px">
			<v-card>
				<v-alert v-if="error" tile type="error">{{ error }}</v-alert>

				<v-card-title class="primary white--text">
					{{ formTitle }}
					<v-spacer></v-spacer>
				</v-card-title>

				<v-card-text class="pt-5">
					<v-form ref="form">
						<v-row>
							<v-col cols="8">
								<v-text-field
									v-model="editedItem.knowledgeBaseTitle"
									:counter="100"
									:rules="[
										rules.required,
										rules.maxLength(100),
									]"
									v-bind:label="
										$t(
											'knowledgeBaseEditDialog.knowledgeBaseItemTitleLabel'
										)
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<!-- Type -->
							<v-col cols="4">
								<v-autocomplete
									v-model="editedItem.knowledgeBaseTypeNodeId"
									:items="knowledgeBaseTypes.edges"
									item-text="node.knowledgeBaseTypeName"
									item-value="node.id"
									:loading="
										$apollo.queries.knowledgeBaseTypes
											.loading
									"
									:rules="[rules.required]"
									v-bind:label="
										$t('knowledgeBaseEditDialog.typeLabel')
									"
									class="subtitle-2"
									outlined
									clearable
								>
									<template v-slot:append-outer>
										<v-btn text small tabindex="-1">
											<v-icon
												color="grey"
												@click="addKnowledgeBaseType"
												tabindex="-1"
												>add</v-icon
											>
										</v-btn>
									</template>
								</v-autocomplete>
							</v-col>
							<!-- Notes -->
							<v-col cols="5">
								<v-textarea
									v-model="
										editedItem.knowledgeBaseDescription
									"
									v-bind:label="
										$t(
											'knowledgeBaseEditDialog.descriptionLabel'
										)
									"
									outlined
									class="subtitle-2"
									rows="7"
									:counter="255"
									:rules="[
										rules.required,
										rules.maxLength(255),
									]"
								></v-textarea>
							</v-col>
							<v-col cols="4">
								<v-file-input
									v-model="selectedImage"
									outlined
									small-chips
									v-bind:label="
										$t(
											'knowledgeBaseEditDialog.avatarLabel'
										)
									"
									prepend-icon=""
									accept="image/*"
									@change="onFileChange"
								></v-file-input>
								<v-switch
									v-model="editedItem.hasVideo"
									v-bind:label="
										$t(
											'knowledgeBaseEditDialog.hasVideoLabel'
										)
									"
									class="my-0 py-0 mb-7"
									hide-details
								></v-switch>
								<v-text-field
									v-if="editedItem.hasVideo"
									v-model="editedItem.videoUrl"
									:counter="100"
									:rules="[rules.maxLength(100)]"
									v-bind:label="
										$t(
											'knowledgeBaseEditDialog.youtubeVideoUrlLabel'
										)
									"
									outlined
									class="subtitle-2"
								></v-text-field>
							</v-col>
							<v-col cols="2">
								<v-avatar size="125" tile>
									<v-img
										:src="imageUrl"
										style="border: 1px dashed #ccc"
										contain
									>
									</v-img>
								</v-avatar>
							</v-col>
							<v-col cols="12">
								<div>
									<!-- Use the component in the right place of the template -->
									<tiptap-vuetify
										v-model="content"
										:extensions="extensions"
									/>
								</div>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn
						:disabled="isSaving"
						text
						rounded
						@click="closeKnowledgeBaseDialog"
						>{{ $t("button.cancelBtn") }}</v-btn
					>
					<v-btn
						outlined
						rounded
						:loading="isSaving"
						color="primary"
						@click="saveKnowledgeBase"
						>{{ $t("button.saveBtn") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import KnowledgeBaseTypeEditDialog from "@/components/status/KnowledgeBaseTypeEditDialog.vue";
import {
	TiptapVuetify,
	Heading,
	Bold,
	Italic,
	Strike,
	Underline,
	Code,
	Paragraph,
	BulletList,
	OrderedList,
	ListItem,
	Link,
	Blockquote,
	HardBreak,
	HorizontalRule,
	History,
} from "tiptap-vuetify";

export default {
	name: "knowledge-base-edit-dialog",
	props: ["dialog", "object"],
	components: { TiptapVuetify, KnowledgeBaseTypeEditDialog },

	apollo: {
		knowledgeBaseTypes: {
			query: gql`
				query knowledgeBaseTypes {
					knowledgeBaseTypes {
						edges {
							node {
								id
								knowledgeBaseTypeName
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.knowledgeBaseTypes,
			skip: true,
			pollInterval: 60000, // ms
		},
	},

	data() {
		return {
			error: null,
			isSaving: false,
			knowledgeBaseTypeDialog: false,
			selectedImage: null,
			imageUrl: null,

			knowledgeBaseTypes: {},

			defaultItem: {
				id: null,
				knowledgeBaseTitle: null,
				knowledgeBaseDescription: null,
				knowledgeBaseTypeNodeId: null,
				knowledgeBaseContent: null,
				hasVideo: false,
				videoUrl: null,
				knowledgeBaseAvatarPath: null,
			},
			editedItem: {
				id: null,
				knowledgeBaseTitle: null,
				knowledgeBaseDescription: null,
				knowledgeBaseTypeNodeId: null,
				knowledgeBaseContent: null,
				hasVideo: false,
				videoUrl: null,
				knowledgeBaseAvatarPath: null,
			},

			rules: {
				required: (v) => !helper.isEmpty(v) || "This field is required",
				minLength: (len) => (v) =>
					(v || "").length >= len ||
					`Invalid character length, required ${len}`,
				maxLength: (len) => (v) =>
					(v || "").length <= len || "Too long",
			},

			extensions: [
				History,
				Blockquote,
				Link,
				Underline,
				Strike,
				Italic,
				ListItem,
				BulletList,
				OrderedList,
				[
					Heading,
					{
						options: {
							levels: [1, 2, 3],
						},
					},
				],
				Bold,
				Code,
				HorizontalRule,
				Paragraph,
				HardBreak,
			],
			// starting editor's content
			content: "",
		};
	},
	computed: {
		formTitle() {
			return this.object
				? this.$t("knowledgeBaseEditDialog.editTitle")
				: this.$t("knowledgeBaseEditDialog.newTitle");
		},
		me() {
			return this.$store.state.user.me;
		},
	},
	watch: {
		// reset form when dialog open or close
		dialog(val) {
			// if open dialog, initialize selections
			if (val) {
				this.$apollo.queries.knowledgeBaseTypes.skip = false;
			}

			this.resetForm();
		},
	},
	created() {
		this.fetchMe();
	},
	methods: {
		fetchMe() {
			this.$store
				.dispatch("user/fetchMe")
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		resetForm() {
			// reset form state
			this.error = null;
			this.isSaving = false;
			this.panel = 0;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			// when editing, set form with object
			if (this.dialog && this.object) {
				this.editedItem.id = this.object.id;
				this.editedItem.knowledgeBaseTitle = this.object.knowledgeBaseTitle;
				this.editedItem.knowledgeBaseDescription = this.object.knowledgeBaseDescription;
				this.editedItem.knowledgeBaseTypeNodeId = this.object.knowledgeBaseType.id;
				this.content = this.object.knowledgeBaseContent;
				this.editedItem.hasVideo = this.object.hasVideo;
				this.editedItem.videoUrl = this.object.videoUrl;
				this.selectedImage = new File(
					["foo"],
					this.object.knowledgeBaseAvatarPath,
					{
						type: "text/plain",
					}
				);
				this.imageUrl = this.object.knowledgeBaseAvatarPath;
			}
			// when new or closing, set form with default
			else {
				this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
				this.content = "";
				this.selectedImage = null;
				this.imageUrl = null;
			}
		},

		closeKnowledgeBaseDialog() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		createImage(file) {
			const reader = new FileReader();

			reader.onload = (e) => {
				this.imageUrl = e.target.result;
			};
			reader.readAsDataURL(file);
		},

		onFileChange(file) {
			if (!file) {
				this.imageUrl = null;
			}
			this.createImage(file);
		},

		saveKnowledgeBase() {
			if (!this.$refs.form.validate()) {
				return;
			}

			// set form state
			this.error = null;
			this.isSaving = true;

			// prepare api call payload
			var payload = {
				knowledgeBaseTitle: this.editedItem.knowledgeBaseTitle,
				knowledgeBaseDescription: this.editedItem
					.knowledgeBaseDescription,
				knowledgeBaseTypeNodeId: this.editedItem
					.knowledgeBaseTypeNodeId,
				knowledgeBaseContent: this.content,
				hasVideo: this.editedItem.hasVideo,
				videoUrl: this.editedItem.videoUrl,
				avatar: this.imageUrl,
			};

			// if editing
			if (this.object) {
				// prepare graphql mutation payload
				payload.id = this.editedItem.id;

				// update
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation updateKnowledgeBase(
								$input: UpdateKnowledgeBaseInput!
							) {
								updateKnowledgeBase(input: $input) {
									knowledgeBase {
										id
										createdAt
										modifiedAt
										knowledgeBaseTitle
										knowledgeBaseDescription
										knowledgeBaseType {
											id
											knowledgeBaseTypeName
										}
										knowledgeBaseContent
										hasVideo
										videoUrl
										knowledgeBaseAvatarPath
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// update parent object
						this.$emit("update:object", response);
						this.closeKnowledgeBaseDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Knowledge Base successfully edited`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
						this.isSaving = false;

						// show snackbar
						const payload = {
							color: "error",
							message: this.error,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.finally(() => {});
			}
			// new
			else {
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation createKnowledgeBase(
								$input: CreateKnowledgeBaseInput!
							) {
								createKnowledgeBase(input: $input) {
									knowledgeBase {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						this.$emit(
							"item-created",
							response.data.createKnowledgeBase.knowledgeBase
						);
						this.closeKnowledgeBaseDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Knowledge Base successfully added`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						console.log(error);

						this.error = error.graphQLErrors
							.map((error) => error.message)
							.join(", ");
						this.isSaving = false;

						// show snackbar
						const payload = {
							color: "error",
							message: this.error,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.finally(() => {});
			}
		},

		addKnowledgeBaseType() {
			this.knowledgeBaseTypeDialog = true;
		},

		knowledgeBaseTypeAdded(item) {
			this.$apollo.queries.knowledgeBaseTypes.refresh();
			this.editedItem.knowledgeBaseTypeNodeId = item.id;
		},
	},
};
</script>
