var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center pl-3"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":"/"}})],1),_c('DeleteDialog',{attrs:{"dialog":_vm.deleteDialog},on:{"update:dialog":function($event){_vm.deleteDialog=$event},"confirmed":_vm.deletingSelectedKnowledgeBaseTypesConfirmed},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("deleteDialog.title")))]},proxy:true},{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.$t("deleteDialog.content")))]},proxy:true}])}),_c('KnowledgeBaseTypeEditDialog',{attrs:{"object":_vm.editedKnowledgeBaseType,"dialog":_vm.knowledgeBaseTypeDialog},on:{"update:object":function($event){_vm.editedKnowledgeBaseType=$event},"update:dialog":function($event){_vm.knowledgeBaseTypeDialog=$event},"item-created":_vm.onKnowledgeBaseTypeCreated}}),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{class:{
					'mx-0 elevation-5': _vm.$vuetify.breakpoint.xsOnly,
					'mx-3 elevation-5': _vm.$vuetify.breakpoint.smAndUp,
				}},[_c('v-tabs',{attrs:{"centered":"","show-arrows":""}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),(_vm.me.isSuperuser)?_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v("error")]),_vm._v(_vm._s(_vm.$t( "knowledgeBaseSettings.knowledgeBaseTypes.title" )))],1):_vm._e(),(_vm.me.isSuperuser)?_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pb-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}}),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","append-outer-icon":"refresh","label":_vm.$t('datatable.searchLabel'),"clearable":"","hide-details":""},on:{"click:append-outer":function($event){return _vm.refreshKnowledgeBaseTypesCurrentPage()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.refreshKnowledgeBaseTypesCurrentPage()},"click:clear":function($event){return _vm.onClearKnowledgeBaseTypesSearch()}},model:{value:(_vm.searchKnowledgeBaseType),callback:function ($$v) {_vm.searchKnowledgeBaseType=$$v},expression:"searchKnowledgeBaseType"}})],1),_c('v-spacer'),(_vm.me.isSuperuser)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","dark":"","outlined":"","rounded":"","small":"","disabled":_vm.selectedKnowledgeBaseTypes.length <=
											0},on:{"click":function($event){return _vm.deletingSelectedKnowledgeBaseTypes()}}},[_vm._v("Delete Type")]):_vm._e(),(_vm.me.isSuperuser)?_c('v-btn',{attrs:{"color":"primary","dark":"","outlined":"","rounded":"","small":""},on:{"click":function($event){return _vm.addKnowledgeBaseType()}}},[_vm._v("New Type")]):_vm._e()],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.knowledgeBaseTypesHeaders,"loading":_vm.$apollo.queries.knowledgeBaseTypes
											.loading,"items":_vm.knowledgeBaseTypes.edges,"options":_vm.knowledgeBaseTypesDataTableOptions,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{
										'items-per-page-options': [
											10,
											25,
											50,
											75,
											100,
										],
										'disable-pagination':
											_vm.$apollo.queries
												.knowledgeBaseTypes.loading,
									},"item-key":"node.id","show-select":_vm.me.isSuperuser
											? 'show-select'
											: undefined},on:{"update:options":function($event){_vm.knowledgeBaseTypesDataTableOptions=$event}},scopedSlots:_vm._u([{key:`item.action`,fn:function({ item }){return [(_vm.me.isSuperuser)?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.editKnowledgeBaseType(
													item.node
												)}}},[_c('v-icon',[_vm._v("edit")])],1):_vm._e()]}}],null,true),model:{value:(_vm.selectedKnowledgeBaseTypes),callback:function ($$v) {_vm.selectedKnowledgeBaseTypes=$$v},expression:"selectedKnowledgeBaseTypes"}})],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }