<template>
	<v-container fluid>
		<v-row class="align-center pl-3">
			<!-- Breadcrumbs -->
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
			<v-spacer />
		</v-row>

		<!-- KnowledgeBase Dialog-->
		<KnowledgeBaseEditDialog
			:object.sync="editedItem"
			:dialog.sync="newKnowledgeBaseDialog"
			v-on:item-created="onKnowledgeBaseCreated"
		></KnowledgeBaseEditDialog>

		<!-- Knowledge Base Dialog -->
		<v-row>
			<v-dialog v-model="knowledgeBaseDialog" scrollable width="75%">
				<v-card tile>
					<v-toolbar flat dark color="primary">
						<v-btn icon dark @click="closeKnowledgeBaseDialog">
							<v-icon>close</v-icon>
						</v-btn>
						<v-toolbar-title>{{
							$t("knowledgeBase.title")
						}}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn
							icon
							dark
							@click="
								goToKnowledgeBaseDetail(
									selectedKnowledgeBaseNodeId
								)
							"
						>
							<v-icon>launch</v-icon>
						</v-btn>
					</v-toolbar>
					<v-card-text class="mx-0 px-0">
						<KnowledgeBaseDetailComponent
							v-if="selectedKnowledgeBaseNodeId"
							:knowledgeBaseNodeId="selectedKnowledgeBaseNodeId"
							v-on:knowledgeBase-items-updated="
								refreshCurrentPage
							"
							v-on:item-deleted="onKnowledgeBaseDeleted"
						></KnowledgeBaseDetailComponent>
					</v-card-text>
				</v-card>
			</v-dialog>
		</v-row>

		<v-row>
			<v-col cols="12" class="pt-0">
				<v-card
					:class="{
						'mx-0 elevation-5': $vuetify.breakpoint.xsOnly,
						'mx-3 elevation-5': $vuetify.breakpoint.smAndUp,
					}"
					:loading="$apollo.queries.knowledgeBases.loading"
				>
					<!-- Header -->
					<v-card-title class="pb-0">
						<v-icon color="primary" class="mx-2">info</v-icon
						>{{ $t("knowledgeBase.title") }}
						<v-btn
							v-if="me.isSuperuser"
							icon
							fab
							small
							class="mr-5"
							@click="goToKnowledgeBaseSettings()"
						>
							<v-icon>settings</v-icon>
						</v-btn>
						<v-spacer></v-spacer>
						<v-col cols="12" sm="4"> </v-col>
						<v-spacer></v-spacer
						><v-btn
							v-if="me.isSuperuser"
							color="primary"
							dark
							outlined
							rounded
							small
							@click="addKnowledgeBase"
							>{{ $t("knowledgeBase.newBtn") }}</v-btn
						>
					</v-card-title>

					<v-card-text>
						<v-row class="mt-1">
							<!-- Filters -->
							<v-col cols="12" md="2">
								<v-card
									:class="{
										'mx-0 elevation-5':
											$vuetify.breakpoint.xsOnly,
										'mx-3 elevation-5':
											$vuetify.breakpoint.smAndUp,
									}"
								>
									<v-list-item-group
										v-model="selectedFilter"
										@change="refreshCurrentPage"
									>
										<v-list-item
											v-for="knowledgeBaseType in knowledgeBaseTypes.edges"
											:key="knowledgeBaseType.node.id"
											:value="
												knowledgeBaseType.node
													.knowledgeBaseTypeName
											"
										>
											<v-list-item-content>
												<v-list-item-title
													v-text="
														knowledgeBaseType.node
															.knowledgeBaseTypeName
													"
												></v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</v-list-item-group>
								</v-card>
							</v-col>

							<v-col cols="12" md="10">
								<!-- The avatar and such only apply to md and up screens -->
								<v-card
									v-if="$vuetify.breakpoint.smAndUp"
									class="mx-3 elevation-5"
								>
									<v-row
										class="mt-0"
										justify="center"
										align="center"
									>
										<v-col cols="12" sm="6">
											<v-text-field
												prepend-inner-icon="search"
												solo
												v-model="search"
												append-outer-icon="refresh"
												@click:append-outer="
													refreshCurrentPage()
												"
												v-bind:placeholder="
													$t('datatable.searchLabel')
												"
												@keydown.enter="
													refreshCurrentPage()
												"
												@click:clear="onClearSearch()"
												clearable
											></v-text-field>
										</v-col>
									</v-row>
									<v-container>
										<v-row>
											<v-virtual-scroll
												:items="
													knowledgeBases
														? knowledgeBases.edges
														: []
												"
												:item-height="160"
												height="600"
											>
												<template
													v-slot:default="{
														index,
														item,
													}"
												>
													<v-col
														:key="item.node.id"
														cols="12"
													>
														<v-card
															@click="
																openKnowledgeBaseDialog(
																	item.node
																)
															"
														>
															<div
																class="d-flex flex-no-wrap"
															>
																<v-avatar
																	class="ma-3"
																	size="125"
																	tile
																>
																	<v-img
																		:src="
																			item
																				.node
																				.knowledgeBaseAvatarPath
																		"
																		contain
																	></v-img>
																</v-avatar>
																<div>
																	<v-card-title
																		class="headline"
																		v-text="
																			item
																				.node
																				.knowledgeBaseTitle
																		"
																	>
																	</v-card-title>

																	<v-card-subtitle
																		>{{
																			item
																				.node
																				.createdAt
																				| moment(
																					"from"
																				)
																		}}
																		-
																		{{
																			item
																				.node
																				.knowledgeBaseType
																				.knowledgeBaseTypeName
																		}}</v-card-subtitle
																	>
																	<v-card-text>
																		{{
																			item
																				.node
																				.knowledgeBaseDescription
																		}}
																	</v-card-text>
																</div>
															</div>
														</v-card>
													</v-col>
													<!-- infinit loading trigger -->
													<v-card
														tile
														v-if="
															index ==
																knowledgeBases
																	.edges
																	.length -
																	1
														"
														v-intersect="
															loadMoreKnowledgeBases
														"
														class="elevation-0"
													>
														<v-card-text
															v-if="
																$apollo.queries
																	.knowledgeBases
																	.loading
															"
															class="text-center"
														>
															<v-progress-circular
																indeterminate
																color="primary"
															></v-progress-circular>
														</v-card-text>
													</v-card>
												</template>
											</v-virtual-scroll>
										</v-row>
									</v-container>
								</v-card>

								<!-- Mobile vcard -->
								<v-card
									v-if="$vuetify.breakpoint.xsOnly"
									class="mx-0 elevation-5"
									:loading="
										$apollo.queries.knowledgeBases.loading
									"
								>
									<v-card-title class="pb-0">
										<v-col cols="12" sm="4">
											<v-text-field
												dense
												v-model="search"
												append-outer-icon="refresh"
												@click:append-outer="
													refreshCurrentPage()
												"
												v-bind:label="
													$t('datatable.searchLabel')
												"
												clearable
												hide-details
												@keydown.enter="
													refreshCurrentPage()
												"
												@click:clear="onClearSearch()"
											></v-text-field>
										</v-col>
									</v-card-title>
									<v-container>
										<v-row>
											<v-virtual-scroll
												:items="
													knowledgeBases
														? knowledgeBases.edges
														: []
												"
												:item-height="80"
												height="600"
											>
												<template
													v-slot:default="{
														index,
														item,
													}"
												>
													<v-list-item
														three-line
														dense
													>
														<v-list-item-content
															class="row-pointer"
															@click="
																goToKnowledgeBaseDetail(
																	item.node.id
																)
															"
														>
															<v-list-item-title
																v-text="
																	item.node
																		.knowledgeBaseTitle
																"
															></v-list-item-title>

															<v-list-item-subtitle
																>{{
																	item.node
																		.createdAt
																		| moment(
																			"from"
																		)
																}}
																-
																{{
																	item.node
																		.knowledgeBaseType
																		.knowledgeBaseTypeName
																}}</v-list-item-subtitle
															>

															<v-list-item-subtitle
																v-text="
																	item.node
																		.knowledgeBaseDescription
																"
															>
																></v-list-item-subtitle
															>
														</v-list-item-content>
													</v-list-item>
													<!-- infinit loading trigger -->
													<v-card
														tile
														v-if="
															index ==
																knowledgeBases
																	.edges
																	.length -
																	1
														"
														v-intersect="
															loadMoreKnowledgeBases
														"
														class="elevation-0"
													>
														<v-card-text
															v-if="
																$apollo.queries
																	.knowledgeBases
																	.loading
															"
															class="text-center"
														>
															<v-progress-circular
																indeterminate
																color="primary"
															></v-progress-circular>
														</v-card-text>
													</v-card>
												</template>
											</v-virtual-scroll>
										</v-row>
									</v-container>
								</v-card>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped>
.row-pointer {
	cursor: pointer;
}
</style>

<script>
import gql from "graphql-tag";
import KnowledgeBaseEditDialog from "@/components/status/KnowledgeBaseEditDialog.vue";
import KnowledgeBaseDetailComponent from "@/components/status/KnowledgeBaseDetailComponent.vue";

export default {
	name: "user-list-page",
	components: {
		KnowledgeBaseEditDialog,
		KnowledgeBaseDetailComponent,
	},
	apollo: {
		knowledgeBases: {
			query: gql`
				query knowledgeBases(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$search: String
					$orderBy: String
					$knowledgeBaseDescription_Icontains: String
					$knowledgeBaseDescription_Iexact: String
					$knowledgeBaseTitle_Icontains: String
					$knowledgeBaseTitle_Iexact: String
					$knowledgeBaseType_KnowledgeBaseTypeName_Icontains: String
					$knowledgeBaseType_KnowledgeBaseTypeName_Iexact: String
				) {
					knowledgeBases(
						first: $first
						last: $last
						before: $before
						after: $after
						search: $search
						orderBy: $orderBy
						knowledgeBaseDescription_Icontains: $knowledgeBaseDescription_Icontains
						knowledgeBaseDescription_Iexact: $knowledgeBaseDescription_Iexact
						knowledgeBaseTitle_Icontains: $knowledgeBaseTitle_Icontains
						knowledgeBaseTitle_Iexact: $knowledgeBaseTitle_Iexact
						knowledgeBaseType_KnowledgeBaseTypeName_Icontains: $knowledgeBaseType_KnowledgeBaseTypeName_Icontains
						knowledgeBaseType_KnowledgeBaseTypeName_Iexact: $knowledgeBaseType_KnowledgeBaseTypeName_Iexact
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								id
								createdAt
								modifiedAt
								knowledgeBaseTitle
								knowledgeBaseDescription
								knowledgeBaseContent
								knowledgeBaseAvatarPath
								hasVideo
								videoUrl
								knowledgeBaseType {
									id
									knowledgeBaseTypeName
								}
							}
						}
					}
				}
			`,
			variables() {
				return {
					first: 10,
				};
			},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.knowledgeBases,
			skip: true,
			pollInterval: 60000, // ms
		},

		knowledgeBaseTypes: {
			query: gql`
				query knowledgeBaseTypes(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$search: String
					$orderBy: String
				) {
					knowledgeBaseTypes(
						first: $first
						last: $last
						before: $before
						after: $after
						search: $search
						orderBy: $orderBy
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								id
								knowledgeBaseTypeName
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.knowledgeBaseTypes,
			pollInterval: 60000, // ms
		},
	},
	data: function() {
		return {
			search: "",
			selectedFilter: null,
			editedItem: {},
			knowledgeBaseDialog: false,
			newKnowledgeBaseDialog: false,
			selectedKnowledgeBaseNodeId: null,

			first: 10,
			pageSize: 10,

			knowledgeBaseTypes: {},
			knowledgeBases: {},

			filterItems: [
				{
					text: "Admin",
				},
				{
					text: "Basic",
				},
			],
		};
	},
	computed: {
		me() {
			return this.$store.state.user.me;
		},

		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("knowledgeBase.breadcrumbs.home"),
					disable: false,
					href: "/#/",
				},
				{
					text: this.$t("knowledgeBase.breadcrumbs.knowledgeBase"),
					disabled: true,
				},
			];
			return breadcrumbs;
		},
	},
	watch: {},
	created() {
		this.fetchMe();
		this.$apollo.queries.knowledgeBases.skip = false;
	},
	mounted() {},
	methods: {
		fetchMe() {
			this.$store
				.dispatch("user/fetchMe")
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		goToKnowledgeBaseSettings() {
			this.$router.push({
				name: "knowledge-base-settings",
			});
		},

		onClearSearch() {
			this.search = "";

			this.refreshCurrentPage();
		},

		refreshCurrentPage() {
			var _selectedFilter = "";
			if (this.selectedFilter == null) {
				_selectedFilter = "";
			} else {
				_selectedFilter = this.selectedFilter;
			}
			this.$apollo.queries.knowledgeBases.setVariables({
				first: this.first,
				search: this.search,
				knowledgeBaseType_KnowledgeBaseTypeName_Iexact: _selectedFilter,
			});
			this.$apollo.queries.knowledgeBases.skip = true;
			this.$apollo.queries.knowledgeBases.refresh();
			this.$apollo.queries.knowledgeBases.skip = false;
		},

		// system
		addKnowledgeBase() {
			this.editedItem = null;
			this.newKnowledgeBaseDialog = true;
		},

		openKnowledgeBaseDialog(knowledgeBase) {
			this.selectedKnowledgeBaseNodeId = knowledgeBase.id;
			this.knowledgeBaseDialog = true;
		},

		closeKnowledgeBaseDialog() {
			this.knowledgeBaseDialog = false;
			this.selectedKnowledgeBaseNodeId = null;
			this.$apollo.queries.knowledgeBases.refresh();
		},

		onKnowledgeBaseCreated(response) {
			this.newKnowledgeBaseDialog = false;
			this.$apollo.queries.knowledgeBases.refresh();
			this.refreshCurrentPage();
			this.goToKnowledgeBaseDetail(response.id);
		},

		onKnowledgeBaseDeleted() {
			// this.refreshCurrentPage();
			this.closeKnowledgeBaseDialog();
		},

		goToKnowledgeBaseDetail(knowledgeBaseNodeId) {
			let routeData = this.$router.resolve({
				name: "knowledge-base-detail",
				params: { id: knowledgeBaseNodeId },
			});
			window.open(routeData.href, "_blank");
		},

		async loadMoreKnowledgeBases() {
			if (
				this.knowledgeBases &&
				this.knowledgeBases.pageInfo.hasNextPage
			) {
				this.$apollo.queries.knowledgeBases.skip = false;

				this.$apollo.queries.knowledgeBases.fetchMore({
					// New variables
					variables: {
						after: this.knowledgeBases.pageInfo.endCursor,
					},
				});
			}
		},
	},
};
</script>
