<template>
	<v-container fluid>
		<v-row class="align-center pl-3">
			<!-- Breadcrumbs -->
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
			<v-spacer />
		</v-row>

		<KnowledgeBaseDetailComponent
			:knowledgeBaseNodeId="$route.params.id"
			v-on:item-fetched="onKnowledgeBaseFetched"
			v-on:item-deleted="onKnowledgeBaseDeleted"
		></KnowledgeBaseDetailComponent>
	</v-container>
</template>

<script>
import KnowledgeBaseDetailComponent from "@/components/status/KnowledgeBaseDetailComponent.vue";

export default {
	name: "knowledge-base-detail-page",
	components: { KnowledgeBaseDetailComponent },
	data() {
		return {
			knowledgeBase: {},
		};
	},
	computed: {
		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("knowledgeBaseDetail.breadcrumbs.home"),
					disable: false,
					href: "/#/",
				},
				{
					text: this.$t(
						"knowledgeBaseDetail.breadcrumbs.knowledgeBase"
					),
					disable: false,
					href: "/#/knowledge-base",
				},
				{
					text: this.knowledgeBase.knowledgeBaseTitle,
					disabled: true,
				},
			];
			return breadcrumbs;
		},
	},
	watch: {},
	created() {},
	methods: {
		onKnowledgeBaseFetched(response) {
			this.knowledgeBase = response;
		},
		onKnowledgeBaseDeleted() {
			// redirect to quotations list page
			this.$router.push("/knowledge-base");
		},
	},
};
</script>
