var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1100px","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.error)?_c('v-alert',{attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.formTitle)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeMainDialog}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-step',{attrs:{"step":"1","complete":_vm.step > 1}},[(_vm.selectedGroup === null)?_c('span',[_vm._v(_vm._s(_vm.$t("reportAddDialog.stepper.step1.title")))]):_vm._e(),(_vm.selectedGroup)?_c('span',{staticClass:"grey--text text--darken-4 mb-4"},[_vm._v(_vm._s(_vm.selectedGroup ? _vm.selectedGroup.name : ""))]):_vm._e(),(_vm.selectedGroup)?_c('v-row',{staticClass:"px-2"},[_c('v-chip',{staticClass:"mr-1 mb-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.selectedGroup ? _vm.selectedGroup.id : "")+" ")]),_c('v-spacer')],1):_vm._e()],1),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-form',{ref:"formStep1"},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-outer-icon":"search","label":_vm.$t('datatable.searchLabel'),"clearable":"","hide-details":""},model:{value:(_vm.searchGroup),callback:function ($$v) {_vm.searchGroup=$$v},expression:"searchGroup"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headersGroup,"loading":_vm.isLoadingGroups,"items":_vm.groups,"item-key":"id","search":_vm.searchGroup,"sort-by":"name"},scopedSlots:_vm._u([{key:`item.action`,fn:function({
													item,
												}){return [_c('v-btn',{attrs:{"small":"","outlined":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.onGroupSelected(
															item
														)}}},[_vm._v(_vm._s(_vm.$t( "button.selectBtn" )))])]}}],null,true)})],1)],1)],1)],1)],1),_c('v-stepper-step',{attrs:{"step":"2","complete":_vm.step > 2}},[_c('span',[_vm._v(_vm._s(_vm.$t("reportAddDialog.stepper.step2.title")))]),(_vm.step > 2)?_c('span',{staticClass:"mt-1 grey--text text--darken-2 subtitle-2"},[_vm._v(_vm._s(_vm.countSelectedReports)+" reports")]):_vm._e()]),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-form',{ref:"formStep2"},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-outer-icon":"search","label":_vm.$t('datatable.searchLabel'),"clearable":"","hide-details":""},model:{value:(_vm.searchReport),callback:function ($$v) {_vm.searchReport=$$v},expression:"searchReport"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headersReport,"loading":_vm.isLoadingReports,"items":_vm.reports,"search":_vm.searchReport,"item-key":"id","show-select":'show-select',"sort-by":[
												'reportType',
												'name',
											],"sort-desc":[true, false],"multi-sort":""},scopedSlots:_vm._u([{key:`item.reportType`,fn:function({
													item,
												}){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({
															on,
														}){return [(
																item.reportType ===
																	'PowerBIReport'
															)?_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("dashboard")]):(
																item.reportType ===
																	'PaginatedReport'
															)?_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("layers")]):_vm._e()]}}],null,true)},[(
															item.reportType ===
																'PowerBIReport'
														)?_c('span',[_vm._v(" "+_vm._s("Power BI Report")+" ")]):(
															item.reportType ===
																'PaginatedReport'
														)?_c('span',[_vm._v(" "+_vm._s("Paginated Report")+" ")]):_vm._e()])]}}],null,true),model:{value:(_vm.selectedReports),callback:function ($$v) {_vm.selectedReports=$$v},expression:"selectedReports"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","rounded":""},on:{"click":function($event){_vm.step = 1;
										_vm.selectedGroup = null;}}},[_vm._v(_vm._s(_vm.$t("button.previousBtn")))]),_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"primary","disabled":_vm.selectedReports.length <= 0},on:{"click":_vm.step2Next}},[_vm._v(_vm._s(_vm.$t("button.nextBtn")))])],1)],1)],1),_c('v-stepper-step',{attrs:{"step":"3","complete":_vm.step > 3}},[_vm._v(" "+_vm._s(_vm.$t("reportAddDialog.stepper.step3.title"))+" ")]),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-form',{ref:"formStep3"},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{staticClass:"subtitle-2 mb-n5",attrs:{"items":_vm.tenants.edges,"item-text":"node.tenantName","item-value":"node.id","loading":_vm.$apollo.queries.tenants
													.loading,"rules":[_vm.rules.required],"label":_vm.$t(
													'reportAddDialog.stepper.step3.tenantLabel'
												),"outlined":""},model:{value:(_vm.tenantNodeId),callback:function ($$v) {_vm.tenantNodeId=$$v},expression:"tenantNodeId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-switch',{staticClass:"mt-3 my-0 py-0",attrs:{"label":_vm.$t(
													'reportAddDialog.stepper.step3.isEditableLabel'
												),"hide-details":""},model:{value:(_vm.isEditable),callback:function ($$v) {_vm.isEditable=$$v},expression:"isEditable"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-switch',{staticClass:"mt-3 my-0 py-0",attrs:{"label":_vm.$t(
													'reportAddDialog.stepper.step3.isBindedLabel'
												),"hide-details":""},model:{value:(_vm.isBinded),callback:function ($$v) {_vm.isBinded=$$v},expression:"isBinded"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-switch',{staticClass:"mt-3 my-0 py-0",attrs:{"label":"Direct Query","hide-details":""},model:{value:(_vm.isDirectQuery),callback:function ($$v) {_vm.isDirectQuery=$$v},expression:"isDirectQuery"}})],1),(_vm.isBinded)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{staticClass:"subtitle-2 mb-n5",attrs:{"items":_vm.groups,"item-text":"name","item-value":"id","loading":_vm.isLoadingGroups,"rules":[_vm.rules.required],"label":_vm.$t(
													'reportAddDialog.stepper.step3.bindedGroupLabel'
												),"outlined":""},on:{"change":function($event){return _vm.onBindedGroupSelected(
													_vm.selectedBindedGroup
												)}},model:{value:(_vm.selectedBindedGroup),callback:function ($$v) {_vm.selectedBindedGroup=$$v},expression:"selectedBindedGroup"}})],1):_vm._e(),(_vm.isBinded)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{staticClass:"subtitle-2 mb-n5",attrs:{"items":_vm.bindedDatasets,"item-text":"name","item-value":"id","loading":_vm.isLoadingBindedDatasets,"rules":[_vm.rules.required],"disabled":!_vm.selectedBindedGroup,"label":_vm.$t(
													'reportAddDialog.stepper.step3.bindedDatasetLabel'
												),"outlined":""},model:{value:(_vm.selectedBindedDataset),callback:function ($$v) {_vm.selectedBindedDataset=$$v},expression:"selectedBindedDataset"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{staticClass:"subtitle-2 mb-n5",attrs:{"items":_vm.reportGroups.edges,"item-text":"node.reportGroupName","item-value":"node.id","loading":_vm.$apollo.queries.reportGroups
													.loading,"rules":[],"label":_vm.$t(
													'reportAddDialog.stepper.step3.reportGroupLabel'
												),"outlined":""},model:{value:(_vm.reportGroupNodeId),callback:function ($$v) {_vm.reportGroupNodeId=$$v},expression:"reportGroupNodeId"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":30,"rules":[_vm.rules.maxLength(30)],"label":_vm.$t(
													'reportAddDialog.stepper.step3.pbiDateTableLabel'
												),"outlined":""},model:{value:(_vm.pbiDateTable),callback:function ($$v) {_vm.pbiDateTable=$$v},expression:"pbiDateTable"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":30,"rules":[_vm.rules.maxLength(30)],"label":_vm.$t(
													'reportAddDialog.stepper.step3.pbiDateColumnLabel'
												),"outlined":""},model:{value:(_vm.pbiDateColumn),callback:function ($$v) {_vm.pbiDateColumn=$$v},expression:"pbiDateColumn"}})],1)],1)],1),(_vm.selectedGroup !== null)?_c('v-card-actions',{staticClass:"mt-10"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","rounded":""},on:{"click":function($event){_vm.step = 2}}},[_vm._v(_vm._s(_vm.$t("button.previousBtn")))]),_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"primary","loading":_vm.isSaving,"disabled":_vm.tenantNodeId === null},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("button.saveBtn")))])],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }