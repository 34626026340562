var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center pl-3"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":"/"}}),_c('v-spacer')],1),_c('KnowledgeBaseEditDialog',{attrs:{"object":_vm.editedItem,"dialog":_vm.newKnowledgeBaseDialog},on:{"update:object":function($event){_vm.editedItem=$event},"update:dialog":function($event){_vm.newKnowledgeBaseDialog=$event},"item-created":_vm.onKnowledgeBaseCreated}}),_c('v-row',[_c('v-dialog',{attrs:{"scrollable":"","width":"75%"},model:{value:(_vm.knowledgeBaseDialog),callback:function ($$v) {_vm.knowledgeBaseDialog=$$v},expression:"knowledgeBaseDialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeKnowledgeBaseDialog}},[_c('v-icon',[_vm._v("close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("knowledgeBase.title")))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.goToKnowledgeBaseDetail(
								_vm.selectedKnowledgeBaseNodeId
							)}}},[_c('v-icon',[_vm._v("launch")])],1)],1),_c('v-card-text',{staticClass:"mx-0 px-0"},[(_vm.selectedKnowledgeBaseNodeId)?_c('KnowledgeBaseDetailComponent',{attrs:{"knowledgeBaseNodeId":_vm.selectedKnowledgeBaseNodeId},on:{"knowledgeBase-items-updated":_vm.refreshCurrentPage,"item-deleted":_vm.onKnowledgeBaseDeleted}}):_vm._e()],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{class:{
					'mx-0 elevation-5': _vm.$vuetify.breakpoint.xsOnly,
					'mx-3 elevation-5': _vm.$vuetify.breakpoint.smAndUp,
				},attrs:{"loading":_vm.$apollo.queries.knowledgeBases.loading}},[_c('v-card-title',{staticClass:"pb-0"},[_c('v-icon',{staticClass:"mx-2",attrs:{"color":"primary"}},[_vm._v("info")]),_vm._v(_vm._s(_vm.$t("knowledgeBase.title"))+" "),(_vm.me.isSuperuser)?_c('v-btn',{staticClass:"mr-5",attrs:{"icon":"","fab":"","small":""},on:{"click":function($event){return _vm.goToKnowledgeBaseSettings()}}},[_c('v-icon',[_vm._v("settings")])],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"4"}}),_c('v-spacer'),(_vm.me.isSuperuser)?_c('v-btn',{attrs:{"color":"primary","dark":"","outlined":"","rounded":"","small":""},on:{"click":_vm.addKnowledgeBase}},[_vm._v(_vm._s(_vm.$t("knowledgeBase.newBtn")))]):_vm._e()],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-card',{class:{
									'mx-0 elevation-5':
										_vm.$vuetify.breakpoint.xsOnly,
									'mx-3 elevation-5':
										_vm.$vuetify.breakpoint.smAndUp,
								}},[_c('v-list-item-group',{on:{"change":_vm.refreshCurrentPage},model:{value:(_vm.selectedFilter),callback:function ($$v) {_vm.selectedFilter=$$v},expression:"selectedFilter"}},_vm._l((_vm.knowledgeBaseTypes.edges),function(knowledgeBaseType){return _c('v-list-item',{key:knowledgeBaseType.node.id,attrs:{"value":knowledgeBaseType.node
												.knowledgeBaseTypeName}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
													knowledgeBaseType.node
														.knowledgeBaseTypeName
												)}})],1)],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"10"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-card',{staticClass:"mx-3 elevation-5"},[_c('v-row',{staticClass:"mt-0",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"search","solo":"","append-outer-icon":"refresh","placeholder":_vm.$t('datatable.searchLabel'),"clearable":""},on:{"click:append-outer":function($event){return _vm.refreshCurrentPage()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.refreshCurrentPage()},"click:clear":function($event){return _vm.onClearSearch()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-container',[_c('v-row',[_c('v-virtual-scroll',{attrs:{"items":_vm.knowledgeBases
													? _vm.knowledgeBases.edges
													: [],"item-height":160,"height":"600"},scopedSlots:_vm._u([{key:"default",fn:function({
													index,
													item,
												}){return [_c('v-col',{key:item.node.id,attrs:{"cols":"12"}},[_c('v-card',{on:{"click":function($event){return _vm.openKnowledgeBaseDialog(
																item.node
															)}}},[_c('div',{staticClass:"d-flex flex-no-wrap"},[_c('v-avatar',{staticClass:"ma-3",attrs:{"size":"125","tile":""}},[_c('v-img',{attrs:{"src":item
																			.node
																			.knowledgeBaseAvatarPath,"contain":""}})],1),_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s(
																		item
																			.node
																			.knowledgeBaseTitle
																	)}}),_c('v-card-subtitle',[_vm._v(_vm._s(_vm._f("moment")(item .node .createdAt, "from" ))+" - "+_vm._s(item .node .knowledgeBaseType .knowledgeBaseTypeName))]),_c('v-card-text',[_vm._v(" "+_vm._s(item .node .knowledgeBaseDescription)+" ")])],1)],1)])],1),(
														index ==
															_vm.knowledgeBases
																.edges
																.length -
																1
													)?_c('v-card',{directives:[{name:"intersect",rawName:"v-intersect",value:(
														_vm.loadMoreKnowledgeBases
													),expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tloadMoreKnowledgeBases\n\t\t\t\t\t\t\t\t\t\t\t\t\t"}],staticClass:"elevation-0",attrs:{"tile":""}},[(
															_vm.$apollo.queries
																.knowledgeBases
																.loading
														)?_c('v-card-text',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()],1):_vm._e()]}}],null,false,1323312268)})],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-card',{staticClass:"mx-0 elevation-5",attrs:{"loading":_vm.$apollo.queries.knowledgeBases.loading}},[_c('v-card-title',{staticClass:"pb-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","append-outer-icon":"refresh","label":_vm.$t('datatable.searchLabel'),"clearable":"","hide-details":""},on:{"click:append-outer":function($event){return _vm.refreshCurrentPage()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.refreshCurrentPage()},"click:clear":function($event){return _vm.onClearSearch()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-container',[_c('v-row',[_c('v-virtual-scroll',{attrs:{"items":_vm.knowledgeBases
													? _vm.knowledgeBases.edges
													: [],"item-height":80,"height":"600"},scopedSlots:_vm._u([{key:"default",fn:function({
													index,
													item,
												}){return [_c('v-list-item',{attrs:{"three-line":"","dense":""}},[_c('v-list-item-content',{staticClass:"row-pointer",on:{"click":function($event){return _vm.goToKnowledgeBaseDetail(
																item.node.id
															)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
																item.node
																	.knowledgeBaseTitle
															)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("moment")(item.node .createdAt, "from" ))+" - "+_vm._s(item.node .knowledgeBaseType .knowledgeBaseTypeName))]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
																item.node
																	.knowledgeBaseDescription
															)}},[_vm._v(" >")])],1)],1),(
														index ==
															_vm.knowledgeBases
																.edges
																.length -
																1
													)?_c('v-card',{directives:[{name:"intersect",rawName:"v-intersect",value:(
														_vm.loadMoreKnowledgeBases
													),expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tloadMoreKnowledgeBases\n\t\t\t\t\t\t\t\t\t\t\t\t\t"}],staticClass:"elevation-0",attrs:{"tile":""}},[(
															_vm.$apollo.queries
																.knowledgeBases
																.loading
														)?_c('v-card-text',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()],1):_vm._e()]}}],null,false,3692130407)})],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }