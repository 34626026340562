<template>
	<v-container fluid>
		<v-row class="align-center pl-3">
			<!-- Breadcrumbs -->
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
			<v-spacer />
		</v-row>

		<!-- System Dialog-->
		<SystemEditDialog
			:object.sync="editedItem"
			:dialog.sync="systemDialog"
			v-on:item-created="onSystemCreated"
		></SystemEditDialog>

		<!-- Maintenance Dialog-->
		<MaintenanceEditDialog
			:object.sync="editedItem"
			:dialog.sync="maintenanceDialog"
			v-on:item-created="onMaintenanceCreated"
		></MaintenanceEditDialog>

		<!-- Incident Dialog-->
		<IncidentEditDialog
			:object.sync="editedItem"
			:dialog.sync="incidentDialog"
			v-on:item-created="onIncidentCreated"
		></IncidentEditDialog>

		<!-- Incident Update Dialog-->
		<IncidentUpdateEditDialog
			:object.sync="editedItem"
			:parentNode.sync="parentNode"
			:dialog.sync="incidentUpdateDialog"
			v-on:item-created="onIncidentUpdateCreated"
		></IncidentUpdateEditDialog>

		<!-- Delete Status Dialog -->
		<DeleteDialog
			:dialog.sync="deleteDialog"
			:item="deletedItem"
			v-on:confirmed="deletingConfirmed"
		>
			<template v-slot:title>{{ $t("deleteDialog.title") }}</template>
			<template v-slot:content>{{ $t("deleteDialog.content") }}</template>
		</DeleteDialog>

		<v-container>
			<v-row justify="center" align="center">
				<v-col cols="12" sm="8">
					<v-alert
						class="elevation-5"
						:type="overallStatus.node.severity.color"
					>
						{{ overallStatus.node.severity.severityName }}
					</v-alert>
				</v-col>
			</v-row>

			<!-- System Status -->
			<v-row justify="center" align="center">
				<v-col cols="12" sm="8">
					<v-card class="elevation-5">
						<!-- Header -->
						<v-card-title class="pb-0">
							<v-icon color="primary" class="mx-2">info</v-icon
							>{{ $t("status.currentStatus.title") }}
							<v-btn
								v-if="me.isSuperuser"
								icon
								fab
								small
								class="mr-5"
								@click="goToStatusSettings()"
							>
								<v-icon>settings</v-icon>
							</v-btn>
							<v-spacer></v-spacer>
							<v-btn
								v-if="me.isSuperuser"
								color="primary"
								dark
								outlined
								rounded
								small
								@click="addSystem"
								>{{ $t("status.currentStatus.newBtn") }}</v-btn
							>
						</v-card-title>

						<v-container fluid>
							<template v-for="system in systems.edges">
								<v-card
									:key="system.node.id"
									class="elevation-2"
									tile
								>
									<v-card-title>
										{{ system.node.systemName }}
										<v-spacer></v-spacer>
										<v-chip
											:color="system.node.severity.color"
											><v-icon left>
												{{
													system.node.severity.icon
												}} </v-icon
											>{{
												system.node.severity
													.severityName
											}}</v-chip
										>
										<v-btn
											v-if="me.isSuperuser"
											icon
											color="primary"
											@click="editSystem(system.node)"
										>
											<v-icon>edit</v-icon>
										</v-btn>
										<v-btn
											v-if="me.isSuperuser"
											icon
											color="error"
											@click="deletingSystem(system.node)"
										>
											<v-icon>delete</v-icon>
										</v-btn>
									</v-card-title>
								</v-card>
							</template>
						</v-container>
					</v-card>
				</v-col>
			</v-row>

			<!-- Scheduled Maintenance -->
			<v-row justify="center" align="center">
				<v-col cols="12" sm="8">
					<v-card class="elevation-5">
						<!-- Header -->
						<v-card-title class="pb-0">
							<v-icon color="primary" class="mx-2">build</v-icon
							>{{ $t("status.scheduledMaintenance.title") }}
							<v-spacer></v-spacer>
							<v-btn
								v-if="me.isSuperuser"
								color="primary"
								dark
								outlined
								rounded
								small
								@click="addMaintenance"
								>{{
									$t("status.scheduledMaintenance.newBtn")
								}}</v-btn
							>
						</v-card-title>

						<v-container fluid>
							<template v-for="maintenance in maintenances.edges">
								<v-card
									:key="maintenance.node.id"
									class="elevation-2"
									tile
								>
									<v-card-title>
										{{ maintenance.node.maintenanceName }}
										<v-spacer></v-spacer>
										<template
											v-for="maintenanceSystemSet in maintenance
												.node.maintenanceSystemSet
												.edges"
										>
											<v-chip
												:key="
													maintenanceSystemSet.node.id
												"
												class="mr-1 mb-1"
												small
												>{{
													maintenanceSystemSet.node
														.system.systemName
												}}</v-chip
											>
										</template>
										<v-btn
											v-if="me.isSuperuser"
											icon
											color="primary"
											@click="
												editMaintenance(
													maintenance.node
												)
											"
										>
											<v-icon>edit</v-icon>
										</v-btn>
										<v-btn
											v-if="me.isSuperuser"
											icon
											color="error"
											@click="
												deletingMaintenance(
													maintenance.node
												)
											"
										>
											<v-icon>delete</v-icon>
										</v-btn>
									</v-card-title>
									<v-card-subtitle>
										{{
											maintenance.node.start
												| moment("YYYY-MM-DD HH:mm")
										}}
										-
										{{
											maintenance.node.end
												| moment("YYYY-MM-DD HH:mm")
										}}
									</v-card-subtitle>
									<v-card-text>
										{{
											maintenance.node
												.maintenanceDescription
										}}
									</v-card-text>
								</v-card>
							</template>
						</v-container>
					</v-card>
				</v-col>
			</v-row>

			<!-- Incidents -->
			<v-row justify="center" align="center">
				<v-col cols="12" sm="8">
					<v-card class="elevation-5">
						<v-card-title class="pb-0">
							<v-icon color="primary" class="mx-2"
								>report_problem</v-icon
							>{{ $t("status.incidents.title") }}
							<v-spacer></v-spacer>
							<v-btn
								v-if="me.isSuperuser"
								color="primary"
								dark
								outlined
								rounded
								small
								@click="addIncident"
								>{{ $t("status.incidents.newBtn") }}</v-btn
							>
						</v-card-title>
						<v-container fluid>
							<v-timeline dense>
								<v-timeline-item
									v-for="incident in incidents.edges"
									:key="incident.node.id"
									left
									small
								>
									<v-card class="elevation-2">
										<v-card-title>
											<v-chip
												v-if="incident.node.isResolved"
												color="success"
												small
												class="mr-1"
												>Resolved</v-chip
											><v-chip
												v-else
												color="warning"
												small
												class="mr-1"
												>Unresolved</v-chip
											>{{ incident.node.incidentName }}
											<v-spacer></v-spacer>
											<template
												v-for="incidentSystemSet in incident
													.node.incidentSystemSet
													.edges"
											>
												<v-chip
													:key="
														incidentSystemSet.node
															.id
													"
													class="mr-1 mb-1"
													small
													>{{
														incidentSystemSet.node
															.system.systemName
													}}</v-chip
												>
											</template>
											<v-btn
												v-if="me.isSuperuser"
												color="primary"
												dark
												outlined
												rounded
												small
												@click="
													addIncidentUpdate(
														incident.node
													)
												"
												>{{
													$t(
														"status.incidents.newUpdateBtn"
													)
												}}</v-btn
											>
											<v-btn
												v-if="me.isSuperuser"
												icon
												color="primary"
												@click="
													editIncident(incident.node)
												"
											>
												<v-icon>edit</v-icon>
											</v-btn>
											<v-btn
												v-if="me.isSuperuser"
												icon
												color="error"
												@click="
													deletingIncident(
														incident.node
													)
												"
											>
												<v-icon>delete</v-icon>
											</v-btn>
										</v-card-title>
										<v-card-subtitle>
											<v-chip
												:color="
													incident.node.severity.color
												"
												x-small
												class="mr-1"
												outlined
												>{{
													incident.node.severity
														.severityName
												}}</v-chip
											>{{
												incident.node.createdAt
													| moment("YYYY-MM-DD HH:mm")
											}}
										</v-card-subtitle>
										<v-card-text>{{
											incident.node.incidentDescription
										}}</v-card-text>
										<v-container fluid>
											<template
												v-for="incidentUpdate in incident
													.node.incidentUpdateSet
													.edges"
											>
												<v-card
													:key="
														incidentUpdate.node.id
													"
													class="elevation-1"
													tile
												>
													<v-card-title
														>{{
															incidentUpdate.node
																.incidentUpdateName
														}}
														<v-spacer></v-spacer>
														<v-btn
															v-if="
																me.isSuperuser
															"
															icon
															color="primary"
															@click="
																editIncidentUpdate(
																	incidentUpdate.node
																)
															"
														>
															<v-icon
																>edit</v-icon
															>
														</v-btn>
														<v-btn
															v-if="
																me.isSuperuser
															"
															icon
															color="error"
															@click="
																deletingIncidentUpdate(
																	incidentUpdate.node
																)
															"
														>
															<v-icon
																>delete</v-icon
															>
														</v-btn>
													</v-card-title>
													<v-card-subtitle>{{
														incidentUpdate.node
															.createdAt
															| moment(
																"YYYY-MM-DD HH:mm"
															)
													}}</v-card-subtitle>
													<v-card-text>{{
														incidentUpdate.node
															.incidentUpdateDescription
													}}</v-card-text>
												</v-card>
											</template>
										</v-container>
									</v-card>
								</v-timeline-item>
							</v-timeline>
						</v-container>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>
<script>
import gql from "graphql-tag";
import SystemEditDialog from "@/components/status/SystemEditDialog.vue";
import MaintenanceEditDialog from "@/components/status/MaintenanceEditDialog.vue";
import IncidentEditDialog from "@/components/status/IncidentEditDialog.vue";
import IncidentUpdateEditDialog from "@/components/status/IncidentUpdateEditDialog.vue";
import DeleteDialog from "@/components/base/DeleteDialog.vue";

export default {
	name: "status-page",
	components: {
		SystemEditDialog,
		MaintenanceEditDialog,
		IncidentEditDialog,
		DeleteDialog,
		IncidentUpdateEditDialog,
	},
	apollo: {
		systems: {
			query: gql`
				query systems {
					systems {
						edges {
							node {
								id
								createdAt
								modifiedAt
								systemName
								systemDescription
								severity {
									id
									severityName
									icon
									color
								}
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			//update: (data) => data.systems,
			update(data) {
				var systems = data.systems.edges;
				this.overallStatus = systems[0];

				return data.systems;
			},
			pollInterval: 60000, // ms
		},

		maintenances: {
			query: gql`
				query maintenances {
					maintenances {
						edges {
							node {
								id
								maintenanceName
								maintenanceDescription
								start
								end
								maintenanceSystemSet {
									edges {
										node {
											id
											system {
												id
												systemName
												systemDescription
											}
										}
									}
								}
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.maintenances,
			pollInterval: 60000, // ms
		},

		incidents: {
			query: gql`
				query incidents {
					incidents(first: 10) {
						edges {
							node {
								id
								createdAt
								modifiedAt
								incidentName
								incidentDescription
								isResolved
								severity {
									id
									severityName
									color
								}
								incidentSystemSet {
									edges {
										node {
											system {
												id
												systemName
											}
										}
									}
								}
								incidentUpdateSet {
									edges {
										node {
											id
											createdAt
											modifiedAt
											incidentUpdateName
											incidentUpdateDescription
											incident {
												id
											}
										}
									}
								}
							}
						}
					}
				}
			`,
			variables() {},
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.incidents,
			pollInterval: 60000, // ms
		},
	},
	data: function() {
		return {
			systems: {},
			systemDialog: false,

			deleteDialog: false,
			// deleted item
			deletedItem: {
				id: null,
				type: null,
				name: null,
			},

			maintenances: {},
			maintenanceDialog: false,

			incidents: {},
			incidentDialog: false,
			incidentUpdateDialog: false,

			overallStatus: {
				node: {
					id: null,
					createdAt: null,
					modifiedAt: null,
					systemName: null,
					systemDescription: null,
					severity: {
						id: null,
						severityName: null,
						icon: null,
						color: null,
					},
				},
			},

			editedItem: {},
			parentNode: {},
		};
	},
	computed: {
		me() {
			return this.$store.state.user.me;
		},

		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("status.breadcrumbs.home"),
					disable: false,
					href: "/#/",
				},
				{
					text: this.$t("status.breadcrumbs.status"),
					disabled: true,
				},
			];
			return breadcrumbs;
		},
	},
	watch: {},
	created() {
		this.fetchMe();
	},
	mounted() {},
	methods: {
		fetchMe() {
			this.$store
				.dispatch("user/fetchMe")
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		goToStatusSettings() {
			this.$router.push({
				name: "status-settings",
			});
		},

		// system
		addSystem() {
			this.editedItem = null;
			this.systemDialog = true;
		},
		onSystemCreated() {
			this.systemDialog = false;
			this.$apollo.queries.systems.refresh();
		},
		editSystem(system) {
			this.editedItem = JSON.parse(JSON.stringify(system));
			this.systemDialog = true;
		},
		deletingSystem(item) {
			this.deletedItem.type = "system";
			this.deletedItem.id = item.id;
			this.deletedItem.name = item.systemName;
			this.deleteDialog = true;
		},

		// maintenance
		addMaintenance() {
			this.editedItem = null;
			this.maintenanceDialog = true;
		},
		onMaintenanceCreated() {
			this.maintenanceDialog = false;
			this.$apollo.queries.maintenances.refresh();
		},
		editMaintenance(maintenance) {
			this.editedItem = JSON.parse(JSON.stringify(maintenance));
			this.maintenanceDialog = true;
		},
		deletingMaintenance(item) {
			this.deletedItem.type = "maintenance";
			this.deletedItem.id = item.id;
			this.deletedItem.name = item.maintenanceName;
			this.deleteDialog = true;
		},

		// incident
		addIncident() {
			this.editedItem = null;
			this.incidentDialog = true;
		},
		onIncidentCreated() {
			this.incidentDialog = false;
			this.$apollo.queries.incidents.refresh();
		},
		editIncident(incident) {
			this.editedItem = JSON.parse(JSON.stringify(incident));
			this.incidentDialog = true;
		},
		deletingIncident(item) {
			this.deletedItem.type = "incident";
			this.deletedItem.id = item.id;
			this.deletedItem.name = item.incidentName;
			this.deleteDialog = true;
		},

		// incident update
		addIncidentUpdate(incident) {
			this.parentNode = incident;
			this.editedItem = null;
			this.incidentUpdateDialog = true;
		},
		onIncidentUpdateCreated() {
			this.incidentUpdateDialog = false;
			this.$apollo.queries.incidents.refresh();
		},
		editIncidentUpdate(incidentUpdate) {
			this.editedItem = JSON.parse(JSON.stringify(incidentUpdate));
			this.incidentUpdateDialog = true;
		},
		deletingIncidentUpdate(item) {
			this.deletedItem.type = "incident update";
			this.deletedItem.id = item.id;
			this.deletedItem.name = item.incidentUpdateName;
			this.deleteDialog = true;
		},

		// delete confirmed
		deletingConfirmed() {
			switch (this.deletedItem.type) {
				case "system":
					this.callDeleteSystem();
					break;
				case "maintenance":
					this.callDeleteMaintenance();
					break;
				case "incident":
					this.callDeleteIncident();
					break;
				case "incident update":
					this.callDeleteIncidentUpdate();
					break;
				default:
					return;
			}
		},

		callDeleteSystem() {
			if (this.deletedItem) {
				const payload = {
					id: this.deletedItem.id,
				};

				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation deleteSystem($input: DeleteSystemInput!) {
								deleteSystem(input: $input) {
									system {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then(() => {
						// refresh plants
						this.$apollo.queries.systems.refresh();

						// close delete dialog
						this.deleteDialog = false;

						// show snackbar
						const payload = {
							color: "success",
							message: `System successfully deleted`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
					});
			}
		},

		callDeleteMaintenance() {
			if (this.deletedItem) {
				const payload = {
					id: this.deletedItem.id,
				};

				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation deleteMaintenance(
								$input: DeleteMaintenanceInput!
							) {
								deleteMaintenance(input: $input) {
									maintenance {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then(() => {
						// refresh plants
						this.$apollo.queries.maintenances.refresh();

						// close delete dialog
						this.deleteDialog = false;

						// show snackbar
						const payload = {
							color: "success",
							message: `Maintenance successfully deleted`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
					});
			}
		},

		callDeleteIncident() {
			if (this.deletedItem) {
				const payload = {
					id: this.deletedItem.id,
				};

				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation deleteIncident(
								$input: DeleteIncidentInput!
							) {
								deleteIncident(input: $input) {
									incident {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then(() => {
						// refresh plants
						this.$apollo.queries.incidents.refresh();

						// close delete dialog
						this.deleteDialog = false;

						// show snackbar
						const payload = {
							color: "success",
							message: `Incident successfully deleted`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
					});
			}
		},

		callDeleteIncidentUpdate() {
			if (this.deletedItem) {
				const payload = {
					id: this.deletedItem.id,
				};

				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation deleteIncidentUpdate(
								$input: DeleteIncidentUpdateInput!
							) {
								deleteIncidentUpdate(input: $input) {
									incidentUpdate {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then(() => {
						// refresh plants
						this.$apollo.queries.incidents.refresh();

						// close delete dialog
						this.deleteDialog = false;

						// show snackbar
						const payload = {
							color: "success",
							message: `Incident update successfully deleted`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
					});
			}
		},
	},
};
</script>
