<template>
	<v-container fluid>
		<v-row class="align-center pl-3">
			<!-- Breadcrumbs -->
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
			<v-spacer />
		</v-row>

		<!-- Delete User Dialog -->
		<DeleteDialog
			:dialog.sync="deleteDialog"
			v-on:confirmed="deletingSelectedUsersConfirmed"
		>
			<template v-slot:title>{{ $t("deleteDialog.title") }}</template>
			<template v-slot:content>{{ $t("deleteDialog.content") }}</template>
		</DeleteDialog>

		<!-- User Dialog-->
		<UserEditDialog
			:object.sync="editedItem"
			:dialog.sync="userDialog"
			v-on:item-created="onUserCreated"
		></UserEditDialog>

		<v-row>
			<v-col cols="12" class="pt-0">
				<v-card
					:class="{
						'mx-0 elevation-5': $vuetify.breakpoint.xsOnly,
						'mx-3 elevation-5': $vuetify.breakpoint.smAndUp,
					}"
				>
					<!-- Header -->
					<v-card-title class="pb-0">
						<v-icon color="primary" class="mx-2">contacts</v-icon>
						{{ $t("users.title") }}
						<v-btn
							v-if="me.isSuperuser"
							icon
							fab
							small
							class="mr-5"
							@click="goToUsersSettings()"
						>
							<v-icon>settings</v-icon>
						</v-btn>
						<v-spacer></v-spacer>
						<v-col cols="12" sm="4">
							<v-text-field
								dense
								:value="searchUsers"
								@input="handleSearch"
								append-outer-icon="refresh"
								@click:append-outer="refreshCurrentPage()"
								v-bind:label="$t('datatable.searchLabel')"
								clearable
								hide-details
								@keydown.enter="refreshCurrentPage()"
								@click:clear="onClearSearch()"
							></v-text-field>
						</v-col>
						<v-spacer></v-spacer>
						<v-btn
							v-if="hasPermission('users.delete_customuser')"
							class="mr-2"
							color="error"
							dark
							outlined
							rounded
							small
							:disabled="selectedUsers.length <= 0"
							@click="deletingSelectedUsers"
							>{{ $t("users.deleteBtn") }}</v-btn
						>
						<v-btn
							v-if="hasPermission('users.add_customuser')"
							color="primary"
							dark
							outlined
							rounded
							small
							@click="addUser"
							>{{ $t("users.newBtn") }}</v-btn
						>
					</v-card-title>

					<v-container fluid>
						<v-data-table
							:headers="
								me.isSuperuser ? headersSuperuser : headers
							"
							:loading="$apollo.queries.users.loading"
							:items="users.edges"
							:options.sync="dataTableOptions"
							:server-items-length="users.totalCount"
							:items-per-page="defaultItemsPerPage"
							:footer-props="{
								'items-per-page-options': [10, 25, 50, 75, 100],
								'disable-pagination':
									$apollo.queries.users.loading,
							}"
							v-model="selectedUsers"
							item-key="node.id"
							:show-select="
								hasPermission('users.delete_customuser')
									? 'show-select'
									: undefined
							"
						>
							<template
								v-slot:[`item.node.isSuperuser`]="{ item }"
							>
								<v-icon
									v-if="item.node.isSuperuser"
									color="success"
									>check_circle</v-icon
								>
								<v-icon v-else color="error">cancel</v-icon>
							</template>
							<template
								v-slot:[`item.node.require2fa`]="{ item }"
							>
								<v-icon
									v-if="item.node.require2fa"
									color="success"
									>check_circle</v-icon
								>
								<v-icon v-else color="error">cancel</v-icon>
							</template>
							<template
								v-slot:[`item.node.enabled2fa`]="{ item }"
							>
								<v-icon
									v-if="item.node.enabled2fa"
									color="success"
									>check_circle</v-icon
								>
								<v-icon v-else color="error">cancel</v-icon>
							</template>

							<template v-slot:[`item.action`]="{ item }">
								<v-tooltip
									v-if="
										hasPermission('users.change_customuser')
									"
									bottom
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-bind="attrs"
											v-on="on"
											icon
											color="primary"
											:loading="isLoggingInAsOtherUser"
											:disabled="isLoggedInAsOtherUser"
											@click="
												loginAsOtherUser(item.node.id)
											"
										>
											<v-icon>mouse</v-icon>
										</v-btn>
									</template>
									<span>{{
										$t("users.loginAsOtherUser")
									}}</span>
								</v-tooltip>
								<v-btn
									v-if="
										hasPermission('users.change_customuser')
									"
									icon
									color="primary"
									class="mr-2"
									@click="editUser(item.node)"
								>
									<v-icon>edit</v-icon>
								</v-btn>
							</template>
						</v-data-table>
					</v-container>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import UserEditDialog from "@/components/users/UserEditDialog.vue";
import DeleteDialog from "@/components/base/DeleteDialog.vue";

export default {
	name: "user-list-page",
	components: {
		UserEditDialog,
		DeleteDialog,
	},
	apollo: {
		users: {
			query: gql`
				query users(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$search: String
					$orderBy: String
				) {
					users(
						first: $first
						last: $last
						before: $before
						after: $after
						search: $search
						orderBy: $orderBy
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								id
								firstName
								lastName
								email
								isSuperuser
								require2fa
								enabled2fa
								tenant {
									id
									tenantName
								}
								groups {
									edges {
										node {
											id
											name
										}
									}
								}
								identityProvider
							}
						}
					}
				}
			`,
			// Additional options here
			fetchPolicy: "cache-and-network",
			update: (data) => data.users,
			// skip: true,
			pollInterval: 60000, // ms
		},
	},
	data: function() {
		return {
			userDialog: false,
			deleteDialog: false,
			isLoggingInAsOtherUser: false,

			defaultItemsPerPage: 25,
			dataTableOptions: {},
			page: 1,
			selectedUsers: [],

			users: {},

			editedItem: null,
			defaultItem: {
				id: null,
				email: null,
				firstName: null,
				lastName: null,
				isSuperuser: false,
			},

			deletedUser: {},

			headers: [
				{
					text: this.$t("users.datatableHeaders.email"),
					value: "node.email",
					sortable: true,
				},
				{
					text: this.$t("users.datatableHeaders.firstName"),
					value: "node.firstName",
					sortable: true,
				},
				{
					text: this.$t("users.datatableHeaders.lastName"),
					value: "node.lastName",
					sortable: true,
				},
				{
					text: "Require 2FA",
					value: "node.require2fa",
					sortable: true,
				},
				{
					text: "Enabled 2FA",
					value: "node.enabled2fa",
					sortable: true,
				},
				{
					text: this.$t("datatable.actionHeader"),
					value: "action",
					width: "120px",
					align: "center",
					sortable: false,
				},
			],

			headersSuperuser: [
				{
					text: this.$t("users.datatableHeaders.tenant"),
					value: "node.tenant.tenantName",
					sortable: true,
				},
				{
					text: this.$t("users.datatableHeaders.email"),
					value: "node.email",
					sortable: true,
				},
				{
					text: this.$t("users.datatableHeaders.firstName"),
					value: "node.firstName",
					sortable: true,
				},
				{
					text: this.$t("users.datatableHeaders.lastName"),
					value: "node.lastName",
					sortable: true,
				},
				{
					text: this.$t("users.datatableHeaders.isSuperuser"),
					value: "node.isSuperuser",
					sortable: false,
				},
				{
					text: this.$t("users.datatableHeaders.require2fa"),
					value: "node.require2fa",
					sortable: false,
				},
				{
					text: this.$t("users.datatableHeaders.enabled2fa"),
					value: "node.enabled2fa",
					sortable: false,
				},
				{
					text: this.$t("datatable.actionHeader"),
					value: "action",
					width: "120px",
					align: "center",
					sortable: false,
				},
			],

			editedIndex: -1,
		};
	},
	computed: {
		isLoggedInAsOtherUser() {
			return this.$store.state.user.loggedInAsOtherUser;
		},

		me() {
			return this.$store.state.user.me;
		},

		searchUsers() {
			return this.$store.state.user.searchUsers;
		},

		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("users.breadcrumbs.home"),
					disable: false,
					href: "/#/",
				},
				{
					text: this.$t("users.breadcrumbs.users"),
					disabled: true,
				},
			];
			return breadcrumbs;
		},
	},
	watch: {
		dataTableOptions: {
			handler() {
				this.refreshCurrentPage();
			},
			deep: true,
		},
	},
	created() {
		this.hasPermission = helper.hasPermission;

		this.fetchMe();
	},
	mounted() {},
	methods: {
		fetchMe() {
			this.$store
				.dispatch("user/fetchMe")
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		goToUsersSettings() {
			this.$router.push({
				name: "users-settings",
			});
		},

		handleSearch(search) {
			this.$store
				.dispatch("user/searchUsers", search)
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		onClearSearch() {
			this.handleSearch("");
			this.refreshCurrentPage();
		},

		refreshCurrentPage() {
			const {
				sortBy,
				sortDesc,
				page,
				itemsPerPage,
			} = this.dataTableOptions;

			var _first = itemsPerPage;
			var _last = null;
			var _before = null;
			var _after = null;
			var _orderBy = null;

			if (page < this.page) {
				_first = null;
				_last = itemsPerPage;
				_before = this.users.pageInfo.startCursor;
				_after = null;
			}

			if (page > this.page) {
				_first = itemsPerPage;
				_last = null;
				_before = null;
				_after = this.users.pageInfo.endCursor;
			}

			switch (sortBy[0]) {
				case "node.tenant.tenantName":
					_orderBy = "tenantName";
					break;
				case "node.email":
					_orderBy = "email";
					break;
				case "node.firstName":
					_orderBy = "firstName";
					break;
				case "node.lastName":
					_orderBy = "lastName";
					break;
				default:
					_orderBy = "email";
					break;
			}

			if (sortDesc[0] === true) {
				_orderBy = "-" + _orderBy;
			}

			this.$apollo.queries.users.setVariables({
				first: _first,
				last: _last,
				before: _before,
				after: _after,
				orderBy: _orderBy,
				search: this.searchUsers,
			});

			// set skip false to execute the query
			this.$apollo.queries.users.skip = false;
			this.$apollo.queries.users.refresh();

			// update this.page
			this.page = page;
		},

		onUserCreated() {
			this.userDialog = false;
			this.$apollo.queries.users.refresh();
		},

		addUser() {
			this.editedItem = null;
			this.userDialog = true;
		},

		editUser(user) {
			this.editedItem = JSON.parse(JSON.stringify(user));

			this.userDialog = true;
		},

		deletingSelectedUsers() {
			this.deleteDialog = true;
		},

		deletingSelectedUsersConfirmed() {
			var selectedUsers = this.selectedUsers;
			function getUserIds(selectedUsers) {
				var userIds = [];
				for (var i = 0; i < selectedUsers.length; i++) {
					userIds.push(selectedUsers[i].node.id);
				}
				return userIds;
			}
			var userIds = getUserIds(selectedUsers);

			const payload = {
				ids: userIds,
			};

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation deleteUsers($input: DeleteUsersInput!) {
							deleteUsers(input: $input) {
								user {
									id
								}
							}
						}
					`,
					// Parameters
					variables: {
						input: payload,
					},
				})
				.then((response) => {
					// refresh plants
					this.$apollo.queries.users.refresh();

					// close delete dialog
					this.deleteDialog = false;

					// show snackbar
					const payload = {
						color: "success",
						message: `Users successfully deleted`,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					this.error = error.graphQLErrors[0].message;
				});
			// update state of selectedReports
			this.selectedUsers = [];
		},

		loginAsOtherUser(userId) {
			this.isLoggingInAsOtherUser = true;

			const payload = { userId: userId };

			this.$store
				.dispatch("user/loginAsOtherUser", payload)
				.then(() => {
					this.$router.push(this.$route.query.redirect || "/");
				})
				.catch((error) => {
					if (error.graphQLErrors) {
						for (let err of error.graphQLErrors) {
							// show snackbar
							const payload = {
								color: "error",
								message: err.message,
							};
							this.$store.dispatch(
								"snackbar/showMessage",
								payload
							);
						}
					}
				})
				.finally(() => {
					this.isLoggingInAsOtherUser = false;
				});
		},
	},
};
</script>
